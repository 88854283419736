import { ReloadOutlined } from '@ant-design/icons';
import {
    Modal,
    Input,
    Row,
    Col,
    Form,
    DatePicker,
    Select,
    Button,
    Typography,
    Space,
    Tooltip
} from 'antd';
import { useState } from 'react';

const { Text } = Typography;

const DispatchBeerStockModal = (props) => {
    const [formDispatchStock] = Form.useForm();
    const {
        visible,
        onCancel,
        onSubmit,
        runDataVal,
        beerAvailableStockDataVal,
        beerLoadingVal
    } = props;
    const [dispatchDate, setDispatchDate] = useState("");
    const [availableQuantity, setAvailableQuantity] = useState([]);

    const handleSubmit = () => {
        if (!formDispatchStock) return;
        formDispatchStock.submit();
    };

    const handleFinish = (values) => {
        if (onSubmit) {
            onSubmit(
                values,
                dispatchDate
            );
        }
    };

    const onDispatchDatePickerChange = (date, dateString) => {
        setDispatchDate(dateString)
    };

    const modalFooter = { onCancel };

    const handleBeerStockNumberChange = (stockNumber) => {
        const filteredBeerAvailableStockData = beerAvailableStockDataVal.filter((beer_available_stock_data) => {
            return beer_available_stock_data.value === stockNumber
        })

        setAvailableQuantity(filteredBeerAvailableStockData[0].available_quantity)
    }

    const getModalContent = () => {
        return (
            <Form
                form={formDispatchStock}
                name="formDispatchStock"
                onFinish={handleFinish}
                style={{ paddingLeft: 20, paddingRight: 20 }}
            >
                <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Run Number"
                            name="run_number"
                            rules={[{ required: true, message: 'Please select a run number' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label?.toLowerCase() ?? '').localeCompare((optionB?.label?.toLowerCase() ?? ''))
                                }
                                placeholder="Select run"
                                options={runDataVal}
                            />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Stock Number"
                            name="stock_number"
                            rules={[{ required: true, message: 'Please select a stock number' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label?.toLowerCase() ?? '').localeCompare((optionB?.label?.toLowerCase() ?? ''))
                                }
                                placeholder="Select stock"
                                options={beerAvailableStockDataVal}
                                onChange={handleBeerStockNumberChange}
                            />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Dispatch Date"
                            name="dispatch_date"
                            rules={[{ required: true, message: 'Please select a dispatch date' }]}
                            labelCol={{ span: 24 }}
                        >
                            <DatePicker style={{ width: "100%" }} onChange={onDispatchDatePickerChange} />
                        </Form.Item>
                    </Col>
                </Row>
                <Text italic>Beer dispatch prices are...</Text>
                <Row gutter={16} style={{ marginTop: 8 }}>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        {availableQuantity.map((available_quantity, index) => {
                            if (available_quantity.volume === 300) {
                                return (
                                    <Text key={index}>{available_quantity.price || 0} LKR for 300ml </Text>
                                );
                            }
                            return null
                        })}
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        {availableQuantity.map((available_quantity, index) => {
                            if (available_quantity.volume === 500) {
                                return (
                                    <Text key={index}>{available_quantity.price || 0} LKR for 500ml </Text>
                                );
                            }
                            return null
                        })}
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        {availableQuantity.map((available_quantity, index) => {
                            if (available_quantity.volume === 700) {
                                return (
                                    <Text key={index}>{available_quantity.price || 0} LKR for 700ml </Text>
                                );
                            }
                            return null
                        })}
                    </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: 16 }}>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="300ml Quantity"
                            name="ml300"
                            rules={[{
                                pattern: /^[0-9]+$/,
                                message: "Please enter a number"
                            }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="How  many bottles or cans ?" />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="500ml Quantity"
                            name="ml500"
                            rules={[{
                                pattern: /^[0-9]+$/,
                                message: "Please enter a number"
                            }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="How  many bottles or cans ?" />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="700ml Quantity"
                            name="ml700"
                            rules={[{
                                pattern: /^[0-9]+$/,
                                message: "Please enter a number"
                            }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="How  many bottles or cans ?" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Modal
            title='Dispatch Stock'
            destroyOnClose
            width={1000}
            open={visible}
            {...modalFooter}
            footer={[
                <Space>
                    <Tooltip title="Clear form">
                        <Button
                            className="edit_button"
                            shape="circle"
                            icon={<ReloadOutlined />}
                            onClick={() => formDispatchStock.resetFields(["ml300", "ml500", "ml700"])}
                        />
                    </Tooltip>
                    <Button key="submit" type="primary" onClick={handleSubmit} loading={beerLoadingVal}>
                        Dispatch
                    </Button>
                </Space>
            ]}
        >
            {getModalContent()}
        </Modal>
    );
};

export default DispatchBeerStockModal;
