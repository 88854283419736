import { Button, message } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { PostDataAuth } from "../apiService/PostData";
import { apis } from "../properties";
import { useRefreshTable, useStore } from "../store";
import Progress from "react-progress-2";
import RunsDataTable from "../components/Run/RunsDataTable";
import useBreadCrumb from "../hooks/useBreadCrumb";
import AddRunModal from "../components/Run/AddRunModal";
import useMemberStatusList from "../hooks/useMemberStatusList";

function Runs() {
  const location = useLocation();
  useBreadCrumb("Runs", location.pathname, "Runs");
  const [visibleAddRunModal, setVisibleAddRunModal] = useState(false);
  const [runCount, setRunCount] = useState(0);
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const userData = useStore(state => state.profileData);

  const memberStatusList = useMemberStatusList("");

  const showModalAddRun = () => {
    setVisibleAddRunModal(true);
  };

  const handleCancelAddRunModal = () => {
    setVisibleAddRunModal(false);
  };

  const handleSubmitAddRunModal = (
    values,
    runDate,
    startingTime,
    uploadedImageKitUrl
  ) => {
    const body = {
      "run_name": values.run_name,
      "run_number": values.run_number,
      "run_date": runDate,
      "starting_time": startingTime || undefined,
      "run_site": values.run_site || undefined,
      "run_site_name": values.run_site_name || undefined,
      "note": values.note || undefined,
      "address": values.address,
      "cover_image_url": uploadedImageKitUrl || undefined,
      "run_status": "Ongoing"
    }

    PostDataAuth(apis.RUNS_LIST, body).then((result) => {
      let responseJson = result;
      if (responseJson.run_number) {
        message.success("Run added successfully")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
        message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });

    setVisibleAddRunModal(false)
  };

  const handleRunCount = (runCount) => {
    setRunCount(runCount + 1)
  }

  return (
    <div className="dashboard">
      <div className="section_row">
        {userData.role_status !== "Super Admin" ? <></> : <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => showModalAddRun()}
          >
            Add Run
          </Button>
        </div>}
        <RunsDataTable getRunCount={handleRunCount} />
        <AddRunModal
          visible={visibleAddRunModal}
          onDone={handleCancelAddRunModal}
          onCancel={handleCancelAddRunModal}
          onSubmit={handleSubmitAddRunModal}
          runCountVal={runCount}
          memberStatusListVal={memberStatusList}
        />
      </div>
    </div>
  );
}

export default Runs
