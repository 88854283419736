import {
  Form,
  Row,
  Col,
  Input,
  //Image
} from "antd";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom"
import useRunsList from "../../hooks/useRunsList";

//const { TextArea } = Input;

function RunDetails() {
  const [formViewRun] = Form.useForm();
  // const [imageURL, setImageURL] = useState("");
  const params = useParams();


  const runsList = useRunsList(`?_id=${params.run_id}`);

  useEffect(() => {
    formViewRun.setFieldsValue(runsList[0])
    //setImageURL(runsList[0]?.cover_image_url)
  }, [formViewRun, runsList])

  return (
    <>
      <Form
        form={formViewRun}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <Row gutter={16} >
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Run Number"
              name="run_number"
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Run Site Name"
              name="run_site_name"
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Run Date"
              name="run_date"
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Starting Time"
              name="starting_time"
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xl={{ span: 16 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Google Map iFrame URL"
              name="run_site"
              rules={[{ required: false, message: 'Please enter a path' }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
            <Form.Item
              label="Address"
              name="address"
              rules={[{ required: false, message: 'Please enter a path' }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row>
          <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
            <Form.Item
              label="Notes"
              name="note"
              rules={[{ required: false, message: 'Please enter a path' }]}
              labelCol={{ span: 24 }}
            >
              <TextArea disabled rows={4} />
            </Form.Item>
          </Col>
        </Row> */}
        {/* <Row>
          <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
            <Form.Item
              label="Cover Image"
              name="cover_image"
              labelCol={{ span: 24 }}
            >
              <Image
                width={200}
                src={imageURL}
              />
            </Form.Item>
          </Col>
        </Row> */}
      </Form>
    </>
  );
}

export default RunDetails;
