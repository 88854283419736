import {
    Modal,
    Input,
    Form,
    Button,
} from 'antd';
import { useEffect } from 'react';

const { TextArea } = Input;

const AddNoticesModal = (props) => {
    const [formAddNotice] = Form.useForm();
    const { visible, onCancel, onSubmit, noticeCountVal } = props;

    useEffect(() => {
        formAddNotice.setFieldsValue({ "notice_number": (noticeCountVal + 1) })
    })

    const handleSubmit = () => {
        if (!formAddNotice) return;
        formAddNotice.submit();
    };

    const handleFinish = (values) => {
        if (onSubmit) {
            onSubmit(values);
            formAddNotice.resetFields();
        }
    };

    const modalFooter = { onCancel };

    const getModalContent = () => {
        return (
            <Form
                form={formAddNotice}
                name="formAddNotice"
                onFinish={handleFinish}
            >
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'Please enter a title' }]}
                    labelCol={{ span: 24 }}
                >
                    <Input placeholder="Title"/>
                </Form.Item>
                <Form.Item
                    label="Content"
                    name="content"
                    rules={[{ required: true, message: 'Please enter a content' }]}
                    labelCol={{ span: 24 }}
                >
                    <TextArea placeholder="Content" rows={4} />
                </Form.Item>
            </Form>
        );
    };

    return (
        <Modal
            title='Add New Notice'
            destroyOnClose
            width={500}
            open={visible}
            {...modalFooter}
            footer={[
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Add
                </Button>
            ]}
        >
            {getModalContent()}
        </Modal>
    );
};

export default AddNoticesModal;
