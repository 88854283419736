import { message } from "antd";
import { useEffect, useState } from "react";
import { GetDataAuth } from "../apiService/GetData";
import { apis } from "../properties";
import { useRefreshTable } from "../store";
import Progress from "react-progress-2";

function useBeerAddedStock(filterData) {
  const [state, setState] = useState([]);
  const refreshTable = useRefreshTable((state) => state.refreshTable);

  useEffect(() => {
    const getData = () => {
      Progress.show();
      GetDataAuth(`${apis.BEER_ADDED_STOCK}${filterData}`).then((result) => {
        let responseJson = [];
        responseJson = result;
        if (responseJson.length > 0) {
          setState(responseJson);
          Progress.hide();
        } else if (responseJson.status === "error") {
          message.error(responseJson.message);
          Progress.hide();
        } else {
          Progress.hide();
        }
      });
    };
    getData();
    // eslint-disable-next-line
  }, [filterData,refreshTable]);
  return state;
}

export default useBeerAddedStock;
