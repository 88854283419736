import { Button, Tabs, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { BackwardOutlined } from "@ant-design/icons";
import { useState } from "react";
import useBreadCrumb from "../hooks/useBreadCrumb";
import TripDetails from "../components/Trip/TripDetails";
//import TripHaresDataTable from "../components/Hares/TripHaresDataTable";
import TripParticipantsDataTable from "../components/Participants/TripParticipantsDataTable";
import TripIncome from "../components/Income/TripIncome";

const { Title } = Typography;

function ViewTrips() {
  const location = useLocation();
  const navigate = useNavigate();
  useBreadCrumb("Trips/View/Details", location.pathname, "Trips");
  const [tabName, setTabName] = useState("Trip Details");

  const items = [
    {
      key: '1',
      label: `Trip Details`,
      children: <TripDetails />,
    },
    // {
    //   key: '2',
    //   label: `Hares`,
    //   children: <TripHaresDataTable />,
    // },
    {
      key: '2',
      label: `Participants`,
      children: <TripParticipantsDataTable />
    },
    {
      key: '3',
      label: `Income`,
      children: <TripIncome />,
    },
  ];

  const onTabChange = (key) => {
    items.map((item_data) => {
      if (item_data.key === key) {
        setTabName(item_data.label)
      }
      return null
    })
  }

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="tab_top_row">
          <Button
            className="primary__btn"
            onClick={() => navigate(
              `/trips`
            )}
            icon={<BackwardOutlined />}
          />
          <Title
            style={{ paddingTop: 5, paddingLeft: 3 }}
            level={5}
          >
            {tabName}
          </Title>
        </div>
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onTabChange}
        />
      </div>
    </div>
  );
}

export default ViewTrips
