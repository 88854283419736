import {
  EditOutlined,
  EyeOutlined,
  UserAddOutlined,
} from "@ant-design/icons";
import { Button, Space, Table, Tooltip, message, Input, Badge, Tag } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom"
import { PutDataAuth } from "../../apiService/PutData";
import { apis } from "../../properties";
import { useRefreshTable } from "../../store";
import { SearchOutlined } from '@ant-design/icons';
import { PostDataAuth } from "../../apiService/PostData";
import Progress from "react-progress-2";
import useMemberProfilesList from "../../hooks/useMemberProfilesList";
import EditMembersModal from "./EditMembersModal";
import Highlighter from 'react-highlight-words';
import UpdateUsernamePassModal from "./UpdateUsernamePassModal";
import useRunsList from "../../hooks/useRunsList";
import usePaymentsList from "../../hooks/usePaymentsList";
import moment from "moment";
import useMemberStatusList from "../../hooks/useMemberStatusList";
import useRolesList from "../../hooks/useRolesList";
import useTripsList from "../../hooks/useTripsList";

function MembersDataTable(props) {
  const { getMemberProfileCount } = props;
  const navigate = useNavigate();
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [data, setData] = useState([]);
  const [visibleEditMemberModal, setVisibleEditMemberModal] = useState(false);
  const [visibleUpdateUsernamePassModal, setVisibleUpdateUsernamePassModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [memberProfileId, setMemberProfileId] = useState("");
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const memberProfilesList = useMemberProfilesList("");
  const allRunList = useRunsList("");
  const runsList = useRunsList("");
  const allTripList = useTripsList("");
  const tripList = useTripsList("");
  const standingOrderPaymentList = usePaymentsList("?payment_type=Standing Order");
  const memberStatusList = useMemberStatusList("");
  const rolesList = useRolesList("");

  useEffect(() => {
    const tempMemberProfilesListData = [];

    memberProfilesList?.forEach((member_profiles_list_data) => {
      const memberData = {
        "key": member_profiles_list_data._id,
        "_id": member_profiles_list_data._id,
        "member_id": member_profiles_list_data.member_id,
        "member_name": `${member_profiles_list_data.first_name} ${member_profiles_list_data.last_name}`,
        "hash_name": member_profiles_list_data.hash_name,
        "contact_number": member_profiles_list_data.contact_number,
        "email": member_profiles_list_data.email,
        "status": member_profiles_list_data.status,
        "payment_type": member_profiles_list_data.payment_type,
        "run_status": null, // Initialize run_status as null,
        "former_runs": member_profiles_list_data.former_runs,
        "former_trips": member_profiles_list_data.former_trips,
        "former_hares": member_profiles_list_data.former_hares
      };

      runsList?.forEach((run_list_data) => {
        run_list_data?.participants?.forEach((participant_data) => {
          if (participant_data.member_id === member_profiles_list_data.member_id) {
            memberData.run_status = participant_data.run_status;
          }
        });
      });

      tempMemberProfilesListData.push(memberData);
    });

    // Initialize counters for each member
    const memberCounts = {};
    for (const member of tempMemberProfilesListData) {
      memberCounts[member.member_id] = { runs: 0, hares: 0, trips: 0 };
    }
    for (const run of allRunList) {
      // Update runs counter for each participant with matching member ID and run_status "Attended"
      for (const participant of run.participants) {
        if (memberCounts[participant.member_id] && participant.run_status === "Attended") {
          memberCounts[participant.member_id].runs++;
        }
      }

      // Update hares counter for each member ID in the run object if participant.run_status is "Attended"
      for (const member_id of run.member_id) {
        if (memberCounts[member_id] && run.participants.some(participant => participant.member_id === member_id && participant.run_status === "Attended")) {
          memberCounts[member_id].hares++;
        }
      }
    }

    for (const trip of allTripList) {
      // Update trips counter for each participant with matching member ID and trip_status "Attended"
      for (const participant of trip.participants) {
        if (memberCounts[participant.member_id] && participant.trip_status === "Attended") {
          memberCounts[participant.member_id].trips++;
        }
      }
    }

    // Add the counts to the original members array
    for (const member of tempMemberProfilesListData) {
      member.runs = (memberCounts[member.member_id].runs + (member.former_runs || 0));
      member.hares = (memberCounts[member.member_id].hares + (member.former_hares || 0));
      member.trips = (memberCounts[member.member_id].trips + (member.former_trips || 0));

      // Reduce 1 from newly added hares values if both conditions are met
      runsList.forEach((run_list) => {
        if (run_list.complete_status !== "Complete") {
          for (const participant of run_list.participants) {
            if (participant.member_id === member.member_id && participant.run_status === "Attended") {
              // Check if the participant is a hare in the current run_list
              const isHare = run_list.member_id.includes(member.member_id);
              member.runs = Math.max(0, member.runs - 1);
              if (isHare) {
                member.hares = Math.max(0, member.hares - 1);
              }
              break; // No need to check other participants in this run_list
            }
          }
        }
      });

      tripList.forEach((trip) => {
        if (trip.complete_status !== "Complete") {
          for (const participant of trip.participants) {
            if (participant.member_id === member.member_id && participant.trip_status === "Attended") {
              // Check if the participant is a hare in the current trip
              member.trips = Math.max(0, member.trips - 1);
              break; // No need to check other participants in this trip
            }
          }
        }
      });
    }

    setData(tempMemberProfilesListData);

    getMemberProfileCount(memberProfilesList.at(-1)?.member_id || 0)
  }, [allRunList, allTripList, getMemberProfileCount, memberProfilesList, runsList, tripList])

  const showModalEditMember = (value) => {
    const filteredMemberProfilesList = memberProfilesList?.filter((member_profile_list_data) => {
      return member_profile_list_data._id === value._id
    })

    const correctedDateOfBirth = filteredMemberProfilesList[0]?.date_of_birth.replace(/-/g, "/");
    const correctedJoinedDate = filteredMemberProfilesList[0]?.joined_date.replace(/-/g, "/");

    const selectedValueObj = {
      "member_id": filteredMemberProfilesList[0]?.member_id,
      "first_name": filteredMemberProfilesList[0]?.first_name,
      "last_name": filteredMemberProfilesList[0]?.last_name,
      "hash_name": filteredMemberProfilesList[0]?.hash_name,
      "contact_number": filteredMemberProfilesList[0]?.contact_number,
      "email": filteredMemberProfilesList[0]?.email,
      "date_of_birth": correctedDateOfBirth,
      "joined_date": correctedJoinedDate,
      "address": filteredMemberProfilesList[0]?.address,
      "status": filteredMemberProfilesList[0]?.status,
      "role_number": filteredMemberProfilesList[0]?.role_number,
      "former_runs": filteredMemberProfilesList[0]?.former_runs,
      "former_trips": filteredMemberProfilesList[0]?.former_trips,
      "former_hares": filteredMemberProfilesList[0]?.former_hares,
    }

    setSelectedValue(selectedValueObj);
    setVisibleEditMemberModal(true);
    setMemberProfileId(filteredMemberProfilesList[0]?.member_id);
  };

  const showModalUpdateUsernamePass = (memberId) => {
    setMemberProfileId(memberId);
    setVisibleUpdateUsernamePassModal(true);
  }

  const handleCancelEditMemberModal = () => {
    setVisibleEditMemberModal(false);
  };

  const handleCancelUpdateUsernamePassModal = () => {
    setVisibleUpdateUsernamePassModal(false);
  };

  const handleSubmitEditMemberModal = (
    values,
    birthDate,
    joinedDate
  ) => {

    const body = {
      "first_name": values.first_name,
      "last_name": values.last_name,
      "hash_name": values.hash_name,
      "contact_number": values.contact_number,
      "email": values.email || undefined,
      "date_of_birth": birthDate,
      "joined_date": joinedDate,
      "address": {
        "street_number_name": values.street_number_name || undefined,
        "apartment_and_number": values.apartment_and_number || undefined,
        "city": values.city || undefined,
        "country": values.country || undefined,
        "state": values.state || undefined,
        "zip_code": values.zip_code || undefined
      },
      "status": values.status,
      "role_number": values.role_number || undefined,
      "former_runs": values.former_runs || undefined,
      "former_trips": values.former_trips || undefined,
      "former_hares": values.former_hares || undefined
    }

    PutDataAuth(`${apis.MEMBER_PROFILES_LIST}/${memberProfileId}`, body).then((result) => {
      let responseJson = result;

      if (responseJson === "success") {
        message.success("Member updated successfully")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
        message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });

    setVisibleEditMemberModal(false)
  };

  const handleSubmitUpdateUsernamePassModal = (values) => {
    PostDataAuth(`${apis.AUTH_REGISTER}`, values).then((result) => {
      let responseJson = result;

      if (responseJson.member_id || responseJson === "success") {
        message.success("Credentials updated successfully")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
        message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });

    setVisibleUpdateUsernamePassModal(false);
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Member ID",
      dataIndex: "member_id",
      sorter: (a, b) => a.member_id - b.member_id,
      sortOrder: 'descend',
      ...getColumnSearchProps('member_id'),
    },
    {
      title: "Member Name",
      dataIndex: "member_name",
      ...getColumnSearchProps('member_name'),
      render: (member_name, record) => {
        // Check if the payment type is 'Standing Order'
        if (record.payment_type === "Standing Order") {
          // Render a Badge before the member name
          return (
            <div>
              <Badge color="#ffec3d" />
              <span>{member_name}</span>
            </div>
          );
        }
        // If not, render the member name as usual
        return member_name;
      },
    },
    {
      title: "Hash Name",
      dataIndex: "hash_name",
      ...getColumnSearchProps('hash_name'),
    },
    {
      title: "Hares",
      dataIndex: "hares",
      ...getColumnSearchProps('hares'),
      render: (hares) => {
        const isMultipleOfFiveMinusOne = (hares % 5 === 4);
        return (
          <>
            {isMultipleOfFiveMinusOne ? <Badge status="success" text={hares} /> : <>{hares}</>}
          </>
        );
      },
    },
    {
      title: "Runs",
      dataIndex: "runs",
      ...getColumnSearchProps('runs'),
      render: (runs) => {
        const isMultipleOfFiveMinusOne = (runs % 5 === 4);
        return (
          <>
            {isMultipleOfFiveMinusOne ? <Badge status="success" text={runs} /> : <>{runs}</>}
          </>
        );
      },
    },
    {
      title: "Trips",
      dataIndex: "trips",
      render: (trips) => {
        const isMultipleOfFiveMinusOne = (trips % 5 === 4);
        return (
          <>
            {isMultipleOfFiveMinusOne ? <Badge status="success" text={trips} /> : <>{trips}</>}
          </>
        );
      },
    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      ...getColumnSearchProps('contact_number'),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps('email'),
    },
    {
      title: "Payment Type",
      dataIndex: "payment_type",
      render: (payment_type, record) => {
        return (
          <>
            {payment_type === "Standing Order" ? (
              <div>
                {standingOrderPaymentList.map((standing_order_payment, indexSt) => {
                  if (standing_order_payment.member_id === record.member_id) {
                    const expirationMonth = standing_order_payment.payment_date[standing_order_payment.payment_date.length - 1];
                    const monthBeforeExpiration = moment(expirationMonth).subtract(1, 'month').format('YYYY-MM');

                    if (standing_order_payment.payment_date.includes(monthBeforeExpiration)) {
                      return (
                        <Tag key={indexSt} style={{ marginBottom: 5 }} color="red">Expiring Soon</Tag>
                      )
                    }
                  }

                  return null
                })}
                <Tag style={{ color: 'black' }} color="#ffec3d">Standing Order</Tag>
              </div>
            ) : (
              <Tag style={{ color: 'black' }} color="#87d068">Cash</Tag>
            )}
          </>
        )
      }
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        const matchingStatus = memberStatusList.find(
          (member_status_data) => member_status_data._id === status
        );

        if (matchingStatus) {
          return <Tag>{matchingStatus.member_status}</Tag>;
        }

        return null
      }
    },
    {
      title: "Action",
      render: (text, record) => (
        <Space>
          <Tooltip placement="bottom" title="Edit Member">
            <Button
              className="edit_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => showModalEditMember(record)}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="View Details">
            <Button
              className="view_button"
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() =>
                navigate(
                  `/members/view/${record._id}`
                )
              }
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Update Username Password">
            <Button
              className="edit_button"
              shape="circle"
              icon={<UserAddOutlined />}
              onClick={() => showModalUpdateUsernamePass(record.member_id)}
            />
          </Tooltip>
        </Space>
      ),
      fixed: "right",
      align: 'left',
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination
        scroll={{ x: 1500 }}
      />
      <EditMembersModal
        visible={visibleEditMemberModal}
        onDone={handleCancelEditMemberModal}
        onCancel={handleCancelEditMemberModal}
        onSubmit={handleSubmitEditMemberModal}
        selectedValueData={selectedValue}
        memberStatusListVal={memberStatusList}
        rolesListVal={rolesList}
      />
      <UpdateUsernamePassModal
        visible={visibleUpdateUsernamePassModal}
        onDone={handleCancelUpdateUsernamePassModal}
        onCancel={handleCancelUpdateUsernamePassModal}
        onSubmit={handleSubmitUpdateUsernamePassModal}
        memberId={memberProfileId}
      />
    </>
  );
}

export default MembersDataTable;
