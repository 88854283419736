import { Button, Tabs, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import useBreadCrumb from "../hooks/useBreadCrumb";
import MemberDetails from "../components/Member/MemberDetails";
import MembersRunDataTable from "../components/Member/MembersRunDataTable";
import MembersTripDataTable from "../components/Member/MembersTripDataTable";
import MembersPaymentDataTable from "../components/Member/MembersPaymentDataTable";
import { BackwardOutlined } from "@ant-design/icons";
import { useState } from "react";

const { Title } = Typography;

function ViewMembers() {
  const location = useLocation();
  const navigate = useNavigate();
  useBreadCrumb("Members/View/Details", location.pathname, "Members");
  const [tabName, setTabName] = useState("Member Details");

  const items = [
    {
      key: '1',
      label: `Member Details`,
      children: <MemberDetails />,
    },
    {
      key: '2',
      label: `Runs`,
      children: <MembersRunDataTable />,
    },
    {
      key: '3',
      label: `Trips`,
      children: <MembersTripDataTable />,
    },
    {
      key: '4',
      label: `Payments`,
      children: <MembersPaymentDataTable />,
    },
  ];

  const onTabChange = (key) => {
    items.map((item_data) => {
      if (item_data.key === key) {
        setTabName(item_data.label)
      }
      return null
    })
  }

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="tab_top_row">
          <Button
            className="primary__btn"
            onClick={() => navigate(
              `/members`
            )}
            icon={<BackwardOutlined />}
          />
          <Title
            style={{ paddingTop: 5, paddingLeft: 3 }}
            level={5}
          >
            {tabName}
          </Title>
        </div>
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onTabChange}
        />
      </div>
    </div>
  );
}

export default ViewMembers
