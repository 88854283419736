import {
    Modal,
    Form,
    Select,
    Button,
    Input
} from 'antd';
import { useEffect } from 'react';

const EditRolesModal = (props) => {
    const [formEditRole] = Form.useForm();
    const {
        visible,
        onCancel,
        onSubmit,
        selectedValueData,
    } = props;

    useEffect(() => {
        formEditRole.setFieldsValue({ "role_id": selectedValueData.role_id })
        formEditRole.setFieldsValue({ "role_name": selectedValueData.role_name })
        formEditRole.setFieldsValue({ "status": selectedValueData.status })
    })

    const handleSubmit = () => {
        if (!formEditRole) return;
        formEditRole.submit();
    };

    const handleFinish = (values) => {
        if (onSubmit) {
            onSubmit(values);
            formEditRole.resetFields();
        }
    };

    const modalFooter = { onCancel };

    const getModalContent = () => {
        return (
            <Form
                form={formEditRole}
                name="formEditRole"
                onFinish={handleFinish}
            >
                <Form.Item
                    label="Role Name"
                    name="role_name"
                    labelCol={{ span: 24 }}
                >
                    <Input placeholder="Enter role"/>
                </Form.Item>
                <Form.Item
                    label="Status"
                    name="status"
                    labelCol={{ span: 24 }}
                >
                    <Select
                        placeholder="Select a status"
                        options={[
                            { value: 'Admin', label: 'Admin' },
                            { value: 'Super Admin', label: 'Super Admin' },
                        ]}
                    />
                </Form.Item>
            </Form>
        );
    };

    return (
        <Modal
            title='Change Status'
            destroyOnClose
            width={500}
            open={visible}
            {...modalFooter}
            footer={[
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Change
                </Button>
            ]}
        >
            {getModalContent()}
        </Modal>
    );
};

export default EditRolesModal;
