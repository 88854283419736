import {
    Modal,
    Input,
    Form,
    Button
} from 'antd';

const AddMemberStatusModal = (props) => {
    const [formAddMemberStatus] = Form.useForm();
    const { visible, onCancel, onSubmit } = props;

    const handleSubmit = () => {
        if (!formAddMemberStatus) return;
        formAddMemberStatus.submit();
    };

    const handleFinish = (values) => {
        if (onSubmit) {
            onSubmit(values);
            formAddMemberStatus.resetFields();
        }
    };

    const modalFooter = { onCancel };

    const getModalContent = () => {
        return (
            <Form
                form={formAddMemberStatus}
                name="formAddMemberStatus"
                onFinish={handleFinish}
            >
                <Form.Item
                    label="Member Status"
                    name="member_status"
                    rules={[{ required: true, message: 'Please enter a member status' }]}
                    labelCol={{ span: 24 }}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Amount (LKR)"
                    name="amount"
                    rules={[{ required: true, message: 'Please enter a amount' }, {
                        pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                        message: "Please enter a number"
                    }]}
                    labelCol={{ span: 24 }}
                >
                    <Input />
                </Form.Item>
            </Form>
        );
    };

    return (
        <Modal
            title='Add Member Status'
            destroyOnClose
            width={500}
            open={visible}
            {...modalFooter}
            footer={[
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Add
                </Button>
            ]}
        >
            {getModalContent()}
        </Modal>
    );
};

export default AddMemberStatusModal;
