import {
    Modal,
    Input,
    Row,
    Col,
    Form,
    DatePicker,
    Select,
    Button
} from 'antd';
import { useEffect, useState } from 'react';
import { country_and_states } from '../../util/CountryAndState';
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"

dayjs.extend(weekday)
dayjs.extend(localeData)

const dateFormat = 'YYYY/MM/DD';
const { Option } = Select;

const EditMembersModal = (props) => {
    const [formEditMember] = Form.useForm();
    const {
        visible,
        onCancel,
        onSubmit,
        selectedValueData,
        memberStatusListVal,
        rolesListVal
    } = props;
    const [birthDate, setBirthDate] = useState();
    const [joinedDate, setJoinedDate] = useState();
    const [memberStatusData, setMemberStatusData] = useState([]);
    const [roleData, setRoleData] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState('');
    const [selectedState, setSelectedState] = useState('');

    useEffect(() => {
        formEditMember.setFieldsValue({ "member_id": selectedValueData.member_id });
        formEditMember.setFieldsValue({ "first_name": selectedValueData.first_name });
        formEditMember.setFieldsValue({ "last_name": selectedValueData.last_name });
        formEditMember.setFieldsValue({ "hash_name": selectedValueData.hash_name });
        formEditMember.setFieldsValue({ "contact_number": selectedValueData.contact_number });
        formEditMember.setFieldsValue({ "email": selectedValueData.email });
        formEditMember.setFieldsValue({ "date_of_birth": dayjs(selectedValueData.date_of_birth, dateFormat) });
        formEditMember.setFieldsValue({ "joined_date": dayjs(selectedValueData.joined_date, dateFormat) });
        formEditMember.setFieldsValue({ "street_number_name": selectedValueData?.address?.street_number_name });
        formEditMember.setFieldsValue({ "apartment_and_number": selectedValueData?.address?.apartment_and_number });
        formEditMember.setFieldsValue({ "city": selectedValueData?.address?.city });
        formEditMember.setFieldsValue({ "country": selectedValueData?.address?.country });
        formEditMember.setFieldsValue({ "state": selectedValueData?.address?.state });
        formEditMember.setFieldsValue({ "zip_code": selectedValueData?.address?.zip_code });
        formEditMember.setFieldsValue({ "status": selectedValueData.status });
        formEditMember.setFieldsValue({ "role_number": selectedValueData.role_number });
        formEditMember.setFieldsValue({ "former_runs": selectedValueData.former_runs });
        formEditMember.setFieldsValue({ "former_trips": selectedValueData.former_trips });
        formEditMember.setFieldsValue({ "former_hares": selectedValueData.former_hares });

        setSelectedCountry(selectedValueData?.address?.country);
        setSelectedState(selectedValueData?.address?.state);

        const tempMemberStatus = []
        memberStatusListVal.map((member_status_data) => {
            tempMemberStatus.push({
                value: member_status_data._id,
                label: member_status_data.member_status
            })

            return null
        })

        setMemberStatusData(tempMemberStatus);

        const tempRoleList = []
        rolesListVal.map((role_list_data) => {
            tempRoleList.push({
                value: role_list_data.role_number,
                label: role_list_data.role_name
            })

            return null
        })

        tempRoleList.push({
            value: -1,
            label: "Member"
        })

        setRoleData(tempRoleList);
    }, [selectedValueData, memberStatusListVal, formEditMember, rolesListVal])

    const handleSubmit = () => {
        if (!formEditMember) return;
        formEditMember.submit();
    };

    const handleFinish = (values) => {
        if (onSubmit) {
            onSubmit(values, birthDate, joinedDate);
            formEditMember.resetFields();
        }
    };

    const onBirthDatePickerChange = (date, dateString) => {
        setBirthDate(dateString)
    };

    const onJoinedDatePickerChange = (date, dateString) => {
        setJoinedDate(dateString)
    };

    const handleCountryChange = (value) => {
        setSelectedCountry(value);
        // Reset the selected state when the country changes
        setSelectedState('');
        formEditMember.resetFields(['state'])
    };

    const handleStateChange = (value) => {
        setSelectedState(value);
    };

    const modalFooter = { onCancel };

    const getModalContent = () => {
        return (
            <Form
                form={formEditMember}
                name="formEditMember"
                onFinish={handleFinish}
                style={{ paddingLeft: 20, paddingRight: 20 }}
            >
                <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Member ID"
                            name="member_id"
                            rules={[{ required: false }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="First Name"
                            name="first_name"
                            rules={[{ required: true, message: 'Please enter a first name' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Last Name"
                            name="last_name"
                            rules={[{ required: true, message: 'Please enter a last name' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Hash Name"
                            name="hash_name"
                            rules={[{ required: false }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Contact Number"
                            name="contact_number"
                            rules={[{ required: true, message: 'Please enter a contact number' },
                            {
                                pattern: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
                                message: "Please enter a valid phone number"
                            }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Email Address"
                            name="email"
                            rules={[{ required: false }, {
                                pattern: /\S+@\S+\.\S+/,
                                message: "Please enter a valid email address"
                            }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Date of Birth"
                            name="date_of_birth"
                            rules={[{ required: true, message: 'Please select a date' }]}
                            labelCol={{ span: 24 }}
                        >
                            <DatePicker
                                format={dateFormat}
                                onChange={onBirthDatePickerChange}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Joined Date"
                            name="joined_date"
                            rules={[{ required: true, message: 'Please select a date' }]}
                            labelCol={{ span: 24 }}
                        >
                            <DatePicker
                                defaultValue={dayjs("", dateFormat)}
                                format={dateFormat}
                                onChange={onJoinedDatePickerChange}
                                style={{ width: "100%" }}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
                        <Form.Item
                            label="Street Number and Name"
                            name="street_number_name"
                            rules={[{ required: false, message: 'Please enter a street number and name' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="Add street number and name" rows={4} />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
                        <Form.Item
                            label="Apartment and Number"
                            name="apartment_and_number"
                            rules={[{ required: false, message: 'Please enter a apartment and number' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="Add apartment and number" rows={4} />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
                        <Form.Item
                            label="City"
                            name="city"
                            rules={[{ required: false, message: 'Please enter a city' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="Add city" rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
                        <Form.Item
                            label="Country"
                            name="country"
                            rules={[{ required: false, message: 'Please enter a country' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Select
                                value={selectedCountry}
                                defaultValue={selectedCountry}
                                placeholder="Select a country"
                                onChange={handleCountryChange}
                                showSearch
                            >
                                {Object.keys(country_and_states.country).map((countryCode) => (
                                    <Option key={countryCode} value={countryCode}>
                                        {country_and_states.country[countryCode]}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
                        <Form.Item
                            label="State"
                            name="state"
                            rules={[{ required: false, message: 'Please enter a state' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Select
                                value={selectedState}
                                defaultValue={selectedState}
                                placeholder="Select a state"
                                onChange={handleStateChange}
                                showSearch
                            >
                                {selectedCountry && country_and_states.states[selectedCountry] &&
                                    country_and_states.states[selectedCountry].map((state) => (
                                        <Option key={state.code} value={state.code}>
                                            {state.name}
                                        </Option>
                                    ))}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
                        <Form.Item
                            label="Zip Code"
                            name="zip_code"
                            rules={[{ required: false, message: 'Please enter a zip code' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="Add zip code" rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Status"
                            name="status"
                            rules={[{ required: true, message: 'Please select a status' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label?.toLowerCase() ?? '').localeCompare((optionB?.label?.toLowerCase() ?? ''))
                                }
                                options={memberStatusData}
                            />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="MMC Role"
                            name="role_number"
                            labelCol={{ span: 24 }}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label?.toLowerCase() ?? '').localeCompare((optionB?.label?.toLowerCase() ?? ''))
                                }
                                placeholder="Select MMC role"
                                options={roleData}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Former Runs Number"
                            name="former_runs"
                            rules={[{
                                pattern: /^[0-9]+$/,
                                message: "Please enter a number"
                            }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Former Trips Number"
                            name="former_trips"
                            rules={[{
                                pattern: /^[0-9]+$/,
                                message: "Please enter a number"
                            }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Former Hares Number"
                            name="former_hares"
                            rules={[{
                                pattern: /^[0-9]+$/,
                                message: "Please enter a number"
                            }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Modal
            title='Edit Member'
            destroyOnClose
            width={1000}
            open={visible}
            {...modalFooter}
            footer={[
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Save Details
                </Button>
            ]}
        >
            {getModalContent()}
        </Modal>
    );
};

export default EditMembersModal;
