import {
  Button,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PostDataAuth } from "../apiService/PostData";
import { apis } from "../properties";
import { useRefreshTable } from "../store";
import { PutDataAuth } from "../apiService/PutData";
import Progress from "react-progress-2";
import useBreadCrumb from "../hooks/useBreadCrumb";
import NewStandingOrderModal from "../components/Payment/NewStandingOrderModal";
import PaymentsDataTable from "../components/Payment/PaymentsDataTable";
import useMemberProfilesList from "../hooks/useMemberProfilesList";

function Payments() {
  const location = useLocation();
  useBreadCrumb("Payments", location.pathname, "Payments");
  const [visibleNewStandingOrderModal, setVisibleNewStandingOrderModal] = useState(false);
  const [memberData, setMemberData] = useState([]);
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);

  const memberProfileList = useMemberProfilesList("");

  useEffect(() => {
    const tempMemberProfileData = []
    memberProfileList?.map((member_profile_list_data) => {
      tempMemberProfileData.push({
        "value": member_profile_list_data.member_id,
        "label": `${member_profile_list_data.first_name} ${member_profile_list_data.last_name}`
      })

      return null
    })
    setMemberData(tempMemberProfileData)
  }, [memberProfileList])

  const showModalNewStandingOrder = () => {
    setVisibleNewStandingOrderModal(true);
  };

  const handleCancelNewStandingOrderModal = () => {
    setVisibleNewStandingOrderModal(false);
  };

  const handleSubmitNewStandingOrderModal = (
    values,
    uploadedImageKitUrl,
    standingOrderPayment
  ) => {
    const numMonths = values.duration

    const yearMonthArray = [];
  
    // Get starting month
    const currentDate = values.starting_month._d

    // Extract the year and month from the current date
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Months are zero-based, so add 1
    
    // Add the start year and month to the array
    yearMonthArray.push(`${currentYear}-${currentMonth.toString().padStart(2, '0')}`);
    
    // Calculate the end year and month
    const endYear = currentYear + Math.floor((currentMonth + numMonths - 1) / 12);
    const endMonth = (currentMonth + numMonths) % 12 || 12;
    
    // Add the end year and month to the array
    yearMonthArray.push(`${endYear}-${endMonth.toString().padStart(2, '0')}`);

    const body = {
      "payment_date": yearMonthArray,
      "amount": standingOrderPayment,
      "payment_type": "Standing Order",
      "invoice": uploadedImageKitUrl,
      "member_id": values.member_id,
      "payment_description": values.payment_description
    }

    PostDataAuth(apis.PAYMENTS_LIST, body).then((result) => {
      let responseJson = result;

      if (responseJson.payment_number) {
        const filteredMember = memberProfileList.filter((member_profile_data) => {
          return member_profile_data.member_id === values.member_id
        })

        const memberProfileBody = {
          payment_type: "Standing Order"
        }

        PutDataAuth(`${apis.MEMBER_PROFILES_LIST}/${filteredMember[0].member_id}`, memberProfileBody).then((result) => {
          let responseJson = result;

          if (responseJson === "success") {
            message.success("Payment added successfully")
            setRefreshTable(!refreshTable)
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson?.error?.details[0]?.message);
            Progress.hide();
          }
        });

      } else if (responseJson.status === "error") {
        message.error(responseJson?.message);
        Progress.hide();
      }
    });

    setVisibleNewStandingOrderModal(false)
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => showModalNewStandingOrder()}
          >
            New Standing Order
          </Button>
        </div>
     
        <PaymentsDataTable />
        <NewStandingOrderModal
          visible={visibleNewStandingOrderModal}
          onDone={handleCancelNewStandingOrderModal}
          onCancel={handleCancelNewStandingOrderModal}
          onSubmit={handleSubmitNewStandingOrderModal}
          memberDataVal={memberData}
        />
      </div>
    </div>
  );
}

export default Payments
