import {
  FileTextOutlined,
} from "@ant-design/icons";
import { Button, Space, Table, Tooltip, Input } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { SearchOutlined } from '@ant-design/icons';
import useBeerAvailableStock from "../../hooks/useBeerAvailableStock";
import useMemberProfilesList from "../../hooks/useMemberProfilesList";
import Highlighter from 'react-highlight-words';
import jsPDF from "jspdf";
import "jspdf-autotable";

function BeerAvailableDataTable() {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const memberProfileList = useMemberProfilesList("");
  const beerAvailableStockList = useBeerAvailableStock("");

  useEffect(() => {
    const tempAvailableStock = []
    beerAvailableStockList?.forEach((beer_available_stock_data) => {
      memberProfileList?.forEach((member_profile_data) => {

        if (beer_available_stock_data.member_id === member_profile_data.member_id) {
          const tempAvailableQuantity = []
          let isQuantityAvailable = false;

          beer_available_stock_data?.available_quantity?.forEach((available_quantity_data) => {
            if (available_quantity_data.quantity > 0) {
              isQuantityAvailable = true;
            }
            tempAvailableQuantity.push({
              "quantity_data": `${available_quantity_data.volume}ml - ${available_quantity_data.quantity}`
            })
          })

          if (isQuantityAvailable) {
            tempAvailableStock.push({
              "key": beer_available_stock_data.stock_number,
              "stock_number": beer_available_stock_data.available_stock_number,
              "purchased_person": `${member_profile_data.first_name} ${member_profile_data.last_name}`,
              "purchase_date": beer_available_stock_data.purchase_date,
              "quantity_description": tempAvailableQuantity
            })
          }
        }
      })
    })

    setData(tempAvailableStock)
  }, [beerAvailableStockList, memberProfileList]);

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const exportAvailableStockDataPDF = (record) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);

    const title = "Colombo Hash";
    const subtitle = "Available Stock Data";
    const headers = [[
      "Stock Number",
      "Purchased Person",
      "Purchase Date",
      "Quantity Description"
    ]];

    const tableData = [record].map(elt => [
      elt.stock_number,
      elt.purchased_person,
      elt.purchase_date,
      elt.quantity_description.map((quantity_description) => quantity_description.quantity_data).join(),
    ]);

    let content = {
      startY: 65,
      head: headers,
      body: tableData
    };

    // Add title and new subtitle
    doc.text(title, marginLeft, 30);
    doc.setFontSize(12); // Reduce font size for the subtitle
    doc.text(subtitle, marginLeft, 50); // Add the new subtitle below the title

    // Get the current date and time
    const now = new Date();
    const generatedDateTime = `${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;

    // Add generated date and time in the bottom right corner
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.setFontSize(10); // Adjust font size for the generated date and time
    doc.text(generatedDateTime, pageWidth - marginLeft, pageHeight - 30, {
      align: "right",
    });

    doc.autoTable(content);
    doc.save(`stock-number${record.stock_number}-available-stock.pdf`)
  }

  const columns = [
    {
      title: "Stock Number",
      dataIndex: "stock_number",
      sorter: (a, b) => a.stock_number - b.stock_number,
      sortOrder: 'descend',
      ...getColumnSearchProps('stock_number'),
    },
    {
      title: "Purchased Person",
      dataIndex: "purchased_person",
      ...getColumnSearchProps('purchased_person'),
    },
    {
      title: "Purchase Date",
      dataIndex: "purchase_date",
      ...getColumnSearchProps('purchase_date'),
    },
    {
      title: "Quantity Description",
      dataIndex: "quantity_description",
      render: (quantity_description) => quantity_description.map((quantity_description) => quantity_description.quantity_data).join(),
    },
    {
      title: "Action",
      render: (text, record) => (
        <Space>
          <Tooltip placement="bottom" title="Download Invoice">
            <Button
              className="edit_button"
              shape="circle"
              icon={<FileTextOutlined />}
              onClick={() => exportAvailableStockDataPDF(record)}
            />
          </Tooltip>
        </Space>
      ),
      fixed: "right",
      align: 'left',
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination
        scroll={{ x: 1500 }}
      />
    </>
  );
}

export default BeerAvailableDataTable;
