import { useState } from 'react';
import {
    Modal,
    Form,
    Select,
    Checkbox,
    Space,
    Button,
    Typography,
    Row,
    Col,
    Radio
} from 'antd';

const { Text, Title } = Typography;

const AddTripParticipantsModal = (props) => {
    const [formAddParticipant] = Form.useForm();
    const [totalCost, setTotalCost] = useState(0);
    const [roomCheckValue, setRoomCheckValue] = useState("");
    const [transportRate, setTransportRate] = useState(false);
    const [mealRate, setMealRate] = useState(false);
    const {
        visible,
        onCancel,
        onSubmit,
        membersData,
        tripListData
    } = props;

    const participantCount = tripListData?.[0]?.participant_count;
    const currentParticipantCount = tripListData?.[0]?.participants.length;
    const participants = tripListData?.[0]?.participants;
    const tripExpenses = tripListData?.[0]?.trip_expenses?.[0];

    const handleSubmit = () => {
        if (!formAddParticipant) return;
        formAddParticipant.submit();
    };

    const handleFinish = (values) => {
        if (onSubmit) {
            onSubmit(values, roomCheckValue);
            formAddParticipant.resetFields();
            setTotalCost((0/100).toFixed(2));
        }
    };

    const onTransportChange = (e) => {
        setTransportRate(e.target.checked);
    }

    const onMealChange = (e) => {
        setMealRate(e.target.checked);
    }

    const handleCostCalculation = () => {
        let tempTotalCost = 0;
        if (transportRate) {
            tempTotalCost = tempTotalCost + tripListData?.[0]?.trip_expenses?.[0]?.transport_rate_per_person
        }
        if (mealRate) {
            tempTotalCost = tempTotalCost + tripListData?.[0]?.trip_expenses?.[0]?.meal_rate_per_person
        }

        const roomType = roomCheckValue.replace("_count", "");

        // Loop through the trip_expenses array
        for (const expense of tripListData[0]?.trip_expenses) {
            // Access the value of roomCheckValue from the expense object
            const roomCount = expense[roomCheckValue];

            // If roomCount is greater than 0, add the corresponding rate to tempTotalCost
            if (roomCount > 0) {
                const ratePerPerson = expense[`${roomType}_rate_per_person`];
                tempTotalCost += 1 * ratePerPerson;
            }
        }

        setTotalCost(tempTotalCost.toFixed(2));
    }

    const modalFooter = { onCancel };

    const onRoomCheckboxChange = (e) => {
        setRoomCheckValue(e.target.value);
    };

    const getModalContent = () => {
        return (
            <Form
                form={formAddParticipant}
                name="formAddParticipant"
                onFinish={handleFinish}
            >
                <div style={{ marginBottom: 8 }}>
                    <Space>
                        <Text>Current Participant Count: {currentParticipantCount}</Text> |
                        <Text>Participant Count: {participantCount}</Text>
                    </Space>
                </div>
                <Row gutter={32}>
                    <Col xl={{ span: 24 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Text>Participants</Text>
                        <Form.Item
                            name="member_id"
                            rules={[{ required: true, message: 'Please select a participant' }]}
                            labelCol={{ span: 24 }}
                            style={{ paddingTop: 3 }}
                        >
                            <Select
                                showSearch
                                placeholder="Select Members"
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label?.toLowerCase() ?? '').localeCompare((optionB?.label?.toLowerCase() ?? ''))
                                }
                                options={membersData}
                            />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Transport"
                            name="transport"
                            valuePropName="checked"
                            initialValue={false}
                            labelCol={{ span: 24 }}
                        >
                            <Checkbox onChange={onTransportChange} />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 12 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Meals"
                            name="meals"
                            valuePropName="checked"
                            initialValue={false}
                            labelCol={{ span: 24 }}
                        >
                            <Checkbox onChange={onMealChange} />
                        </Form.Item>
                    </Col>
                </Row>

                <Radio.Group onChange={onRoomCheckboxChange}>
                    <Row style={{ marginBottom: 16 }} gutter={32}>
                        {/* First column */}
                        <Col xl={{ span: 12 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                            {tripExpenses?.single_deluxe_room_count > 0 && (
                                <Radio
                                    value={"single_deluxe_room_count"}
                                    disabled={
                                        tripExpenses.single_deluxe_room_count <=
                                        participants.filter(
                                            (participant) =>
                                                participant.room_type === "Single Deluxe Room"
                                        ).length
                                    }
                                >
                                    Single Deluxe Room
                                </Radio>
                            )}
                            {tripExpenses?.single_standard_room_count > 0 && (
                                <Radio
                                    value={"single_standard_room_count"}
                                    disabled={
                                        tripExpenses.single_standard_room_count <=
                                        participants.filter(
                                            (participant) =>
                                                participant.room_type === "Single Standard Room"
                                        ).length
                                    }
                                >
                                    Single Standard Room
                                </Radio>
                            )}
                            {tripExpenses?.double_deluxe_room_count > 0 && (
                                <Radio
                                    value={"double_deluxe_room_count"}
                                    disabled={
                                        (tripExpenses.double_deluxe_room_count * 2) <=
                                        participants.filter(
                                            (participant) =>
                                                participant.room_type === "Double Deluxe Room"
                                        ).length
                                    }
                                >
                                    Double Deluxe Room
                                </Radio>
                            )}
                        </Col>

                        {/* Second column */}
                        <Col xl={{ span: 12 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                            {tripExpenses?.double_standard_room_count > 0 && (
                                <Radio
                                    value={"double_standard_room_count"}
                                    disabled={
                                        (tripExpenses.double_standard_room_count * 2) <=
                                        participants.filter(
                                            (participant) =>
                                                participant.room_type === "Double Standard Room"
                                        ).length
                                    }
                                >
                                    Double Standard Room
                                </Radio>
                            )}
                            {tripExpenses?.triple_deluxe_room_count > 0 && (
                                <Radio
                                    value={"triple_deluxe_room_count"}
                                    disabled={
                                        (tripExpenses.triple_deluxe_room_count * 3) <=
                                        participants.filter(
                                            (participant) =>
                                                participant.room_type === "Triple Deluxe Room"
                                        ).length
                                    }
                                >
                                    Triple Deluxe Room
                                </Radio>
                            )}
                            {tripExpenses?.triple_standard_room_count > 0 && (
                                <Radio
                                    value={"triple_standard_room_count"}
                                    disabled={
                                        (tripExpenses.triple_standard_room_count * 3) <=
                                        participants.filter(
                                            (participant) =>
                                                participant.room_type === "Triple Standard Room"
                                        ).length
                                    }
                                >
                                    Triple Standard Room
                                </Radio>
                            )}
                        </Col>
                    </Row>
                </Radio.Group>

                <Row>
                    <Space direction="vertical">
                        <Space direction="horizontal">
                            <Text>Total Cost</Text> <Title style={{ marginTop: 5 }} level={3}>{`LKR ${totalCost}`}</Title>
                        </Space>
                        <Button type="primary" onClick={handleCostCalculation}>Calculate Cost</Button>
                    </Space>
                </Row>
            </Form>
        );
    };

    return (
        <Modal
            title='Add Participant'
            destroyOnClose
            width={600}
            open={visible}
            {...modalFooter}
            footer={[
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Add
                </Button>
            ]}
        >
            {getModalContent()}
        </Modal>
    );
};

export default AddTripParticipantsModal;
