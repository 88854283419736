import { Table, Input, Button, Space } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';
import useMemberProfilesList from "../../hooks/useMemberProfilesList";
import useTripsList from "../../hooks/useTripsList";
import Highlighter from 'react-highlight-words';

function MembersTripDataTable() {
  const [data, setData] = useState([]);
  const [inRunMemberIdArr, setInRunMemberIdArr] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const params = useParams();

  const memberProfilesList = useMemberProfilesList(`?_id=${params.member_id}`);
  const tripsList = useTripsList("")

  useEffect(() => {
    const tempRunListData = []

    memberProfilesList?.map((member_profiles_list_data) => {
      tripsList?.map((trip_list_data) => {

        trip_list_data?.participants?.map((participant_data) => {

          if (participant_data.member_id === member_profiles_list_data.member_id) {
            tempRunListData.push({
              "key": trip_list_data._id,
              "_id": trip_list_data._id,
              "trip_number": trip_list_data.trip_number,
              "trip_name": trip_list_data.trip_name,
              "trip_date": trip_list_data.starting_date
            })
          }

          return null
        })

        setInRunMemberIdArr(trip_list_data?.participants)

        return null
      })

      return null
    })

    setData(tempRunListData)

  }, [inRunMemberIdArr, memberProfilesList, tripsList])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Trip Number",
      dataIndex: "trip_number",
      sorter: (a, b) => a.trip_number - b.trip_number,
      sortOrder: 'descend',
      ...getColumnSearchProps('trip_number'),
    },
    {
      title: "Trip Name",
      dataIndex: "trip_name",
      ...getColumnSearchProps('trip_name'),
    },
    {
      title: "Trip Date",
      dataIndex: "trip_date",
      ...getColumnSearchProps('trip_date'),
    }
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      pagination
      scroll={{ x: 1500 }}
    />
  );
}

export default MembersTripDataTable;
