import React, { useEffect, useState } from "react";
import { menuItemData } from "./MenuItemRoutes";
import { useStore } from "../../store";
import MenuItem from "./MenuItem";

const SideMenu = () => {
  const activeSideMenu = useStore((state) => state.activeSideMenu);
  const userData = useStore(state => state.profileData);
  const [filteredMenuItems, setFilteredMenuItems] = useState([]);

  const removeActiveClassFromSubMenu = () => {
    document.querySelectorAll(".sub-menu").forEach((el) => {
      el.classList.remove("active");
    });
  };

  useEffect(() => {
    let menuItems = document.querySelectorAll(".menu-item");
    menuItems.forEach((el) => {
      el.addEventListener("click", (e) => {
        const next = el.nextElementSibling;
        removeActiveClassFromSubMenu();
        menuItems.forEach((el) => el.classList.remove("active"));
        el.classList.toggle("active");
        if (next !== null) {
          next.classList.toggle("active");
        }
      });
    });

    const tempFilteredMenuItems = menuItemData.filter((menuItem) => {
      if (userData.role_status === "Super Admin") {
        // Show all menu items for Super Admin
        return true;
      } else if (userData.role_status === "Admin") {
        // Show menu items with privilege "Admin" only for Admin
        return menuItem.privilege.includes("Admin");
      }
      // For any other role status, you can handle the logic accordingly.
      return false;
    });

    setFilteredMenuItems(tempFilteredMenuItems);

  }, [userData]);

  return (
    <div className={`side-menu ${activeSideMenu ? "inactive" : ""}`}>
      <div className="top-section">
        <div className="logo">
          <img src="/images/logomain.png" alt="logo" />
          <span>COLOMBO HASH</span>
        </div>
      </div>

      <div className="main-menu">
        <ul>
           {filteredMenuItems.map((menuItem, index) => (
            <MenuItem
              key={index}
              name={menuItem.name}
              exact={menuItem.exact}
              to={menuItem.to}
              subMenus={menuItem.subMenus || []}
              iconClassName={menuItem.iconClassName}
              route_key={menuItem.route_key}
            />
          ))}
        </ul>
      </div>
    </div>
  );
};

export default SideMenu;
