import React from "react";
import "./statuscard.css";
import { Tooltip } from "antd";

export default function DashBoardBox({ icon, title, count, tooltipData }) {
  return (
    <Tooltip title={tooltipData}>
      <div className="status-card">
      <div className="status-card__icon">
        <i className={icon}></i>
      </div>
      <div className="status-card__info">
        <p>{count}</p>
        <span>{title}</span>
      </div>
    </div>
    </Tooltip>
  );
}
