import { Button, message } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { PostDataAuth } from "../apiService/PostData";
import { apis } from "../properties";
import { useRefreshTable } from "../store";
import Progress from "react-progress-2";
import useBreadCrumb from "../hooks/useBreadCrumb";
import MemberStatusDataTable from "../components/MemberStatus/MemberStatusDataTable";
import AddMemberStatusModal from "../components/MemberStatus/AddMemberStatusModal";

function MembersStatus() {
  const location = useLocation();
  useBreadCrumb("Members Status", location.pathname, "Members Status");
  const [visibleAddMembersStatusModal, setVisibleAddMembersStatusModal] = useState(false);
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);

  const showModalAddMembersStatus = () => {
    setVisibleAddMembersStatusModal(true);
  };

  const handleCancelAddMembersStatusModal = () => {
    setVisibleAddMembersStatusModal(false);
  };

  const handleSubmitAddMembersStatusModal = (
    values
  ) => {

    const body = {
      "member_status": values.member_status,
      "amount": values.amount
    }

    PostDataAuth(apis.MEMBER_STATUS_LIST, body).then((result) => {
      let responseJson = result;

      if (responseJson === "success") {
        message.success("Member Status added successfully")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
          message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });

    setVisibleAddMembersStatusModal(false)
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => showModalAddMembersStatus()}
          >
            Add Member Status
          </Button>
        </div>
        <MemberStatusDataTable />
        <AddMemberStatusModal
          visible={visibleAddMembersStatusModal}
          onDone={handleCancelAddMembersStatusModal}
          onCancel={handleCancelAddMembersStatusModal}
          onSubmit={handleSubmitAddMembersStatusModal}
        />
      </div>
    </div>
  );
}

export default MembersStatus
