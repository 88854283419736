import {
  DeleteOutlined,
  ExclamationCircleOutlined
} from "@ant-design/icons";
import { Button, Space, Table, Tooltip, message, Input, Modal, Tag } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom"
import { PutDataAuth } from "../../apiService/PutData";
import { apis } from "../../properties";
import { useRefreshTable } from "../../store";
import { SearchOutlined } from '@ant-design/icons';
import Progress from "react-progress-2";
import useMemberProfilesList from "../../hooks/useMemberProfilesList";
import AddRunHaresModal from "./AddRunHaresModal";
import useRunsList from "../../hooks/useRunsList";
import Highlighter from 'react-highlight-words';
import useMemberStatusList from "../../hooks/useMemberStatusList";

const { confirm } = Modal;

function RunHaresDataTable() {
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [data, setData] = useState([]);
  const [visibleAddHareModal, setVisibleAddHareModal] = useState(false);
  const [inRunMemberIdArr, setInRunMemberIdArr] = useState([]);
  const [outRunMemberIdArr, setOutRunMemberIdArr] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const params = useParams();

  const memberProfilesList = useMemberProfilesList("");
  const runList = useRunsList(`?_id=${params.run_id}`);
  const memberStatusList = useMemberStatusList("");

  useEffect(() => {
    const tempMemberProfilesListData = []

    memberProfilesList?.map((member_profiles_list_data, indexNo) => {
      runList?.map((run_list_data) => {

        run_list_data?.member_id?.map((member_id) => {

          if (member_id === member_profiles_list_data.member_id) {
            tempMemberProfilesListData.push({
              "key": indexNo,
              "_id": member_profiles_list_data._id,
              "member_id": member_profiles_list_data.member_id,
              "member_name": `${member_profiles_list_data.first_name} ${member_profiles_list_data.last_name}`,
              "hash_name": member_profiles_list_data.hash_name,
              "contact_number": member_profiles_list_data.contact_number,
              "email": member_profiles_list_data.email,
              "status": member_profiles_list_data.status
            })
          }

          return null
        })

        setInRunMemberIdArr(run_list_data?.member_id)

        return null
      })

      return null
    })

    setData(tempMemberProfilesListData)

    const runListMemberIdArr = runList[0]?.member_id
    // filtering member ids already in the run
    const comparedArray = memberProfilesList.filter(ar => !runListMemberIdArr?.find(rm => (rm === ar.member_id && ar.member_id === rm)));

    const tempSetMemberData = []
    comparedArray.map((compared_data) => {
      tempSetMemberData.push({
        "value": compared_data.member_id,
        "label": `${compared_data.member_id} - ${compared_data.first_name} ${compared_data.last_name} / ${compared_data.hash_name}`
      })

      return null
    })

    setOutRunMemberIdArr(tempSetMemberData)
  }, [inRunMemberIdArr, memberProfilesList, runList])


  const showModalAddHare = () => {
    setVisibleAddHareModal(true);
  };

  const handleCancelAddHareModal = () => {
    setVisibleAddHareModal(false);
  };

  const handleSubmitAddHareModal = (
    values
  ) => {
    const submitInRunMemberIdArr = inRunMemberIdArr
    values.member_id.map((member_id_data) => {
      submitInRunMemberIdArr.push(member_id_data)
      return null
    })

    const updatedParticipants = [...runList[0].participants]; // Create a copy of the participants array

    // Loop through each member_id
    for (let i = 0; i < submitInRunMemberIdArr.length; i++) {
      const id = submitInRunMemberIdArr[i];
      let isDuplicate = false;

      // Check if member_id already exists in updatedParticipants array
      for (let j = 0; j < updatedParticipants.length; j++) {
        if (updatedParticipants[j].member_id === id) {
          isDuplicate = true;
          break;
        }
      }

      // If member_id doesn't exist, add it to updatedParticipants array
      if (!isDuplicate) {
        updatedParticipants.push({
          member_id: id,
          run_status: 'Not Attended'
        });
      }
    }

    const body = {
      "member_id": submitInRunMemberIdArr,
      //"participants": updatedParticipants
    }

    Progress.show();
    PutDataAuth(`${apis.RUNS_LIST}/${params.run_id}`, body).then((result) => {
      let responseJson = result;

      if (responseJson === "success") {
        message.success("Hare added to the run successfully")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
        message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });

    setVisibleAddHareModal(false)
  };

  const handleRemoveHare = (value) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to delete this content?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        const submitMemberIdArr = inRunMemberIdArr.filter((item) => {
          return item !== value.member_id
        })

        const body = {
          "member_id": submitMemberIdArr
        }

        PutDataAuth(`${apis.RUNS_LIST}/${params.run_id}`, body).then((result) => {
          let responseJson = result;

          if (responseJson === "success") {
            message.success("Hare removed from the run successfully")
            setRefreshTable(!refreshTable)
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson?.error?.details[0]?.message);
            Progress.hide();
          }
        });

      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Member ID",
      dataIndex: "member_id",
      sorter: (a, b) => a.member_id - b.member_id,
      sortOrder: 'descend',
      ...getColumnSearchProps('trip_number'),
    },
    {
      title: "Member Name",
      dataIndex: "member_name",
      ...getColumnSearchProps('member_name'),
    },
    {
      title: "Hash Name",
      dataIndex: "hash_name",
      ...getColumnSearchProps('hash_name'),
    },
    {
      title: "Contact Number",
      dataIndex: "contact_number",
      ...getColumnSearchProps('contact_number'),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...getColumnSearchProps('email'),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        const matchingStatus = memberStatusList.find(
          (member_status_data) => member_status_data._id === status
        );

        if (matchingStatus) {
          return <Tag>{matchingStatus.member_status}</Tag>;
        }

        return null
      }
    },
    {
      title: "Action",
      render: (text, record) => (
        <Space>
          <Tooltip placement="bottom" title="Delete Member">
            <Button
              className={runList[0]?.complete_status === "Complete" ? "delete_button_disabled" : "delete_button"}
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => handleRemoveHare(record)}
              disabled={runList[0]?.complete_status === "Complete"}
            />
          </Tooltip>
        </Space>
      ),
      fixed: "right",
      align: 'left',
    },
  ];

  return (
    <>
      <div className="top_row">
        <Button
          className="primary__btn"
          onClick={() => showModalAddHare()}
          disabled={runList[0]?.complete_status === "Complete"}
        >
          Add Hare
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination
        scroll={{ x: 1500 }}
      />
      <AddRunHaresModal
        visible={visibleAddHareModal}
        onDone={handleCancelAddHareModal}
        onCancel={handleCancelAddHareModal}
        onSubmit={handleSubmitAddHareModal}
        membersData={outRunMemberIdArr}
      />
    </>
  );
}

export default RunHaresDataTable;
