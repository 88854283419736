import "react-progress-2/main.css";
import "./assets/css/Dashboard.css";
import "./assets/css/Login.css";
import "./assets/css/Header.css";
import "./assets/css/MenuSideBar.css";
import "./assets/css/ThemeChange.css";
import "./assets/css/Responsive.css";
import "./assets/css/AddTripDetails.css";
import "./assets/css/Payment.css";
import "./assets/css/BeerStock.css";

import { Routes, Route } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useStore } from "./store";
import Progress from "react-progress-2";
import NotFount from "./errorPage/404";
import Dashboard from "./pages/Dashboard";
import Layout from "./Layout";
import Login from "./pages/Login";
import Runs from "./pages/Runs";
import Members from "./pages/Members";
import ViewMembers from "./pages/ViewMembers";
import ViewRuns from "./pages/ViewRuns";
import Trips from "./pages/Trips";
import ViewTrips from "./pages/ViewTrips";
import AddTripDetails from "./pages/AddTripDetails";
import EditTripDetails from "./pages/EditTripDetails";
import Roles from "./pages/Roles";
import BeerStocks from "./pages/BeerStocks";
import Notices from "./pages/Notices";
import Payments from "./pages/Payments";
import MembersStatus from "./pages/MembersStatus";
import Auth from "./auth/Auth";

const checkPrivilege = (privilege, userRole) => {
  if (userRole === "Super Admin") {
    return true;
  }
  return privilege.includes(userRole);
};

export const ProtectedRoute = ({ children }) => {
  const auth = Auth.isAuthenticated();
  return auth ? children : <Navigate to="/login" />;
};

export const RouteWithPrivilege = ({ element, privilege }) => {
  const userData = useStore(state => state.profileData);

  const auth = Auth.isAuthenticated();
  const userRole = userData.role_status;

  if (auth && checkPrivilege(privilege, userRole)) {
    return element;
  }

  return <Navigate to="/not-found" />;
};

function App() {
  return (
    <div className="App">
      <Progress.Component />
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <Layout />
            </ProtectedRoute>
          }
        >
          <Route
            path="/dashboard"
            element={
              <RouteWithPrivilege element={<Dashboard />} privilege={["Admin"]} />
            }
          />

          <Route
            path="/runs"
            element={<RouteWithPrivilege element={<Runs />} privilege={["Admin"]} />}
          />
          <Route path="/runs/view/:run_id" element={<ViewRuns />} />

          <Route
            path="/trips"
            element={<RouteWithPrivilege element={<Trips />} privilege={["Admin"]} />}
          />
          <Route path="/trips/view/:trip_id" element={<ViewTrips />} />
          <Route path="/trips/add/details/:trip_id" element={<AddTripDetails />} />
          <Route path="/trips/edit/details/:trip_id" element={<EditTripDetails />} />

          <Route
            path="/members"
            element={
              <RouteWithPrivilege element={<Members />} privilege={["Super Admin"]} />
            }
          />
          <Route path="/members/view/:member_id" element={<ViewMembers />} />

          <Route
            path="/members/status"
            element={
              <RouteWithPrivilege element={<MembersStatus />} privilege={["Super Admin"]} />
            }
          />

          <Route path="/notices" element={<RouteWithPrivilege element={<Notices />} privilege={["Super Admin"]} />} />

          <Route path="/roles" element={<RouteWithPrivilege element={<Roles />} privilege={["Super Admin"]} />} />

          <Route path="/beer/stocks" element={<RouteWithPrivilege element={<BeerStocks />} privilege={["Super Admin"]} />} />

          <Route path="/payments" element={<RouteWithPrivilege element={<Payments />} privilege={["Super Admin"]} />} />

        </Route>
        <Route path="*" element={<NotFount />} />
      </Routes>
    </div>
  );
}

export default App;
