import {
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Space, Table, Tooltip, Input, Select } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { apis } from "../../properties";
import { useRefreshTable, useStore } from "../../store";
import { useNavigate } from "react-router-dom";
import { PutDataAuth } from "../../apiService/PutData";
import { SearchOutlined } from '@ant-design/icons';
import { GetDataAuth } from "../../apiService/GetData";
import useTripsList from "../../hooks/useTripsList";
import Progress from "react-progress-2";
import Highlighter from 'react-highlight-words';

const { confirm } = Modal;

function TripsDataTable(props) {
  const { getTripCount } = props;
  const navigate = useNavigate();
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const userData = useStore(state => state.profileData);

  const tripsList = useTripsList("");

  useEffect(() => {
    const tempTripListData = []
    tripsList.map((trip_list_data) => {
      if (trip_list_data.trip_status === "Ongoing") {

        const resStarting = trip_list_data.starting_date.replace(/\./g, "/")
        const resEnding = trip_list_data.ending_date.replace(/\./g, "/")
        const diff = Math.floor((Date.parse(resEnding) - Date.parse(resStarting)) / 86400000);

        tempTripListData.push({
          "key": trip_list_data.trip_number,
          "trip_number": trip_list_data.trip_number,
          "_id": trip_list_data._id,
          "trip_name": trip_list_data.trip_name,
          "starting_date": trip_list_data.starting_date,
          "ending_date": trip_list_data.ending_date,
          "number_of_days": diff,
          "destination": trip_list_data.destination,
          "cover_image_url": trip_list_data.cover_image_url,
          "description": trip_list_data.description,
          "member_id": trip_list_data.member_id,
          "registration_closing_date": trip_list_data.registration_closing_date,
          "trip_details": trip_list_data.trip_details,
          "trip_expenses": trip_list_data.trip_expenses,
          "participants": trip_list_data.participants,
          "income": trip_list_data.income,
          "registration_fee": trip_list_data.registration_fee,
          "trip_status": trip_list_data.trip_status,
          "complete_status": trip_list_data.complete_status,
          "participant_count": trip_list_data?.participants?.length,
          "total_collected_amount": trip_list_data?.income[0]?.total_income?.toFixed(2) || (0 / 100).toFixed(2),
          "created_at": trip_list_data.created_at
        })
      }

      return null
    })

    // Iterate over the runList array
    for (let trip of tripsList) {
      let totalAmount = 0;

      // Calculate the total amount from participants' amounts
      for (let participant_data of trip.participants) {
        if (participant_data.payment_number) {
          if (participant_data.room_cost || participant_data.meal_cost || participant_data.transport_cost) {
            totalAmount += participant_data.room_cost
            totalAmount += participant_data.meal_cost
            totalAmount += participant_data.transport_cost
          }
        }
      }

      // Find the corresponding object in tempRunListData and update the total_collected_amount
      let correspondingObject = tempTripListData.find(obj => obj._id === trip._id);
      if (correspondingObject) {
        correspondingObject.total_collected_amount = totalAmount.toFixed(2);
      }
    }

    setData(tempTripListData)
    getTripCount(tripsList.at(-1)?.trip_number || 0)
  }, [getTripCount, tripsList])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleNavigateEditTrip = (tripId) => {

    Progress.show();
    GetDataAuth(`${apis.TRIPS_LIST}?_id=${tripId}`).then((result) => {
      let responseJson = [];
      responseJson = result;
      if (responseJson.length > 0) {

        let runNumber = []
        responseJson[0]?.trip_details?.map((trip_details_data) => {
          runNumber = trip_details_data.run_number
          return null
        })

        let queryString = ""
        runNumber.map((run_number_data) => {
          queryString += `?day_number=${run_number_data.day_number}?run_number=${run_number_data.run_number}`
          return null
        })

        navigate(`/trips/edit/details/${tripId}${queryString}`)

        Progress.hide();
      } else if (responseJson.status === "error") {
        message.error(responseJson.message);
        Progress.hide();
      } else {
        Progress.hide();
      }
    });
  }

  const handleCompleteStatusChange = (record, event) => {
    const tripListBody = {
      "complete_status": event
    }

    PutDataAuth(`${apis.TRIPS_LIST}/${record._id}`, tripListBody).then((result) => {
      let responseJson = result;
      if (responseJson === "success") {
        message.success("Trip complete status updated successfully")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
        message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });
  }

  const columns = [
    {
      title: "Trip Number",
      dataIndex: "trip_number",
      sorter: (a, b) => a.trip_number - b.trip_number,
      sortOrder: 'descend',
      ...getColumnSearchProps('trip_number'),
    },
    {
      title: "Trip Name",
      dataIndex: "trip_name",
      ...getColumnSearchProps('trip_name'),
    },
    {
      title: "Date",
      dataIndex: "starting_date",
      ...getColumnSearchProps('starting_date'),
    },
    {
      title: "No of Days",
      dataIndex: "number_of_days",
    },
    {
      title: "Destination",
      dataIndex: "destination",
      ...getColumnSearchProps('destination'),
    },
    {
      title: "Participant Count",
      dataIndex: "participant_count",
    },
    {
      title: "Total Collected Amount (LKR)",
      dataIndex: "total_collected_amount",
      fixed: "right",
    },
    {
      title: "Complete Status",
      dataIndex: "complete_status",
      fixed: "right",
      render: (complete_status, record) => {
        const isDisabled = complete_status === "Complete"
        return (
          <Select
            defaultValue={{ value: complete_status || "Incomplete" }}
            onChange={((event) => handleCompleteStatusChange(record, event))}
            disabled={isDisabled}
            options={[
              { value: 'Incomplete', label: 'Incomplete' },
              { value: 'Complete', label: 'Complete' },
            ]}
          />
        )
      }
    },
    {
      title: "Action",
      render: (text, record) => (
        <Space>
          {
            record.complete_status === "Complete" ? <Tooltip placement="bottom" title="Edit Trip">
              <Button
                className="delete_button_disabled"
                shape="circle"
                icon={<EditOutlined />}
                disabled
              />
            </Tooltip> : <Tooltip placement="bottom" title="Edit Trip">
              <Button
                className="edit_button"
                shape="circle"
                icon={<EditOutlined />}
                onClick={() => handleNavigateEditTrip(record._id)}
              />
            </Tooltip>
          }
          <Tooltip placement="bottom" title="View Details">
            <Button
              className="view_button"
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() =>
                navigate(
                  `/trips/view/${record._id}`
                )
              }
            />
          </Tooltip>
          {
            record.complete_status === "Complete" || userData.role_status === "Admin" ? <Tooltip placement="bottom" title="Cancel Trip">
              <Button
                className="delete_button_disabled"
                shape="circle"
                icon={<DeleteOutlined />}
                disabled
              />
            </Tooltip> : <Tooltip placement="bottom" title="Cancel Trip">
              <Button
                className="delete_button"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => deleteTripsContent(record._id)}
              />
            </Tooltip>
          }
        </Space>
      ),
      fixed: "right",
      align: 'left',
    },
  ];

  const deleteTripsContent = (trip_id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to cancel this trip?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        const body = {
          "trip_status": "Cancel"
        }

        Progress.show();
        PutDataAuth(`${apis.TRIPS_LIST}/${trip_id}`, body).then((result) => {
          let responseJson = result;

          if (responseJson === "success") {
            message.success("Trip canceled")
            setRefreshTable(!refreshTable)
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson?.error?.details[0]?.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination
        scroll={{ x: 1500 }}
      />
    </>
  );
}

export default TripsDataTable;
