import { Button, message } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { PostDataAuth } from "../apiService/PostData";
import { apis } from "../properties";
import { useRefreshTable, useStore } from "../store";
import Progress from "react-progress-2";
import useBreadCrumb from "../hooks/useBreadCrumb";
import AddNoticesModal from "../components/Notice/AddNoticesModal";
import NoticesDataTable from "../components/Notice/NoticesDataTable";

function Notices() {
  const location = useLocation();
  useBreadCrumb("Notices", location.pathname, "Notices");
  const [visibleAddNoticeModal, setVisibleAddNoticeModal] = useState(false);
  const [noticeCount, setNoticeCount] = useState(0);
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const userData = useStore(state => state.profileData);

  const showModalAddNotice = () => {
    setVisibleAddNoticeModal(true);
  };

  const handleCancelAddNoticeModal = () => {
    setVisibleAddNoticeModal(false);
  };

  const handleGetNoticeCount = (noticeCount) => {
    setNoticeCount(noticeCount)
  }

  const handleSubmitAddNoticeModal = (
    values
  ) => {

    const body = {
      "title": values.title,
      "content": values.content,
      "username": userData.username,
    }

    PostDataAuth(apis.NOTICES_LIST, body).then((result) => {
      let responseJson = result;

      if (responseJson === "success") {
        message.success("Notice added successfully")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
          message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });

    setVisibleAddNoticeModal(false)
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => showModalAddNotice()}
          >
            Add Notice
          </Button>
        </div>
        <NoticesDataTable getNoticeCount={handleGetNoticeCount} />
        <AddNoticesModal
          visible={visibleAddNoticeModal}
          onDone={handleCancelAddNoticeModal}
          onCancel={handleCancelAddNoticeModal}
          onSubmit={handleSubmitAddNoticeModal}
          noticeCountVal={noticeCount}
        />
      </div>
    </div>
  );
}

export default Notices
