import { Button } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useStore } from "../store";
import TripsDataTable from "../components/Trip/TripsDataTable";
import useBreadCrumb from "../hooks/useBreadCrumb";

function Trips() {
  const location = useLocation();
  const navigate = useNavigate();
  useBreadCrumb("Trips", location.pathname, "Trips");
  const [tripCount, setTripCount] = useState(0);
  const userData = useStore(state => state.profileData);

  const handleTripCount = (tripCount) => {
    setTripCount(tripCount)
  }

  return (
    <div className="dashboard">
      <div className="section_row">
        {userData.role_status !== "Super Admin" ? <></> : <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => navigate(
              `/trips/add/details/${tripCount + 1}`
            )}
          >
            Add Trip
          </Button>
        </div>}
        <TripsDataTable getTripCount={handleTripCount} />
      </div>
    </div>
  );
}

export default Trips
