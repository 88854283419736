import {
    Modal,
    Input,
    Form,
    Button
} from 'antd';
import { useEffect } from 'react';

const UpdateUsernamePassModal = (props) => {
    const [formUpdateUsernamePass] = Form.useForm();
    const {
        visible,
        onCancel,
        onSubmit,
        memberId,
    } = props;

    useEffect(() => {
        formUpdateUsernamePass.setFieldsValue({ "member_id": memberId })
    })

    const handleSubmit = () => {
        if (!formUpdateUsernamePass) return;
        formUpdateUsernamePass.submit();
    };

    const handleFinish = (values) => {
        if (onSubmit) {
            onSubmit(values);
            formUpdateUsernamePass.resetFields();
        }
    };

    const modalFooter = { onCancel };

    const getModalContent = () => {
        return (
            <Form
                form={formUpdateUsernamePass}
                name="formUpdateUsernamePass"
                onFinish={handleFinish}
                style={{ paddingLeft: 20, paddingRight: 20 }}
            >
                <Form.Item
                    label="Member ID"
                    name="member_id"
                    rules={[{ required: false }]}
                    labelCol={{ span: 24 }}
                >
                    <Input disabled />
                </Form.Item>
                <Form.Item
                    label="Username"
                    name="username"
                    rules={[{ required: true, message: 'Please enter a username' }]}
                    labelCol={{ span: 24 }}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please enter a password' }]}
                    labelCol={{ span: 24 }}
                >
                    <Input.Password type="password"/>
                </Form.Item>
            </Form>
        );
    };

    return (
        <Modal
            title='Update Username Password'
            destroyOnClose
            width={500}
            open={visible}
            {...modalFooter}
            footer={[
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Update
                </Button>
            ]}
        >
            {getModalContent()}
        </Modal>
    );
};

export default UpdateUsernamePassModal;
