import {
    Modal,
    Input,
    Row,
    Col,
    Form,
    DatePicker,
    TimePicker,
    //message,
    Typography,
    Space,
    Button,
    // Spin
} from 'antd';
import { useEffect, useState } from 'react';
import useRunsList from '../../hooks/useRunsList';
//import { apis } from '../../properties';
//import { IKContext, IKUpload } from 'imagekitio-react';

const { Text } = Typography;

const AddRunModal = (props) => {
    const [formAddRun] = Form.useForm();
    const {
        visible,
        onCancel,
        onSubmit,
        runCountVal,
        memberStatusListVal
    } = props;
    const [runDate, setRunDate] = useState("");
    const [startingTime, setStartingTime] = useState("");
    //const [uploadedImageKitUrl, setUploadedImageKitUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const runsList = useRunsList("");

    // const publicKey = process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY
    // const urlEndpoint = process.env.REACT_APP_IMAGE_PATH
    // const authenticationEndpoint = apis.IMAGEKIT_AUTH;

    useEffect(() => {
        const tt = runsList.sort((a, b) => a.run_number - b.run_number);
        const runCountG = (tt.at(-1)?.run_number + 1);
        formAddRun.setFieldsValue({ "run_number": runCountG });
        setLoading(false);
    }, [formAddRun, runCountVal, runsList])

    const handleSubmit = () => {
        if (!formAddRun) return;
        formAddRun.submit();
    };

    const handleFinish = (values) => {
        if (onSubmit) {
            onSubmit(
                values,
                runDate,
                startingTime,
                //uploadedImageKitUrl
            );
            formAddRun.resetFields();
        }
    };

    const onRunDatePickerChange = (date, dateString) => {
        setRunDate(dateString)
    };

    const onStartingTimePickerChange = (time, timeString) => {
        setStartingTime(timeString)
    }

    // const onImageUploadError = err => {
    //     message.error(err)
    //     setLoading(false)
    // };

    // const onImageUploadSuccess = res => {
    //     setUploadedImageKitUrl(res.url)
    //     setLoading(false)
    // };

    // const handleImageUploadChange = (e) => {
    //     if (e.type === "change") {
    //         setLoading(true)
    //     }
    // }

    const modalFooter = { onCancel };

    const getCurrentMonth = () => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), now.getDate());
    };

    const disablePastDates = (current) => {
        // Disable past years, months, and dates
        return current && current < getCurrentMonth();
    };

    const getModalContent = () => {
        return (
            <Form
                form={formAddRun}
                name="formAddRun"
                onFinish={handleFinish}
                style={{ paddingLeft: 20, paddingRight: 20 }}
            >
                <Row gutter={16} >
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Run Number"
                            name="run_number"
                            labelCol={{ span: 24 }}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    {/* <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Run Name"
                            name="run_name"
                            rules={[{ required: true, message: 'Please enter a run name' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="Add run name" />
                        </Form.Item>
                    </Col> */}
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Run Site Name"
                            name="run_site_name"
                            rules={[{ required: false, message: 'Please enter a run site' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="Add run site name" style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Run Date"
                            name="run_date"
                            rules={[{ required: true, message: 'Please select a run date' }]}
                            labelCol={{ span: 24 }}
                        >
                            <DatePicker
                                placeholder="Select date"
                                style={{ width: "100%" }}
                                onChange={onRunDatePickerChange}
                                disabledDate={disablePastDates}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Starting Time"
                            name="starting_time"
                            rules={[{ required: true, message: 'Please select a starting time' }]}
                            labelCol={{ span: 24 }}
                        >
                            <TimePicker
                                placeholder="Select time"
                                style={{ width: "100%" }}
                                onChange={onStartingTimePickerChange}
                                format={"HH:mm"}
                            />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 16 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Google Map iFrame URL"
                            name="run_site"
                            rules={[{ required: false, message: 'Please enter a run site' },
                            {
                                pattern: /^<iframe.+src="(.+)".*><\/iframe>$/,
                                message: "Please enter a valid iframe link"
                            }
                            ]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="Add google map url" style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
                        <Form.Item
                            label="Address"
                            name="address"
                            rules={[{ required: false, message: 'Please enter a address' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="Add address" rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Row>
                    <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
                        <Form.Item
                            label="Notes"
                            name="note"
                            rules={[{ required: false, message: 'Please enter a path' }]}
                            labelCol={{ span: 24 }}
                        >
                            <TextArea placeholder="Add notes" rows={4} />
                        </Form.Item>
                    </Col>
                </Row> */}
                {/* <Row>
                    <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
                        <IKContext
                            publicKey={publicKey}
                            urlEndpoint={urlEndpoint}
                            authenticationEndpoint={authenticationEndpoint}
                        >
                            <Text>Cover Image</Text>
                            <Spin spinning={loading}>
                                <IKUpload
                                    fileName=""
                                    onError={onImageUploadError}
                                    onSuccess={onImageUploadSuccess}
                                    onChange={((e) => handleImageUploadChange(e))}
                                />
                            </Spin>
                        </IKContext>
                    </Col>
                </Row> */}
                <Row style={{ paddingTop: 16 }}>
                    <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
                        <Space direction="vertical">
                            <Text strong>Registration Fees</Text>
                            <Space>
                                {memberStatusListVal?.map((member_status_list, index) => (
                                    <Text key={index}>{`${member_status_list.member_status}: LKR ${member_status_list.amount}`}</Text>
                                ))}
                            </Space>
                        </Space>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Modal
            title='Add Run'
            destroyOnClose
            width={1000}
            open={visible}
            {...modalFooter}
            footer={[
                <Button
                    loading={loading}
                    key="submit"
                    type="primary"
                    onClick={handleSubmit}
                >
                    Add Run
                </Button>
            ]}
        >
            {getModalContent()}
        </Modal>
    );
};

export default AddRunModal;
