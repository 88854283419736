export const menuItemData = [
  {
    name: "Dashboard",
    exact: `true`,
    to: "/dashboard",
    iconClassName: "bi bi-cast",
    route_key: "dashboard",
    privilege: ["Admin"],
  },
  {
    name: "Runs",
    exact: `true`,
    to: "/runs",
    iconClassName: "bi bi-person-lines-fill",
    route_key: "runs",
    privilege: ["Admin"],
  },
  {
    name: "Trips",
    exact: `true`,
    to: "/trips",
    iconClassName: "bi bi-people",
    route_key: "trips",
    privilege: ["Admin"],
  },
  {
    name: "Members",
    exact: `true`,
    to: "/members",
    iconClassName: "bi bi-person-check",
    route_key: "members",
    privilege: ["Super Admin"],
  },
  {
    name: "Members Status",
    exact: `true`,
    to: "/members/status",
    iconClassName: "bi bi-person-check",
    route_key: "members/status",
    privilege: ["Super Admin"],
  },
  {
    name: "Notices",
    exact: `true`,
    to: "/notices",
    iconClassName: "bi bi-bell",
    route_key: "notices",
    privilege: ["Super Admin"],
  },
  {
    name: "MMC Roles",
    exact: `true`,
    to: "/roles",
    iconClassName: "bi bi-person-check",
    route_key: "roles",
    privilege: ["Super Admin"],
  },
  {
    name: "Beer Stock",
    exact: `true`,
    to: "/beer/stocks",
    iconClassName: "bi bi-cup-straw",
    route_key: "beer/stocks",
    privilege: ["Super Admin"],
  },
  {
    name: "Payments",
    exact: `true`,
    to: "/payments",
    iconClassName: "bi bi-currency-dollar",
    route_key: "payments",
    privilege: ["Super Admin"],
  },
];
