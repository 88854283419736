import {
    Modal,
    Input,
    Form,
    Row,
    Col,
    Select,
    message,
    Button,
    Typography,
    Space,
    Spin,
    DatePicker,
} from 'antd';
import { useState } from 'react';
import { apis } from '../../properties';
import { IKContext, IKUpload } from 'imagekitio-react';

const { Text } = Typography;
const { TextArea } = Input;

const NewStandingOrderModal = (props) => {
    const [formNewStandingOrder] = Form.useForm();
    const { visible, onCancel, onSubmit, memberDataVal } = props;
    const [uploadedImageKitUrl, setUploadedImageKitUrl] = useState("");
    const [loading, setLoading] = useState(false);
    const [standingOrderPayment, setStandingOrderPayment] = useState(0);
    const [startingDate, setStartingDate] = useState();

    const publicKey = process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY
    const urlEndpoint = process.env.REACT_APP_IMAGE_PATH
    const authenticationEndpoint = apis.IMAGEKIT_AUTH;

    const handleSubmit = () => {
        if (!formNewStandingOrder) return;
        formNewStandingOrder.submit();
    };

    const handleFinish = (values) => {
        if (onSubmit) {
            onSubmit(values, uploadedImageKitUrl, standingOrderPayment);
            formNewStandingOrder.resetFields();
        }
    };

    const onImageUploadError = err => {
        message.error(err)
        setLoading(false)
    };

    const onImageUploadSuccess = res => {
        setUploadedImageKitUrl(res.url)
        setLoading(false)
    };

    const handleImageUploadChange = (e) => {
        if (e.type === "change") {
            setLoading(true)
        }
    }

    const modalFooter = { onCancel };

    const onDurationChange = (e) => {
        const givenDate = new Date(startingDate);
        givenDate.setUTCMonth(givenDate.getUTCMonth() + e);

        const year = givenDate.getUTCFullYear();
        const month = givenDate.getUTCMonth() + 1; // January is 0, so we add 1 to get the correct month.

        formNewStandingOrder.setFieldsValue({ ending_month: `${year}-${month.toString().padStart(2, '0')}` })
        setStandingOrderPayment((e * 3000));
    };

    const onStartingMonthChange = (date) => {
        setStartingDate(date._d);
    }

    const getCurrentMonth = () => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), 1);
    };

    const getModalContent = () => {
        return (
            <Form
                form={formNewStandingOrder}
                name="formNewStandingOrder"
                onFinish={handleFinish}
                style={{ paddingLeft: 20, paddingRight: 20 }}
            >
                <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Member Name"
                            name="member_id"
                            rules={[{ required: true, message: 'Please select a member' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Select
                                showSearch
                                filterOption={(input, option) =>
                                    (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label?.toLowerCase() ?? '').localeCompare((optionB?.label?.toLowerCase() ?? ''))
                                }
                                placeholder="Select member"
                                options={memberDataVal}
                            />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Starting Month"
                            name="starting_month"
                            rules={[{ required: true, message: 'Please select a starting month' }]}
                            labelCol={{ span: 24 }}
                        >
                            <DatePicker
                                onChange={onStartingMonthChange}
                                style={{ width: "100%" }}
                                picker="month"
                                disabledDate={(current) => {
                                    // Disable months before the current month
                                    return current && current < getCurrentMonth();
                                }}
                            />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Duration"
                            name="duration"
                            rules={[{ required: true, message: 'Please select duration' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                                filterSort={(optionA, optionB) =>
                                    parseInt(optionA?.value, 10) - parseInt(optionB?.value, 10)
                                }
                                onChange={onDurationChange}
                                placeholder="Select duration"
                                options={[
                                    { value: 1, label: '1 Month' },
                                    { value: 2, label: '2 Months' },
                                    { value: 3, label: '3 Months' },
                                    { value: 4, label: '4 Months' },
                                    { value: 5, label: '5 Months' },
                                    { value: 6, label: '6 Months' },
                                    { value: 7, label: '7 Months' },
                                    { value: 8, label: '8 Months' },
                                    { value: 9, label: '9 Months' },
                                    { value: 10, label: '10 Months' },
                                    { value: 11, label: '11 Months' },
                                    { value: 12, label: '12 Months' }
                                ]}
                            />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Ending Month"
                            name="ending_month"
                            labelCol={{ span: 24 }}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Payment"
                            name="amount"
                            labelCol={{ span: 24 }}
                        >
                            <Text>{standingOrderPayment.toFixed(2)} LKR</Text>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xl={{ span: 24 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Payment Description"
                            name="payment_description"
                            labelCol={{ span: 24 }}
                            rules={[{ required: true, message: 'Please add a description' }]}
                        >
                            <TextArea />
                        </Form.Item>
                    </Col>
                </Row>
                <IKContext
                    publicKey={publicKey}
                    urlEndpoint={urlEndpoint}
                    authenticationEndpoint={authenticationEndpoint}
                >
                    <Space direction="vertical">
                        <Text>
                            <span style={{ color: 'red' }}>*</span> Bank Slip
                        </Text>
                        <Spin spinning={loading}>
                            <IKUpload
                                fileName=""
                                onError={onImageUploadError}
                                onSuccess={onImageUploadSuccess}
                                onChange={((e) => handleImageUploadChange(e))}
                            />
                        </Spin>
                    </Space>
                </IKContext>
            </Form>
        );
    };

    return (
        <Modal
            title='Create a Standing Order'
            destroyOnClose
            width={1000}
            open={visible}
            {...modalFooter}
            footer={[
                <Button
                    loading={loading}
                    key="submit"
                    type="primary"
                    onClick={handleSubmit}
                >
                    Add Standing Order
                </Button>
            ]}
        >
            {getModalContent()}
        </Modal>
    );
};

export default NewStandingOrderModal;
