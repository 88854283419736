import {
  FileTextOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import {
  Button,
  Space,
  Table,
  Tooltip,
  message,
  Input,
  Modal,
  Select,
  Badge,
  Tag
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom"
import { PutDataAuth } from "../../apiService/PutData";
import { DeleteDataAuth } from "../../apiService/DeleteData";
import { apis } from "../../properties";
import { useRefreshTable } from "../../store";
import { SearchOutlined } from '@ant-design/icons';
import { PostDataAuth } from "../../apiService/PostData";
import Progress from "react-progress-2";
import useMemberProfilesList from "../../hooks/useMemberProfilesList";
import AddRunParticipantsModal from "./AddRunParticipantsModal";
import useRunsList from "../../hooks/useRunsList";
import Highlighter from 'react-highlight-words';
import usePaymentsList from "../../hooks/usePaymentsList";
import moment from "moment";
import useMemberStatusList from "../../hooks/useMemberStatusList";
import jsPDF from "jspdf";
import "jspdf-autotable";

const { confirm } = Modal;

function RunParticipantsDataTable() {
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [data, setData] = useState([]);
  const [visibleAddParticipantModal, setVisibleAddParticipantModal] = useState(false);
  const [inRunMemberIdArr, setInRunMemberIdArr] = useState([]);
  const [outRunMemberIdArr, setOutRunMemberIdArr] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const params = useParams();

  const memberProfilesList = useMemberProfilesList("");
  const standingOrderPaymentList = usePaymentsList("?payment_type=Standing Order");
  const runsList = useRunsList(`?_id=${params.run_id}`);
  const allRunList = useRunsList("");
  const memberStatusList = useMemberStatusList("");

  useEffect(() => {
    const tempMemberProfilesListData = []
    memberProfilesList?.map((member_profiles_list_data) => {
      runsList?.map((run_list_data) => {
        run_list_data?.participants?.map((participant_data) => {

          if (participant_data.member_id === member_profiles_list_data.member_id) {
            if (participant_data.payment_number) {
              tempMemberProfilesListData.push({
                "key": member_profiles_list_data._id,
                "_id": member_profiles_list_data._id,
                "member_id": member_profiles_list_data.member_id,
                "member_name": `${member_profiles_list_data.first_name} ${member_profiles_list_data.last_name}`,
                "hash_name": member_profiles_list_data.hash_name,
                "status": member_profiles_list_data.status,
                "payment_type": member_profiles_list_data.payment_type,
                "payment": "Paid",
                "former_runs": member_profiles_list_data.former_runs,
                "former_trips": member_profiles_list_data.former_trips,
                "former_hares": member_profiles_list_data.former_hares
              })
            } else {
              tempMemberProfilesListData.push({
                "key": member_profiles_list_data._id,
                "_id": member_profiles_list_data._id,
                "member_id": member_profiles_list_data.member_id,
                "member_name": `${member_profiles_list_data.first_name} ${member_profiles_list_data.last_name}`,
                "hash_name": member_profiles_list_data.hash_name,
                "status": member_profiles_list_data.status,
                "payment_type": member_profiles_list_data.payment_type,
                "payment": "Not Paid",
                "former_runs": member_profiles_list_data.former_runs,
                "former_trips": member_profiles_list_data.former_trips,
                "former_hares": member_profiles_list_data.former_hares
              })
            }
          }

          return null
        })

        setInRunMemberIdArr(run_list_data?.participants)

        return null
      })

      return null
    })

    // Initialize counters for each member
    const memberCounts = {};
    for (const member of tempMemberProfilesListData) {
      memberCounts[member.member_id] = { runs: 0, hares: 0 };
    }
    for (const run of allRunList) {
      // Update runs counter for each participant with matching member ID and run_status "Attended"
      for (const participant of run.participants) {
        if (memberCounts[participant.member_id] && participant.run_status === "Attended") {
          memberCounts[participant.member_id].runs++;
        }
      }

      // Update hares counter for each member ID in the run object if participant.run_status is "Attended"
      for (const member_id of run.member_id) {
        if (memberCounts[member_id] && run.participants.some(participant => participant.member_id === member_id && participant.run_status === "Attended")) {
          memberCounts[member_id].hares++;
        }
      }
    }

    // Add the counts to the original members array
    for (const member of tempMemberProfilesListData) {
      member.runs = (memberCounts[member.member_id].runs + (member.former_runs || 0));
      member.hares = (memberCounts[member.member_id].hares + (member.former_hares || 0));

      // Reduce 1 from newly added hares values if both conditions are met
      runsList.forEach((run_list) => {
        if (run_list.complete_status !== "Complete") {
          for (const participant of run_list.participants) {
            if (participant.member_id === member.member_id && participant.run_status === "Attended") {
              // Check if the participant is a hare in the current run_list
              const isHare = run_list.member_id.includes(member.member_id);
              member.runs = Math.max(0, member.runs - 1);
              if (isHare) {
                member.hares = Math.max(0, member.hares - 1);
              }
              break; // No need to check other participants in this run_list
            }
          }
        }
      });
    }

    setData(tempMemberProfilesListData);

    const runListParticipantsArr = runsList[0]?.participants
    // filtering member ids already in the run
    const comparedArray = memberProfilesList.filter(ar => !runListParticipantsArr?.find(rm => (rm.member_id === ar.member_id && ar.member_id === rm.member_id)));

    const tempSetMemberData = []
    comparedArray.map((compared_data) => {
      tempSetMemberData.push({
        "value": compared_data.member_id,
        "label": `${compared_data.member_id} - ${compared_data.first_name} ${compared_data.last_name} / ${compared_data.hash_name}`
      })

      return null
    })

    setOutRunMemberIdArr(tempSetMemberData)
  }, [allRunList, inRunMemberIdArr, memberProfilesList, runsList]);

  useEffect(() => {
    // Get the current year and month
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-based

    // Create a separate array for member_ids
    const expiredMembers = [];

    // Filter the standingOrderPaymentList array
    for (const payment of standingOrderPaymentList) {
      const paymentDate = payment.payment_date[payment.payment_date.length - 1];
      const [endingYear, endingMonth] = paymentDate.split('-').map(Number);

      if (currentYear > endingYear || (currentYear === endingYear && currentMonth > endingMonth)) {
        expiredMembers.push(payment.member_id);
      }
    }

    const memberProfileBody = {
      payment_type: "Cash"
    }

    expiredMembers.map((expired_member_id) => {
      PutDataAuth(`${apis.MEMBER_PROFILES_LIST}/${expired_member_id}`, memberProfileBody).then((result) => {
        let responseJson = result;
        if (responseJson === "success") {
          setRefreshTable(!refreshTable)
          Progress.hide();
        } else if (responseJson.status === "error") {
          message.error(responseJson?.error?.details[0]?.message);
          Progress.hide();
        }
      });

      return null;
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showModalAddParticipant = () => {
    setVisibleAddParticipantModal(true);
  };

  const handleCancelAddParticipantModal = () => {
    setVisibleAddParticipantModal(false);
  };

  const handleSubmitAddParticipantModal = (
    values
  ) => {
    const submitInRunMemberIdArr = inRunMemberIdArr

    values.member_id.map((member_id_data) => {
      submitInRunMemberIdArr.push({
        "member_id": member_id_data,
        "run_status": "Not Attended"
      })

      return null
    })

    const body = {
      "participants": submitInRunMemberIdArr
    }

    Progress.show();
    PutDataAuth(`${apis.RUNS_LIST}/${params.run_id}`, body).then((result) => {
      let responseJson = result;

      if (responseJson === "success") {
        message.success("Participant added to the run successfully")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
        message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });

    setVisibleAddParticipantModal(false)
  };

  const handleRemoveHare = (value) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to delete this content?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        const submitParticipantsArr = inRunMemberIdArr.filter((item) => {
          return item.member_id !== value.member_id
        })

        const body = {
          "participants": submitParticipantsArr
        }

        PutDataAuth(`${apis.RUNS_LIST}/${params.run_id}`, body).then((result) => {
          let responseJson = result;

          if (responseJson === "success") {
            message.success("Participant removed from the run successfully")
            setRefreshTable(!refreshTable)
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson?.error?.details[0]?.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const handlePaymentChange = (record, event) => {
    let amount = 0;
    memberStatusList.map((member_status_data) => {
      if (record.status === member_status_data._id) {
        amount = member_status_data.amount
      }

      return null
    })

    const paymentType = "Cash";
    const paymentDate = new Date().toJSON().slice(0, 10)
    const submitInRunMemberIdArr = runsList[0].participants

    if (event === "Not Paid") {
      for (let i in submitInRunMemberIdArr) {
        if (submitInRunMemberIdArr[i].member_id === record.member_id) {
          delete submitInRunMemberIdArr[i].payment_number;
          delete submitInRunMemberIdArr[i].amount;
          delete submitInRunMemberIdArr[i].payment_type;
          delete submitInRunMemberIdArr[i].payment_description;
          submitInRunMemberIdArr[i].run_status = "Not Attended";
          break; //Stop this loop, we found it!
        }
      }

      DeleteDataAuth(`${apis.PAYMENTS_LIST}?payment_description=run-${runsList[0]?.run_number}&member_id=${record.member_id}`)
        .then((result) => {
          let responseJson = result;
          if (responseJson === "success") {
            const runListBody = {
              "participants": submitInRunMemberIdArr
            }

            PutDataAuth(`${apis.RUNS_LIST}/${params.run_id}`, runListBody).then((result) => {
              let responseJson = result;
              if (responseJson === "success") {
                message.success("Payment removed from the member")
                setRefreshTable(!refreshTable)
                Progress.hide();
              } else if (responseJson.status === "error") {
                message.error(responseJson?.error?.details[0]?.message);
                Progress.hide();
              }
            });

          } else if (responseJson.status === "error") {
            message.error(responseJson?.error?.details[0]?.message);
            Progress.hide();
          }
        });

    } else {
      const body = {
        "payment_date": [paymentDate, ""],
        "amount": amount,
        "payment_type": paymentType,
        "payment_description": `run-${runsList[0].run_number}`,
        "member_id": Number(record.member_id)
      }

      PostDataAuth(`${apis.PAYMENTS_LIST}`, body).then((result) => {
        let responseJson = result;

        if (responseJson.payment_number) {
          for (let i in submitInRunMemberIdArr) {
            if (submitInRunMemberIdArr[i].member_id === record.member_id) {
              submitInRunMemberIdArr[i].payment_number = responseJson.payment_number;
              submitInRunMemberIdArr[i].amount = amount;
              submitInRunMemberIdArr[i].payment_type = paymentType;
              submitInRunMemberIdArr[i].payment_description = `run-${runsList[0].run_number}`;
              submitInRunMemberIdArr[i].run_status = "Attended";
              break; //Stop this loop, we found it!
            }
          }

          const runListBody = {
            "participants": submitInRunMemberIdArr
          }

          PutDataAuth(`${apis.RUNS_LIST}/${params.run_id}`, runListBody).then((result) => {
            let responseJson = result;
            if (responseJson === "success") {
              message.success("Payment added to the member successfully")
              setRefreshTable(!refreshTable)
              Progress.hide();
            } else if (responseJson.status === "error") {
              message.error(responseJson?.error?.details[0]?.message);
              Progress.hide();
            }
          });

        } else if (responseJson.status === "error") {
          message.error(responseJson?.error?.details[0]?.message);
          Progress.hide();
        }
      });
    }
  }

  const handleAttendanceChange = (record, event) => {
    const submitInRunMemberIdArr = runsList[0].participants

    if (event === "Not Paid") {
      for (let i in submitInRunMemberIdArr) {
        if (submitInRunMemberIdArr[i].member_id === record.member_id) {
          delete submitInRunMemberIdArr[i].payment_number;
          delete submitInRunMemberIdArr[i].amount;
          delete submitInRunMemberIdArr[i].payment_type;
          delete submitInRunMemberIdArr[i].payment_description;
          submitInRunMemberIdArr[i].run_status = "Not Attended";
          break; //Stop this loop, we found it!
        }
      }
    } else {
      for (let i in submitInRunMemberIdArr) {
        if (submitInRunMemberIdArr[i].member_id === record.member_id) {
          delete submitInRunMemberIdArr[i].payment_number;
          delete submitInRunMemberIdArr[i].amount;
          delete submitInRunMemberIdArr[i].payment_type;
          delete submitInRunMemberIdArr[i].payment_description;
          submitInRunMemberIdArr[i].run_status = "Attended";
          break; //Stop this loop, we found it!
        }
      }
    }

    const runListBody = {
      "participants": submitInRunMemberIdArr
    }

    PutDataAuth(`${apis.RUNS_LIST}/${params.run_id}`, runListBody).then((result) => {
      let responseJson = result;
      if (responseJson === "success") {
        message.success("Attendance marked")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
        message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const exportParticipantListPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);

    const title = "Colombo Hash";
    const subtitle = `${runsList[0]?.run_number}-${runsList[0]?.run_name}, Run Participant List, Participant Number-${data.length}`;
    const headers = [[
      "Member ID",
      "Member Name",
      "Hash Name",
      "Hares",
      "Runs",
      "Attendance"
    ]];

    const tableData = data.map(elt => [
      elt.member_id,
      elt.member_name,
      elt.hash_name,
      elt.hares,
      elt.runs,
      elt.attendance
    ]);

    let content = {
      startY: 65,
      head: headers,
      body: tableData,
    };

    // Add title and new subtitle
    doc.text(title, marginLeft, 30);
    doc.setFontSize(12); // Reduce font size for the subtitle
    doc.text(subtitle, marginLeft, 50); // Add the new subtitle below the title

    // Get the current date and time
    const now = new Date();
    const generatedDateTime = `${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;

    // Add generated date and time in the bottom right corner
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.setFontSize(10); // Adjust font size for the generated date and time
    doc.text(generatedDateTime, pageWidth - marginLeft, pageHeight - 30, {
      align: "right",
    });

    doc.autoTable(content);
    doc.save(`run-number${runsList[0]?.run_number}-participant-list.pdf`)
  }

  const exportParticipantDataPDF = (record) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);

    const title = "Colombo Hash";
    const subtitle = `${runsList[0]?.run_number}-${runsList[0]?.run_name}, Run Participant Data`;
    const headers = [[
      "Member ID",
      "Member Name",
      "Hash Name",
      "Hares",
      "Runs",
      "Run Number"
    ]];

    const tableData = [record].map(elt => [
      elt.member_id,
      elt.member_name,
      elt.hash_name,
      elt.hares,
      elt.runs,
      runsList[0]?.run_number
    ]);

    let content = {
      startY: 65,
      head: headers,
      body: tableData,
    };

    // Add title and new subtitle
    doc.text(title, marginLeft, 30);
    doc.setFontSize(12); // Reduce font size for the subtitle
    doc.text(subtitle, marginLeft, 50); // Add the new subtitle below the title

    // Get the current date and time
    const now = new Date();
    const generatedDateTime = `${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;

    // Add generated date and time in the bottom right corner
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.setFontSize(10); // Adjust font size for the generated date and time
    doc.text(generatedDateTime, pageWidth - marginLeft, pageHeight - 30, {
      align: "right",
    });

    doc.autoTable(content);
    doc.save(`run-number${runsList[0]?.run_number}-member-id${record.member_id}-participant.pdf`)
  }

  const columns = [
    {
      title: "Member ID",
      dataIndex: "member_id",
      sorter: (a, b) => a.member_id - b.member_id,
      sortOrder: 'descend',
      ...getColumnSearchProps('member_id'),
    },
    {
      title: "Member Name",
      dataIndex: "member_name",
      ...getColumnSearchProps('member_name'),
      render: (member_name, record) => {
        // Check if the payment type is 'Standing Order'
        if (record.payment_type === "Standing Order") {
          // Render a Badge before the member name
          return (
            <div>
              <Badge color="#ffec3d" />
              <span>{member_name}</span>
            </div>
          );
        }
        // If not, render the member name as usual
        return member_name;
      },
    },
    {
      title: "Hash Name",
      dataIndex: "hash_name",
      ...getColumnSearchProps('hash_name'),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        const matchingStatus = memberStatusList.find(
          (member_status_data) => member_status_data._id === status
        );

        if (matchingStatus) {
          return <Tag>{matchingStatus.member_status}</Tag>;
        }

        return null
      }
    },
    {
      title: "Hares",
      dataIndex: "hares",
      render: (hares) => {
        const isMultipleOfFiveMinusOne = (hares % 5 === 4);
        return (
          <>
            {isMultipleOfFiveMinusOne ? <Badge status="success" text={hares} /> : <>{hares}</>}
          </>
        );
      },
    },
    {
      title: "Runs",
      dataIndex: "runs",
      render: (runs) => {
        const isMultipleOfFiveMinusOne = (runs % 5 === 4);
        return (
          <>
            {isMultipleOfFiveMinusOne ? <Badge status="success" text={runs} /> : <>{runs}</>}
          </>
        );
      },
    },
    {
      title: "Payment Type",
      dataIndex: "payment_type",
      render: (payment_type, record) => {
        return (
          <>
            {payment_type === "Standing Order" ? (
              <div>
                {standingOrderPaymentList.map((standing_order_payment, indexSt) => {
                  if (standing_order_payment.member_id === record.member_id) {
                    const expirationMonth = standing_order_payment.payment_date[standing_order_payment.payment_date.length - 1];
                    const monthBeforeExpiration = moment(expirationMonth).subtract(1, 'month').format('YYYY-MM');

                    if (standing_order_payment.payment_date.includes(monthBeforeExpiration)) {
                      return (
                        <Tag key={indexSt} style={{ marginBottom: 5 }} color="red">Expiring Soon</Tag>
                      )
                    }
                  }

                  return null
                })}
                <Tag style={{ color: 'black' }} color="#ffec3d">Standing Order</Tag>
              </div>
            ) : (
              <Tag style={{ color: 'black' }} color="#87d068">Cash</Tag>
            )}
          </>
        )
      }
    },
    {
      title: "Attendance",
      dataIndex: "payment_type",
      render: (payment_type, record) => {
        const isMemberInList = runsList[0]?.member_id?.includes(record.member_id);
        if (payment_type === "Standing Order" || isMemberInList) {
          return runsList[0]?.participants.map((participant_data, index) => {
            if (record.member_id === participant_data.member_id) {
              return (
                <Select
                  key={index}
                  defaultValue={{ value: participant_data.run_status }}
                  onChange={((event) => handleAttendanceChange(record, event))}
                  options={[
                    { value: 'Attended', label: 'Attended' },
                    { value: 'Not Attended', label: 'Not Attended' }
                  ]}
                  disabled={runsList[0]?.complete_status === "Complete"}
                />
              );
            }
            return null; // Add this line if there are other cases where the map doesn't return a value
          });
        } else {
          return runsList[0]?.participants.map((participant_data, index) => {
            if (record.member_id === participant_data.member_id) {
              return (
                <Select
                  key={index}
                  value={{ value: participant_data.run_status }}
                  disabled
                />
              );
            }
            return null; // Add this line if there are other cases where the map doesn't return a value
          });
        }
      }
    },
    {
      title: "Payment",
      dataIndex: "payment",
      fixed: "right",
      render: (payment, record) => {
        const isComplete = runsList[0]?.complete_status === "Complete";
        const isStandingOrder = record.payment_type === "Standing Order";
        const isMemberInList = runsList[0]?.member_id?.includes(record.member_id);

        if (isComplete || isStandingOrder || isMemberInList) {
          return (
            <Select
              defaultValue={{ value: "Paid" }}
              disabled
            />
          );
        } else {
          return (
            <Select
              defaultValue={{ value: payment || "Not Paid" }}
              onChange={(event) => handlePaymentChange(record, event)}
              options={[
                { value: 'Paid', label: 'Paid' },
                { value: 'Not Paid', label: 'Not Paid' }
              ]}
            />
          );
        }
      }
    },
    {
      title: "Action",
      render: (text, record) => (
        <Space>
          <Tooltip placement="bottom" title="Stat Sheet">
            <Button
              className="edit_button"
              shape="circle"
              icon={<FileTextOutlined />}
              onClick={() => exportParticipantDataPDF(record)}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Delete Member">
            <Button
              className={runsList[0]?.complete_status === "Complete" ? "delete_button_disabled" : "delete_button"}
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => handleRemoveHare(record)}
              disabled={runsList[0]?.complete_status === "Complete"}
            />
          </Tooltip>
        </Space>
      ),
      fixed: "right",
      align: 'left',
    },
  ];

  return (
    <>
      <div className="top_row_both_side">
        <div>
          <p><Badge status="success" />Anniversary Stats</p>
          <p><Badge color="#ffec3d" />Standing Order Member</p>
        </div>
        <Space>
          <Button
            className="primary__btn"
            onClick={() => showModalAddParticipant()}
            disabled={runsList[0]?.complete_status === "Complete"}
          >
            Add Participants
          </Button>
          <Tooltip placement="bottom" title="Participant List Download">
            <Button
              className="edit_button"
              shape="circle"
              icon={<FileTextOutlined />}
              onClick={() => exportParticipantListPDF()}
            />
          </Tooltip>
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination
        scroll={{ x: 1500 }}
      />
      <AddRunParticipantsModal
        visible={visibleAddParticipantModal}
        onDone={handleCancelAddParticipantModal}
        onCancel={handleCancelAddParticipantModal}
        onSubmit={handleSubmitAddParticipantModal}
        membersData={outRunMemberIdArr}
      />
    </>
  );
}

export default RunParticipantsDataTable;
