import { Badge, Button, message } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { PostDataAuth } from "../apiService/PostData";
import { apis } from "../properties";
import { useRefreshTable } from "../store";
import Progress from "react-progress-2";
import useBreadCrumb from "../hooks/useBreadCrumb";
import AddMembersModal from "../components/Member/AddMembersModal";
import MembersDataTable from "../components/Member/MembersDataTable";
import useMemberStatusList from "../hooks/useMemberStatusList";
import useRolesList from "../hooks/useRolesList";
import usePaymentsList from "../hooks/usePaymentsList";
import { PutDataAuth } from "../apiService/PutData";

function Members() {
  const location = useLocation();
  useBreadCrumb("Members", location.pathname, "Members");
  const [visibleAddMemberModal, setVisibleAddMemberModal] = useState(false);
  const [memberProfileCount, setMemberProfileCount] = useState(0);
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);

  const memberStatusList = useMemberStatusList("");
  const rolesList = useRolesList("");
  const standingOrderPaymentList = usePaymentsList("?payment_type=Standing Order");

  useEffect(() => {
    // Get the current year and month
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // Month is zero-based

    // Create a separate array for member_ids
    const expiredMembers = [];

    // Filter the standingOrderPaymentList array
    for (const payment of standingOrderPaymentList) {
      const paymentDate = payment.payment_date[payment.payment_date.length - 1];
      const [endingYear, endingMonth] = paymentDate.split('-').map(Number);

      if (currentYear > endingYear || (currentYear === endingYear && currentMonth > endingMonth)) {
        expiredMembers.push(payment.member_id);
      }
    }

    const memberProfileBody = {
      payment_type: "Cash"
    }

    expiredMembers.map((expired_member_id) => {
      PutDataAuth(`${apis.MEMBER_PROFILES_LIST}/${expired_member_id}`, memberProfileBody).then((result) => {
        let responseJson = result;
        if (responseJson === "success") {
          setRefreshTable(!refreshTable)
          Progress.hide();
        } else if (responseJson.status === "error") {
          message.error(responseJson?.error?.details[0]?.message);
          Progress.hide();
        }
      });

      return null;
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showModalAddMember = () => {
    setVisibleAddMemberModal(true);
  };

  const handleCancelAddMemberModal = () => {
    setVisibleAddMemberModal(false);
  };

  const handleGetMemberProfileCount = (memberProfileCount) => {
    setMemberProfileCount(memberProfileCount)
  }

  const handleSubmitAddMemberModal = (
    values,
    birthDate,
    joinedDate
  ) => {

    const body = {
      "first_name": values.first_name,
      "last_name": values.last_name,
      "hash_name": values.hash_name,
      "contact_number": values.contact_number,
      "email": values.email,
      "date_of_birth": birthDate,
      "joined_date": joinedDate,
      "address": {
        "street_number_name": values.street_number_name || undefined,
        "apartment_and_number": values.apartment_and_number || undefined,
        "city": values.city || undefined,
        "country": values.country || 'LK',
        "state": values.state || '11',
        "zip_code": values.zip_code || undefined
      },
      "status": values.status,
      "role_number": values.role_number,
      "former_runs": values.former_runs || 0,
      "former_trips": values.former_trips || 0,
      "former_hares": values.former_hares || 0
    }

    PostDataAuth(apis.MEMBER_PROFILES_LIST, body).then((result) => {
      let responseJson = result;

      if (responseJson === "success") {
        message.success("Member added successfully")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
        message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });

    setVisibleAddMemberModal(false)
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row_both_side">
          <div>
            <p><Badge status="success" />Anniversary Stats</p>
            <p><Badge color="#ffec3d" />Standing Order Member</p>
          </div>
          <Button
            className="primary__btn"
            onClick={() => showModalAddMember()}
          >
            Add Member
          </Button>
        </div>
        <MembersDataTable getMemberProfileCount={handleGetMemberProfileCount} />
        <AddMembersModal
          visible={visibleAddMemberModal}
          onDone={handleCancelAddMemberModal}
          onCancel={handleCancelAddMemberModal}
          onSubmit={handleSubmitAddMemberModal}
          memberProfileCountVal={memberProfileCount}
          memberStatusListVal={memberStatusList}
          rolesListVal={rolesList}
        />
      </div>
    </div>
  );
}

export default Members
