import {
    Modal,
    Form,
    Select,
    Button
} from 'antd';

const AddRunParticipantsModal = (props) => {
    const [formAddParticipant] = Form.useForm();
    const {
        visible,
        onCancel,
        onSubmit,
        membersData,
    } = props;

    const handleSubmit = () => {
        if (!formAddParticipant) return;
        formAddParticipant.submit();
    };

    const handleFinish = (values) => {
        if (onSubmit) {
            onSubmit(values);
            formAddParticipant.resetFields();
        }
    };

    const modalFooter = { onCancel };

    const getModalContent = () => {
        return (
            <Form
                form={formAddParticipant}
                name="formAddParticipant"
                onFinish={handleFinish}
            >
                <Form.Item
                    name="member_id"
                    rules={[{ required: true, message: 'Please select a hare' }]}
                    labelCol={{ span: 24 }}
                >
                    <Select
                        showSearch
                        mode="multiple"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                            (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())
                        }
                        filterSort={(optionA, optionB) =>
                            (optionA?.label?.toLowerCase() ?? '').localeCompare((optionB?.label?.toLowerCase() ?? ''))
                        }
                        options={membersData}
                    />

                </Form.Item>
            </Form>
        );
    };

    return (
        <Modal
            title='Add Participant'
            destroyOnClose
            width={500}
            open={visible}
            {...modalFooter}
            footer={[
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Add
                </Button>
            ]}
        >
            {getModalContent()}
        </Modal>
    );
};

export default AddRunParticipantsModal;
