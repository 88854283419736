import {
    Modal,
    Input,
    Row,
    Col,
    Form,
    DatePicker,
    TimePicker,
    // Upload,
    //message,
    Button,
    // Space,
    // Typography,
    // Spin
} from 'antd';
import { useEffect, useState } from 'react';
//import { apis } from '../../properties';
//import { IKContext, IKUpload } from 'imagekitio-react';
import dayjs from 'dayjs';
import weekday from "dayjs/plugin/weekday"
import localeData from "dayjs/plugin/localeData"
import moment from 'moment';

dayjs.extend(weekday)
dayjs.extend(localeData)

const dateFormat = 'YYYY/MM/DD';
const timeFormat = 'HH:mm'

//const { TextArea } = Input;
//const { Text } = Typography;

// const getBase64 = (file) =>
//     new Promise((resolve, reject) => {
//         const reader = new FileReader();
//         reader.readAsDataURL(file);
//         reader.onload = () => resolve(reader.result);
//         reader.onerror = (error) => reject(error);
//     });

const EditRunModal = (props) => {
    const [formEditRun] = Form.useForm();
    const { visible, onCancel, onSubmit, selectedValueData } = props;
    const [runDate, setRunDate] = useState();
    const [startingTime, setStartingTime] = useState();
    //const [uploadedImageKitUrl, setUploadedImageKitUrl] = useState();
    // const [fileList, setFileList] = useState([]);
    // const [previewOpen, setPreviewOpen] = useState(false);
    // const [previewImage, setPreviewImage] = useState('');
    // const [previewTitle, setPreviewTitle] = useState('');
    const [loading, setLoading] = useState(false);

    // const publicKey = process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY
    // const urlEndpoint = process.env.REACT_APP_IMAGE_PATH
    // const authenticationEndpoint = apis.IMAGEKIT_AUTH;

    useEffect(() => {
        formEditRun.setFieldsValue({ "run_number": selectedValueData.run_number });
        formEditRun.setFieldsValue({ "run_name": selectedValueData.run_name });
        formEditRun.setFieldsValue({ "run_date": dayjs(selectedValueData.run_date, dateFormat) });
        formEditRun.setFieldsValue({ "run_site": selectedValueData.run_site });
        formEditRun.setFieldsValue({ "run_site_name": selectedValueData.run_site_name });
        formEditRun.setFieldsValue({ "address": selectedValueData.address });
        formEditRun.setFieldsValue({ "note": selectedValueData.note });

        const startingTime = moment(selectedValueData.starting_time, timeFormat);

        formEditRun.setFieldsValue({ "starting_time": startingTime })

        setLoading(false);

        // setFileList([
        //     {
        //         uid: '-1',
        //         name: 'image.png',
        //         status: 'done',
        //         url: selectedValueData.cover_image_url,
        //     }])

    }, [formEditRun, selectedValueData])

    const handleSubmit = () => {
        if (!formEditRun) return;
        formEditRun.submit();
    };

    const handleFinish = (values) => {
        if (onSubmit) {
            onSubmit(
                values,
                runDate,
                startingTime,
                //uploadedImageKitUrl
            );
            formEditRun.resetFields();
        }
    };

    const onRunDatePickerChange = (date, dateString) => {
        setRunDate(dateString)
    };

    const onStartingTimePickerChange = (time, timeString) => {
        setStartingTime(timeString)
    }

    // const onImageUploadError = err => {
    //     message.error(err)
    //     setLoading(false)
    // };

    // const onImageUploadSuccess = res => {
    //     setUploadedImageKitUrl(res.url)
    //     setLoading(false)
    // };

    // const handleImageUploadChange = (e) => {
    //     if (e.type === "change") {
    //         setLoading(true)
    //     }
    // }

    // const handlePreview = async (file) => {
    //     if (!file.url && !file.preview) {
    //         file.preview = await getBase64(file.originFileObj);
    //     }
    //     setPreviewImage(file.url || file.preview);
    //     setPreviewOpen(true);
    //     setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    // };
    // const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
    // const handleCancel = () => setPreviewOpen(false);

    const modalFooter = { onCancel };

    const getCurrentMonth = () => {
        const now = new Date();
        return new Date(now.getFullYear(), now.getMonth(), now.getDate());
    };

    const disablePastDates = (current) => {
        // Disable past years, months, and dates
        return current && current < getCurrentMonth();
    };

    const getModalContent = () => {
        return (
            <Form
                form={formEditRun}
                name="formEditRun"
                onFinish={handleFinish}
                style={{ paddingLeft: 20, paddingRight: 20 }}
            >
                <Row gutter={16} >
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Run Number"
                            name="run_number"
                            labelCol={{ span: 24 }}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Run Site Name"
                            name="run_site_name"
                            rules={[{ required: true, message: 'Please enter a run site' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="Add run site" style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Run Date"
                            name="run_date"
                            rules={[{ required: true, message: 'Please select a run date' }]}
                            labelCol={{ span: 24 }}
                        >
                            <DatePicker
                                placeholder="Select date"
                                picker="date"
                                style={{ width: "100%" }}
                                onChange={onRunDatePickerChange}
                                disabledDate={disablePastDates}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Starting Time"
                            name="starting_time"
                            rules={[{ required: true, message: 'Please select a starting time' }]}
                            labelCol={{ span: 24 }}
                        >
                            <TimePicker
                                placeholder="Select time"
                                style={{ width: "100%" }}
                                onChange={onStartingTimePickerChange}
                                format={timeFormat}
                            />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 16 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                    <Form.Item
                            label="Google Map iFrame URL"
                            name="run_site"
                            rules={[{ required: true, message: 'Please enter a run site' },
                            {
                                pattern: /^<iframe.+src="(.+)".*><\/iframe>$/,
                                message: "Please enter a valid iframe link"
                            }
                            ]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="Add google map url" style={{ width: "100%" }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
                        <Form.Item
                            label="Address"
                            name="address"
                            rules={[{ required: true, message: 'Please enter a address' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="Add address" rows={4} />
                        </Form.Item>
                    </Col>
                </Row>
                {/* <Row>
                    <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
                        <Form.Item
                            label="Notes"
                            name="note"
                            rules={[{ required: false, message: 'Please enter a path' }]}
                            labelCol={{ span: 24 }}
                        >
                            <TextArea placeholder="Add notes" rows={4} />
                        </Form.Item>
                    </Col>
                </Row> */}
                {/* <Row style={{ paddingBottom: 16 }}>
                    <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
                        <IKContext
                            publicKey={publicKey}
                            urlEndpoint={urlEndpoint}
                            authenticationEndpoint={authenticationEndpoint}
                        >
                            <Space direction="vertical">
                                <Text>* Cover Image</Text>
                                <Spin spinning={loading}>
                                    <IKUpload
                                        fileName=""
                                        onError={onImageUploadError}
                                        onSuccess={onImageUploadSuccess}
                                        onChange={((e) => handleImageUploadChange(e))}
                                    />
                                </Spin>
                            </Space>
                        </IKContext>
                    </Col>
                </Row>
                <Upload
                    action=""
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                >
                    {fileList.length >= 1 ? null : ""}
                </Upload>
                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal> */}
            </Form>
        );
    };

    return (
        <Modal
            title='Edit Run'
            destroyOnClose
            width={1000}
            open={visible}
            {...modalFooter}
            footer={[
                <Button
                    loading={loading}
                    key="submit"
                    type="primary"
                    onClick={handleSubmit}
                >
                    Save Details
                </Button>
            ]}
        >
            {getModalContent()}
        </Modal>
    );
};

export default EditRunModal;
