import { Button, Table, Tooltip, Input, Space } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom"
import { FileOutlined } from "@ant-design/icons";
import { SearchOutlined } from '@ant-design/icons';
import useMemberProfilesList from "../../hooks/useMemberProfilesList";
import usePaymentsList from "../../hooks/usePaymentsList";
import Highlighter from 'react-highlight-words';
import jsPDF from "jspdf";
import "jspdf-autotable";

function MembersPaymentDataTable() {
  const [data, setData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const params = useParams();

  const memberProfilesList = useMemberProfilesList(`?_id=${params.member_id}`);
  const paymentList = usePaymentsList("");

  useEffect(() => {
    const tempPaymentListData = []
    memberProfilesList?.map((member_profiles_list_data) => {
      paymentList?.map((payment_list_data) => {
        if (payment_list_data.member_id === member_profiles_list_data.member_id) {
          tempPaymentListData.push({
            "key": payment_list_data._id,
            "_id": payment_list_data._id,
            "payment_number": payment_list_data.payment_number,
            "payment_date": payment_list_data.payment_date,
            "amount": payment_list_data.amount?.toFixed(2),
            "payment_description": payment_list_data.payment_description,
            "invoice": payment_list_data.invoice
          })
        }

        return null
      })

      return null
    })

    setData(tempPaymentListData)

  }, [memberProfilesList, paymentList])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const exportPaymentListDataPDF = (record) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);

    const title = "Colombo Hash";
    const subtitle = `Member ${memberProfilesList[0].member_id} Payment List Data`;
    const headers = [[
      "Payment Number",
      "Payment Date",
      "Amount(LKR)",
      "Payment Description"
    ]];

    const tableData = [record].map(elt => [
      elt.payment_number,
      elt.payment_date,
      elt.amount,
      elt.payment_description
    ]);

    let content = {
      startY: 65,
      head: headers,
      body: tableData,
    };

    // Add title and new subtitle
    doc.text(title, marginLeft, 30);
    doc.setFontSize(12); // Reduce font size for the subtitle
    doc.text(subtitle, marginLeft, 50); // Add the new subtitle below the title

    // Get the current date and time
    const now = new Date();
    const generatedDateTime = `${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;

    // Add generated date and time in the bottom right corner
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.setFontSize(10); // Adjust font size for the generated date and time
    doc.text(generatedDateTime, pageWidth - marginLeft, pageHeight - 30, {
      align: "right",
    });

    doc.autoTable(content);
    doc.save(`payment-number-${record.payment_number}-member-payments.pdf`)
  }

  const columns = [
    {
      title: "Payment Number",
      dataIndex: "payment_number",
      sorter: (a, b) => a.payment_number - b.payment_number,
      sortOrder: 'descend',
      ...getColumnSearchProps('payment_number'),
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      ...getColumnSearchProps('payment_date'),
    },
    {
      title: "Amount (LKR)",
      dataIndex: "amount",
      ...getColumnSearchProps('amount'),
    },
    {
      title: "Payment Description",
      dataIndex: "payment_description",
      ...getColumnSearchProps('payment_description'),
    },
    {
      title: "Action",
      render: (text, record) => (
        <Tooltip placement="bottom" title="Invoice Download">
          <Button
            className="edit_button"
            shape="circle"
            icon={<FileOutlined />}
            onClick={() => exportPaymentListDataPDF(record)}
          />
        </Tooltip>
      ),
      fixed: "right",
      align: 'left',
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={data}
      bordered
      pagination
      scroll={{ x: 1500 }}
    />
  );
}

export default MembersPaymentDataTable;
