import {
  EditOutlined,
} from "@ant-design/icons";
import { Button, Space, Table, Tooltip, message, Input } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { PutDataAuth } from "../../apiService/PutData";
import { apis } from "../../properties";
import { useRefreshTable } from "../../store";
import { SearchOutlined } from '@ant-design/icons';
import Progress from "react-progress-2";
import useMemberStatusList from "../../hooks/useMemberStatusList";
import Highlighter from 'react-highlight-words';
import EditMemberStatusModal from "./EditMemberStatusModal";

function MemberStatusDataTable() {
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [data, setData] = useState([]);
  const [visibleEditMemberStatusModal, setVisibleEditMemberStatusModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [memberStatusId, setMemberStatusId] = useState("");
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const memberStatusList = useMemberStatusList("");

  useEffect(() => {
    const tempMemberStatusListData = []

    memberStatusList.map((member_status_list_data, indexNo) => {
      tempMemberStatusListData.push({
        "key": indexNo,
        "_id": member_status_list_data._id,
        "member_status": member_status_list_data.member_status,
        "amount": member_status_list_data.amount?.toFixed(2)
      })

      return null
    })

    setData(tempMemberStatusListData)
  }, [memberStatusList])

  const showModalEditMemberStatus = (value) => {
    const filteredMemberStatusList = memberStatusList?.filter((member_status_list_data) => {
      return member_status_list_data._id === value._id
    })

    const selectedValueObj = {
      "member_status": filteredMemberStatusList[0]?.member_status,
      "amount": filteredMemberStatusList[0]?.amount?.toFixed(2)
    }

    setSelectedValue(selectedValueObj);
    setVisibleEditMemberStatusModal(true);
    setMemberStatusId(value._id)
  };

  const handleCancelEditMemberStatusModal = () => {
    setVisibleEditMemberStatusModal(false);
  };

  const handleSubmitEditMemberStatusModal = (
    values
  ) => {

    const body = {
      "member_status": values.member_status,
      "amount": values.amount
    }

    PutDataAuth(`${apis.MEMBER_STATUS_LIST}/${memberStatusId}`, body).then((result) => {
      let responseJson = result;

      if (responseJson === "success") {
        message.success("Member status updated successfully")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
          message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });

    setVisibleEditMemberStatusModal(false)
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Member Status",
      dataIndex: "member_status",
      ...getColumnSearchProps('member_status'),
    },
    {
      title: "Amount (LKR)",
      dataIndex: "amount",
    },
    {
      title: "Action",
      render: (text, record) => (
        <Space>
          <Tooltip placement="bottom" title="Edit MemberStatus">
            <Button
              className="edit_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => showModalEditMemberStatus(record)}
            />
          </Tooltip>
        </Space>
      ),
      fixed: "right",
      align: 'left',
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination
        scroll={{ x: 1500 }}
      />
      <EditMemberStatusModal
        visible={visibleEditMemberStatusModal}
        onDone={handleCancelEditMemberStatusModal}
        onCancel={handleCancelEditMemberStatusModal}
        onSubmit={handleSubmitEditMemberStatusModal}
        selectedValueData={selectedValue}
      />
    </>
  );
}

export default MemberStatusDataTable;
