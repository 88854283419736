import {
  DownloadOutlined,
  FileTextOutlined,
} from "@ant-design/icons";
import {
  Button,
  Space,
  Table,
  Tooltip,
  Input,
  Tag,
  DatePicker,
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import { SearchOutlined } from '@ant-design/icons';
import usePaymentsList from "../../hooks/usePaymentsList";
import useMemberProfilesList from "../../hooks/useMemberProfilesList";
import Highlighter from 'react-highlight-words';
import jsPDF from "jspdf";
import "jspdf-autotable";

const { RangePicker } = DatePicker;

function PaymentsDataTable() {
  const [data, setData] = useState([]);
  const [sdata, setSData] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const paymentList = usePaymentsList("");
  const memberProfileList = useMemberProfilesList("");

  useEffect(() => {
    const tempPaymentListData = []

    paymentList?.map((payment_list_data, indexNo) => {
      memberProfileList?.map((member_profile_list_data) => {
        if (payment_list_data.member_id === member_profile_list_data.member_id) {
          tempPaymentListData.push({
            "key": indexNo,
            "_id": payment_list_data._id,
            "payment_number": payment_list_data.payment_number,
            "payment_date": `${payment_list_data.payment_date} `,
            "member_id": payment_list_data.member_id,
            "member_name": `${member_profile_list_data.first_name} ${member_profile_list_data.last_name}`,
            "hash_name": member_profile_list_data.hash_name,
            "amount": payment_list_data.amount?.toFixed(2),
            "type": payment_list_data.payment_type,
            "payment_description": payment_list_data.payment_description,
            "invoice": payment_list_data.invoice
          })
        }

        return null
      })

      return null
    })

    setData(tempPaymentListData);
    setSData(tempPaymentListData);
  }, [memberProfileList, paymentList])

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const exportPaymentListDataPDF = (record) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);

    const title = "Colombo Hash";
    const subtitle = "Payment List Data";
    const headers = [[
      "Payment Number",
      "Payment Date",
      "Member  ID",
      "Member Name",
      "Hash Name",
      "Amount",
      "Type",
      "Payment Description"
    ]];

    const tableData = [record].map(elt => [
      elt.payment_number,
      elt.payment_date,
      elt.member_id,
      elt.member_name,
      elt.hash_name,
      elt.amount,
      elt.type,
      elt.payment_description
    ]);

    let content = {
      startY: 65,
      head: headers,
      body: tableData,
    };

    // Add title and new subtitle
    doc.text(title, marginLeft, 30);
    doc.setFontSize(12); // Reduce font size for the subtitle
    doc.text(subtitle, marginLeft, 50); // Add the new subtitle below the title

    // Get the current date and time
    const now = new Date();
    const generatedDateTime = `${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;

    // Add generated date and time in the bottom right corner
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.setFontSize(10); // Adjust font size for the generated date and time
    doc.text(generatedDateTime, pageWidth - marginLeft, pageHeight - 30, {
      align: "right",
    });

    doc.autoTable(content);
    doc.save(`payment-number${record.payment_number}-payments.pdf`)
  }

  const handleDownloadInvoice = (record) => {
    // Get the invoice URL from the record object
    const invoiceUrl = record.invoice;
    window.open(invoiceUrl, '_blank');
  };

  const handleRangePickerChange = (value, dateString) => {
    if (dateString[0] === "" && dateString[1] === "") {
      setData(sdata);
    } else {
      // Convert the dateString array to actual Date objects for comparison
      const startDate = new Date(dateString[0]);
      const endDate = new Date(dateString[dateString.length - 1]);

      // Filter the data array based on payment_date within the range
      const filteredData = data.filter(item => {
        // Split payment_date to handle multiple dates if present
        const paymentDates = item.payment_date.split(',').map(date => date.trim());
        for (const date of paymentDates) {
          const paymentDate = new Date(date);
          if (paymentDate >= startDate && paymentDate <= endDate) {
            return true; // Include the item if payment_date is within the range
          }
        }
        return false; // Exclude the item if payment_date is not within the range
      });

      setData(filteredData);
    }
  }

  const columns = [
    {
      title: "Payment Number",
      dataIndex: "payment_number",
      sorter: (a, b) => a.payment_number - b.payment_number,
      sortOrder: 'descend',
      ...getColumnSearchProps('payment_number'),
    },
    {
      title: "Payment Date",
      dataIndex: "payment_date",
      ...getColumnSearchProps('payment_date'),
    },
    {
      title: "Member ID",
      dataIndex: "member_id",
      ...getColumnSearchProps('member_id'),
    },
    {
      title: "Member Name",
      dataIndex: "member_name",
      ...getColumnSearchProps('member_name'),
    },
    {
      title: "Hash Name",
      dataIndex: "hash_name",
      ...getColumnSearchProps('hash_name'),
    },
    {
      title: "Amount (LKR)",
      dataIndex: "amount",
    },
    {
      title: "Type",
      dataIndex: "type",
      ...getColumnSearchProps('type'),
      render: (type, record) => (
        <>
          {
            type === "Cash" ? <Tag style={{ color: 'black' }} color="#87d068">Cash</Tag> : <>
              {
                type === "Standing Order" ? <Tag style={{ color: 'black' }} color="#ffec3d">Standing Order</Tag> : <></>
              }
            </>
          }
        </>
      ),
    },
    {
      title: "Payment Description",
      dataIndex: "payment_description",
      ...getColumnSearchProps('payment_description'),
    },
    {
      title: "Action",
      render: (text, record) => (
        <Space>
          <Tooltip placement="bottom" title="Download Invoice">
            <Button
              className="edit_button"
              shape="circle"
              icon={<FileTextOutlined />}
              onClick={() => exportPaymentListDataPDF(record)}
            />
          </Tooltip>
          {record.invoice ? <Tooltip placement="bottom" title="Download Standing Order Invoice">
            <Button
              className="edit_button"
              shape="circle"
              icon={<DownloadOutlined />}
              onClick={() => handleDownloadInvoice(record)}
            />
          </Tooltip> : <></>}
        </Space>
      ),
      fixed: "right",
      align: 'left',
    },
  ];

  return (
    <>
      <div className="top_second_row">
        <RangePicker onChange={handleRangePickerChange} />
      </div>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination
        scroll={{ x: 1500 }}
      />
    </>
  );
}

export default PaymentsDataTable;
