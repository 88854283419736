import {
  Tabs,
  Button,
  Space,
  message,
  Typography
} from "antd";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useRefreshTable } from "../store";
import { PostDataAuth } from "../apiService/PostData";
import { apis } from "../properties";
import { GetDataAuth } from "../apiService/GetData";
import BeerAddedDataTable from "../components/BeerStock/BeerAddedDataTable";
import AddBeerStockModal from "../components/BeerStock/AddBeerStockModal";
import useMemberProfilesList from "../hooks/useMemberProfilesList";
import useBreadCrumb from "../hooks/useBreadCrumb";
import BeerDispatchDataTable from "../components/BeerStock/BeerDispatchDataTable";
import DispatchBeerStockModal from "../components/BeerStock/DispatchBeerStockModal";
import useRunsList from "../hooks/useRunsList";
import Progress from "react-progress-2";
import LiquidFillGaugeComp from "../util/LiquidGauge";
import BeerAvailableDataTable from "../components/BeerStock/BeerAvailableDataTable";
import useBeerAvailableStock from "../hooks/useBeerAvailableStock";
import useBeerAddedStock from "../hooks/useBeerAddedStock";
import useRolesList from "../hooks/useRolesList";

const { Title } = Typography;

function BeerStocks() {
  const location = useLocation();
  useBreadCrumb("Beer Stocks", location.pathname, "Beer Stock");
  const [visibleAddStockModal, setVisibleAddStockModal] = useState(false);
  const [visibleDispatchStockModal, setVisibleDispatchStockModal] = useState(false);
  const [purchasePersonData, setPurchasePersonData] = useState([]);
  const [runData, setRunData] = useState([]);
  const [currentStockData, setCurrentStockData] = useState([
    {
      "volume": 300,
      "quantity": 0
    },
    {
      "volume": 500,
      "quantity": 0
    },
    {
      "volume": 700,
      "quantity": 0
    }
  ]);
  const [beerAddedStockCount, setBeerAddedStockCount] = useState(0);
  const [beerAvailableStockData, setBeerAvailableStockData] = useState([]);
  const [beerLoading, setBeerLoading] = useState(false);
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);

  const memberProfileList = useMemberProfilesList("");
  const runList = useRunsList("");
  const beerAvailableStock = useBeerAvailableStock("");
  const beerAddedStock = useBeerAddedStock("");
  const roleStatusList = useRolesList("");

  useEffect(() => {
    const tempMemberList = []

    memberProfileList?.map((member_list_data) => {
      roleStatusList?.map((role_status_data) => {
        if (member_list_data?.role_number === role_status_data.role_number) {
          if (role_status_data.status === "Super Admin") {
            tempMemberList.push({
              "value": member_list_data.member_id,
              "label": `${member_list_data.first_name} ${member_list_data.last_name}`
            })
          }
        }

        return null
      })

      return null
    })

    const tempRunListData = []
    runList.map((run_list_data) => {
      if (run_list_data.run_status === "Ongoing") {
        if (run_list_data.complete_status !== "Complete") {
          tempRunListData.push({
            "value": run_list_data.run_number,
            "label": `${run_list_data.run_number} - ${run_list_data.run_name}`
          })
        }
      }

      return null
    })

    setPurchasePersonData(tempMemberList)
    setRunData(tempRunListData)

    const filteredStock = beerAvailableStock.filter(item => {
      return item.available_quantity.some(quantityItem => quantityItem.quantity !== 0);
    });

    const tempBeerAvailableStockData = []
    filteredStock.map((beer_available_stock) => {
      beerAddedStock.map((beer_added_stock) => {
        if(beer_available_stock.available_stock_number === beer_added_stock.stock_number) {
          tempBeerAvailableStockData.push({
            "value": beer_available_stock.available_stock_number,
            "label": `${beer_available_stock.available_stock_number} - ${beer_available_stock.purchase_date}`,
            "available_quantity": beer_added_stock.added_quantity
          })    
        }

        return null
      })

      return null
    })

    setBeerAvailableStockData(tempBeerAvailableStockData)

    GetDataAuth(`${apis.BEER_CURRENT_STOCK}`).then((result) => {
      let responseJson = [];
      responseJson = result;
      if (responseJson.length > 0) {
        setCurrentStockData(responseJson[0].current_quantity)
        Progress.hide();
      } else if (responseJson.status === "error") {
        message.error(responseJson.message);
        Progress.hide();
      } else {
        Progress.hide();
      }
    });
  }, [beerAvailableStock, beerAddedStock, memberProfileList, runList, roleStatusList])

  const showModalAddStock = () => {
    setVisibleAddStockModal(true);
  };

  const showModalDispatchStock = () => {
    setVisibleDispatchStockModal(true);
  };

  const handleCancelAddStockModal = () => {
    setVisibleAddStockModal(false);
  };

  const handleCancelDispatchStockModal = () => {
    setVisibleDispatchStockModal(false);
  };

  const handleSubmitAddStockModal = (
    values,
    purchaseDate,
    uploadedImageKitUrl
  ) => {
    setBeerLoading(true);
    const body = {
      "member_id": values.purchased_person,
      "purchase_date": purchaseDate,
      "invoice": uploadedImageKitUrl,
      "added_quantity": [
        {
          "volume": 300,
          "quantity": Number(values.ml300) || 0,
          "price": Number(values.lkr300) || 0
        },
        {
          "volume": 500,
          "quantity": Number(values.ml500) || 0,
          "price": Number(values.lkr500) || 0
        },
        {
          "volume": 700,
          "quantity": Number(values.ml700) || 0,
          "price": Number(values.lkr700) || 0
        }
      ]
    }

    PostDataAuth(apis.BEER_ADDED_STOCK, body).then((result) => {
      let responseJson = result;
      if (responseJson.stock_number) {

        const availableStockBody = {
          "available_stock_number": responseJson.stock_number,
          "member_id": values.purchased_person,
          "purchase_date": purchaseDate,
          "available_quantity": [
            {
              "volume": 300,
              "quantity": Number(values.ml300) || 0,
              "price": Number(values.lkr300) || 0
            },
            {
              "volume": 500,
              "quantity": Number(values.ml500) || 0,
              "price": Number(values.lkr500) || 0
            },
            {
              "volume": 700,
              "quantity": Number(values.ml700) || 0,
              "price": Number(values.lkr700) || 0
            }
          ]
        }

        PostDataAuth(apis.BEER_AVAILABLE_STOCK, availableStockBody).then((result) => {
          let responseJson = result;
          if (responseJson === "success") {
            setBeerLoading(false);
            message.success("Beer stock added successfully")
            setRefreshTable(!refreshTable)
          } else if (responseJson.status === "error") {
            setBeerLoading(false);
            message.error(responseJson?.error?.details[0]?.message);
          }
        })
      } else if (responseJson.status === "error") {
        message.error(responseJson?.error?.details[0]?.message);
      }
    });

    setVisibleAddStockModal(false)
  };

  const handleGetBeerAddedStockCount = (beerAddedStockCount) => {
    setBeerAddedStockCount(beerAddedStockCount)
  }

  const handleSubmitDispatchStockModal = (
    values,
    dispatchDate
  ) => {
    setBeerLoading(true);
    const body = {
      "run_number": values.run_number,
      "dispatch_date": dispatchDate,
      "stock_number": values.stock_number,
      "dispatch_quantity": [
        {
          "volume": 300,
          "quantity": Number(values.ml300) || 0
        },
        {
          "volume": 500,
          "quantity": Number(values.ml500) || 0
        },
        {
          "volume": 700,
          "quantity": Number(values.ml700) || 0
        }
      ]
    }

    PostDataAuth(apis.BEER_DISPATCH_STOCK, body).then((result) => {
      let responseJson = result;
      if (responseJson === "success") {
        message.success("Beer dispatched successfully")
        setVisibleDispatchStockModal(false);
        setRefreshTable(!refreshTable)
        setBeerLoading(false);
      } else if (responseJson.status === "error") {
        message.error(responseJson.message);
        setBeerLoading(false);
      }
    });
  };

  const items = [
    {
      key: '1',
      label: `Added Stock`,
      children: <BeerAddedDataTable
        getBeerAddedStockCount={handleGetBeerAddedStockCount}
      />,
    },
    {
      key: '2',
      label: `Available Stock`,
      children: <BeerAvailableDataTable />,
    },
    {
      key: '3',
      label: `Dispatch Stock`,
      children: <BeerDispatchDataTable />,
    },
  ];

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Space>
            <Button
              className="primary__btn"
              onClick={() => showModalAddStock()}
            >
              Add Stock
            </Button>
            <Button
              onClick={() => showModalDispatchStock()}
            >
              Dispatch
            </Button>
          </Space>
        </div>
        <div className="stock_available_container">
          <Title level={4} strong>Total Current Stock</Title>
          <Space>
            {currentStockData.map((current_stock_data, indexNo) => (
              <div key={indexNo}>
                <LiquidFillGaugeComp
                  quantity={current_stock_data.quantity}
                  totalQuantity={currentStockData.reduce((total, data) => total + data.quantity, 0)}
                  volume={current_stock_data.volume}
                />
              </div>
            ))}
          </Space>
        </div>
        <Tabs defaultActiveKey="1" items={items} />
      </div>
      <AddBeerStockModal
        visible={visibleAddStockModal}
        onDone={handleCancelAddStockModal}
        onCancel={handleCancelAddStockModal}
        onSubmit={handleSubmitAddStockModal}
        purchasePersonDataVal={purchasePersonData}
        beerAddedStockCountVal={beerAddedStockCount}
        beerLoadingVal={beerLoading}
      />
      <DispatchBeerStockModal
        visible={visibleDispatchStockModal}
        onDone={handleCancelDispatchStockModal}
        onCancel={handleCancelDispatchStockModal}
        onSubmit={handleSubmitDispatchStockModal}
        runDataVal={runData}
        beerAvailableStockDataVal={beerAvailableStockData}
        beerLoadingVal={beerLoading}
      />
    </div>
  );
}

export default BeerStocks
