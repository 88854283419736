import React, { useState } from 'react';
import { useStepsForm } from 'sunflower-antd';
import {
  //Steps, 
  Input,
  Button,
  Form,
  Collapse,
  DatePicker,
  message,
  Checkbox,
  Card,
  Typography,
  Space,
  Spin,
  Result
} from 'antd';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IKContext, IKUpload } from 'imagekitio-react';
import { apis } from '../properties';
import { BackwardOutlined, DeleteFilled, PlusOutlined } from '@ant-design/icons';
import { useRefreshTable } from '../store';
import { PostDataAuth } from '../apiService/PostData';
import useBreadCrumb from "../hooks/useBreadCrumb";
import Progress from "react-progress-2";
import AddRunModal from '../components/Run/AddRunModal';
import RunsDataTable from '../components/Run/RunsDataTable';
import useRunsList from '../hooks/useRunsList';
import useMemberStatusList from '../hooks/useMemberStatusList';

const { TextArea } = Input;
const { Panel } = Collapse;
const { Text, Title } = Typography;

const AddTripDetails = () => {
  const navigate = useNavigate();
  const [tripStartingDate, setTripStartingDate] = useState();
  const [tripEndingDate, setTripEndingDate] = useState();
  const [regClosingDate, setRegClosingDate] = useState();
  const [uploadedImageKitUrl, setUploadedImageKitUrl] = useState({});
  const [dayNumber, setDayNumber] = useState(1);
  const [visibleAddRunModal, setVisibleAddRunModal] = useState(false);
  const [runNumber, setRunNumber] = useState([]);
  const [dayNumberAddDate, setDayNumberAddDate] = useState(0);
  const [uniqeRunNumber, setUniqeRunNumber] = useState([]);
  const [runCount, setRunCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [tripUpdate, setTripUpdate] = useState(false);
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const params = useParams();

  const publicKey = process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY
  const urlEndpoint = process.env.REACT_APP_IMAGE_PATH
  const authenticationEndpoint = apis.IMAGEKIT_AUTH;
  const memberStatusList = useMemberStatusList("");

  const {
    form,
    current,
    gotoStep,
    // stepsProps,
    formProps,
    submit,
    formLoading,
  } = useStepsForm({
    async submit(values) {

      let tempDay = {}
      for (const key in values) {
        const keyFirstData = key.substring(0, key.indexOf(' '));
        const keySecondData = key.substring(key.indexOf(' ') + 1);

        if (keyFirstData === 'description' || keyFirstData === 'checkboxValue') {
          if (keySecondData !== "0") {
            tempDay[key] = values[key]
          }
        }
      }

      for (const key in uploadedImageKitUrl) {
        const keySecondData = key.substring(key.indexOf(' ') + 1);

        if (keySecondData !== "0") {
          tempDay[key] = uploadedImageKitUrl[key]
        }
      }

      const outputArr = [];
      let currentObj = {};

      for (const [key, value] of Object.entries(tempDay)) {
        const keyParts = key.split(" ");
        const propName = keyParts[0];
        const objIndex = keyParts[1];

        if (objIndex !== currentObj.index) {
          // We've reached a new object, so push the current one onto the array
          if (Object.keys(currentObj).length > 0) {
            outputArr.push(currentObj);
          }

          // Reset the current object
          currentObj = {
            index: objIndex
          };
        }

        // Set the current object property based on the key
        if (propName === "imageUrl") {
          currentObj[propName] = value;
        } else {
          const propNameWithoutIndex = propName.replace(/\d+/g, "");
          currentObj[propNameWithoutIndex] = value;
        }
      }

      // Push the final object onto the array
      if (Object.keys(currentObj).length > 0) {
        outputArr.push(currentObj);
      }

      const result = [];
      for (let i = 0; i < outputArr.length; i++) {
        const obj = outputArr[i];
        const index = obj.index;

        if (!result[index]) {
          result[index] = {};
        }

        for (const prop in obj) {
          if (prop !== "index") {
            if (result[index][prop] && Array.isArray(result[index][prop])) {
              result[index][prop] = result[index][prop].concat(obj[prop]);
            } else {
              result[index][prop] = obj[prop];
            }
          }
        }
      }

      const daySubmitArr = []
      result.map((result_data, index_no) => {
        daySubmitArr.push({
          "day": index_no,
          "destination_images": result_data.imageUrl,
          "description": result_data.description,
          "food": result_data.checkboxValue
        })

        return null
      })

      const missingDays = new Set();

      // Check missing day numbers in daySubmitArr and add to runNumber
      for (const { day } of daySubmitArr) {
        if (!runNumber.some(({ day_number }) => day_number === day)) {
          missingDays.add(day);
          runNumber.push({
            day_number: day,
            run_number: -1
          });
        }
      }

      // Check missing day numbers in runNumber and add to daySubmitArr
      for (const { day_number } of runNumber) {
        if (!daySubmitArr.some(({ day }) => day === day_number)) {
          missingDays.add(day_number);
          daySubmitArr.push({
            day: day_number
          });
        }
      }

      daySubmitArr.push({ "run_number": runNumber })

      const tripExpenseSubmitArr = []
      tripExpenseSubmitArr.push({
        "vehicle_type": values.vehicle_type,
        "transport_rate_per_person": values.transport_rate_per_person,
        "meal_rate_per_person": Number(values.meal_rate_per_person) || 0,
        "hotel_name": [values.hotel_name],
        "double_deluxe_room_count": Number(values.double_deluxe_room_count) || 0,
        "double_deluxe_room_rate_per_person": Number(values.double_deluxe_room_rate_per_person) || 0,
        "double_standard_room_count": Number(values.double_standard_room_count) || 0,
        "double_standard_room_rate_per_person": Number(values.double_standard_room_rate_per_person) || 0,
        "single_deluxe_room_count": Number(values.single_deluxe_room_count) || 0,
        "single_deluxe_room_rate_per_person": Number(values.single_deluxe_room_rate_per_person) || 0,
        "single_standard_room_count": Number(values.single_standard_room_count) || 0,
        "single_standard_room_rate_per_person": Number(values.single_standard_room_rate_per_person) || 0,
        "triple_deluxe_room_count": Number(values.triple_deluxe_room_count) || 0,
        "triple_deluxe_room_rate_per_person": Number(values.triple_deluxe_room_rate_per_person) || 0,
        "triple_standard_room_count": Number(values.triple_standard_room_count) || 0,
        "triple_standard_room_rate_per_person": Number(values.triple_standard_room_rate_per_person) || 0
      })

      let totalParticipantsCount = 0;

      const getRoomCount = (roomCount, multiplier) => {
        return (Number(roomCount) || 0) * multiplier;
      }

      totalParticipantsCount += getRoomCount(values.single_deluxe_room_count, 1);
      totalParticipantsCount += getRoomCount(values.single_standard_room_count, 1);
      totalParticipantsCount += getRoomCount(values.double_deluxe_room_count, 2);
      totalParticipantsCount += getRoomCount(values.double_standard_room_count, 2);
      totalParticipantsCount += getRoomCount(values.triple_deluxe_room_count, 3);
      totalParticipantsCount += getRoomCount(values.triple_standard_room_count, 3);

      const participantCount = Number(values.participant_count);

      if (totalParticipantsCount > participantCount) {
        message.info("You can't add participants to rooms that exceed the participant count");
      } else {
        const body = {
          "trip_name": values.trip_name,
          "starting_date": tripStartingDate,
          "ending_date": tripEndingDate,
          "destination": values.destination,
          "participant_count": participantCount,
          "cover_image_url": Object.values(uploadedImageKitUrl)[0],
          "description": values.description,
          "registration_closing_date": regClosingDate,
          "trip_details": daySubmitArr,
          "trip_expenses": tripExpenseSubmitArr,
          "trip_status": "Ongoing"
        }

        PostDataAuth(apis.TRIPS_LIST, body).then((result) => {
          let responseJson = result;

          if (responseJson === "success") {
            message.success("Trip added successfully");
            setRefreshTable(!refreshTable);
            setTripUpdate(true);
            Progress.hide();
          } else if (responseJson.status === "error") {
            setTripUpdate(false);
            message.error(responseJson?.error?.details[0]?.message);
            Progress.hide();
          }
        });

        // Ensure the function is declared as async
        await new Promise(r => setTimeout(r, 10));
        return 'ok';
      }
    }
  });

  form.setFieldsValue({ "trip_number": `${params.trip_id}` });
  form.setFieldsValue({ "transport_rate_per_person": (0 / 100).toFixed(2) });
  form.setFieldsValue({ "meal_rate_per_person": (0 / 100).toFixed(2) });
  form.setFieldsValue({ "single_deluxe_room_rate_per_person": (0 / 100).toFixed(2) });
  form.setFieldsValue({ "single_standard_room_rate_per_person": (0 / 100).toFixed(2) });
  form.setFieldsValue({ "double_deluxe_room_rate_per_person": (0 / 100).toFixed(2) });
  form.setFieldsValue({ "double_standard_room_rate_per_person": (0 / 100).toFixed(2) });
  form.setFieldsValue({ "triple_deluxe_room_rate_per_person": (0 / 100).toFixed(2) });
  form.setFieldsValue({ "triple_standard_room_rate_per_person": (0 / 100).toFixed(2) });

  form.setFieldsValue({ "single_deluxe_room_count": 0 });
  form.setFieldsValue({ "single_standard_room_count": 0 });
  form.setFieldsValue({ "double_deluxe_room_count": 0 });
  form.setFieldsValue({ "double_standard_room_count": 0 });
  form.setFieldsValue({ "triple_deluxe_room_count": 0 });
  form.setFieldsValue({ "triple_standard_room_count": 0 });

  const location = useLocation();
  useBreadCrumb("trips/add/details", location.pathname, "Trips");

  const onStartingDatePickerChange = (date, dateString) => {
    setTripStartingDate(dateString)
  };

  const onEndingDatePickerChange = (time, dateString) => {
    setTripEndingDate(dateString)
  }

  const onImageUploadError = err => {
    message.error(err)
    setLoading(false)
  };

  const onImageUploadSuccess = (res, dayNumber) => {
    setUploadedImageKitUrl({ ...uploadedImageKitUrl, [`imageUrl ${dayNumber}`]: res.url })
    setLoading(false)
  };

  const handleImageUploadChange = (e) => {
    if (e.type === "change") {
      setLoading(true)
    }
  }

  const onRegClosingDatePickerChange = (date, dateString) => {
    setRegClosingDate(dateString)
  }

  const handleIncreaseDay = () => {
    setDayNumber(dayNumber + 1)
  }

  const handleDecreaseDay = () => {
    setDayNumber(dayNumber - 1)
  }

  const mealOptions = ['Breakfast', 'Lunch', 'Dinner'];

  const showModalAddRun = (dayNumber) => {
    setDayNumberAddDate(dayNumber)
    setVisibleAddRunModal(true);
  };

  const handleCancelAddRunModal = () => {
    setVisibleAddRunModal(false);
  };

  const handleSubmitAddRunModal = (
    values,
    runDate,
    startingTime,
    uploadedImageKitUrl
  ) => {
    const body = {
      "run_name": values.run_name,
      "run_date": runDate,
      "starting_time": startingTime,
      "run_site": values.run_site,
      "run_site_name": values.run_site_name,
      "note": values.note,
      "address": values.address,
      "cover_image_url": uploadedImageKitUrl,
      "run_status": "Ongoing",
      "trip_run_day": dayNumberAddDate
    }

    PostDataAuth(apis.RUNS_LIST, body).then((result) => {
      let responseJson = result;
      if (responseJson.run_number) {

        const tempRunNumber = runNumber
        tempRunNumber.push({ "day_number": dayNumberAddDate, "run_number": responseJson.run_number })
        setRunNumber(tempRunNumber)

        const newRunNum = tempRunNumber.filter((v, i, a) => a.findIndex(v2 => (v2.day_number === v.day_number)) === i)
        setUniqeRunNumber(newRunNum)

        message.success("Run added successfully")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
        message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });

    setVisibleAddRunModal(false)
  };

  const runsList = useRunsList("");

  const handleRunCount = () => {
    setRunCount((runsList.at(-1)?.run_number) + 1 || 0)
  }

  const getCurrentMonth = () => {
    const now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), now.getDate());
  };

  const disablePastDates = (current) => {
    // Disable past years, months, and dates
    return current && current < getCurrentMonth();
  };

  const formList = [
    <>
      <Space style={{ padding: 2 }}>
        <Button type="primary" icon={<BackwardOutlined />}
          onClick={() => navigate(
            `/trips`
          )} />
        <Title level={5} style={{ paddingTop: 5 }}>Add Trip</Title>
      </Space>
      <div className="trip-grid-container-first">
        <Form.Item
          label="Trip Number"
          name="trip_number"
          labelCol={{ span: 24 }}
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="Trip Name"
          name="trip_name"
          rules={[{ required: true, message: 'Please enter a trip name' }]}
          labelCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Starting Date"
          name="starting_date"
          rules={[{ required: true, message: 'Please select a starting date' }]}
          labelCol={{ span: 24 }}
        >
          <DatePicker
            style={{ width: "100%" }}
            onChange={onStartingDatePickerChange}
            disabledDate={disablePastDates}
          />
        </Form.Item>
        <Form.Item
          label="Ending Date"
          name="ending_date"
          rules={[{ required: true, message: 'Please select a ending date' }]}
          labelCol={{ span: 24 }}
        >
          <DatePicker
            style={{ width: "100%" }}
            onChange={onEndingDatePickerChange}
            disabledDate={disablePastDates}
          />
        </Form.Item>
        <Form.Item
          label="Destination"
          name="destination"
          rules={[{ required: true, message: 'Please enter destination' }]}
          labelCol={{ span: 24 }}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Participant Count"
          name="participant_count"
          rules={[{ required: true, message: 'Please enter participant count' }, {
            pattern: /^[0-9]+$/,
            message: "Please enter a number"
          }]}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input type="number" />
        </Form.Item>
      </div>
      <div className="trip-grid-container-second">
        <div id="trip-cover-img">
          <IKContext
            publicKey={publicKey}
            urlEndpoint={urlEndpoint}
            authenticationEndpoint={authenticationEndpoint}
          >
            <Text>
              <span style={{ color: 'red' }}>*</span> Cover Image
            </Text>
            <Spin spinning={loading}>
              <IKUpload
                fileName=""
                onError={onImageUploadError}
                onSuccess={(response) => onImageUploadSuccess(response, 0)}
                onChange={((e) => handleImageUploadChange(e))}
              />
            </Spin>
          </IKContext>
        </div>
        {/* <Form.Item
          label="Description"
          name="description"
          rules={[{ required: false, message: 'Please enter a description' }]}
          labelCol={{ span: 24 }}
        >
          <TextArea rows={6} />
        </Form.Item> */}
        <Form.Item
          label="Registration Closing Date"
          name="registration_closing_date"
          rules={[{ required: true, message: 'Please select a closing date' }]}
          labelCol={{ span: 24 }}
        >
          <DatePicker
            style={{ width: "30%" }}
            onChange={onRegClosingDatePickerChange}
            disabledDate={disablePastDates}
          />
        </Form.Item>
      </div>
      <div className='footer-button-first'>
        <Button loading={loading} type="primary" onClick={() => gotoStep(current + 1)}>Next</Button>
      </div>
    </>,

    <>
      <div>
        <Collapse
          ghost
          defaultActiveKey={0}
          style={{ width: '100%' }}
        >{
            [...Array(dayNumber).keys()].map((array_index) => (
              <Panel
                header={`Day ${array_index + 1}`}
                key={array_index}
                extra={
                  <Button onClick={() => handleDecreaseDay()}>
                    <DeleteFilled />
                  </Button>
                }>
                <div style={{ paddingBottom: "16px" }}>
                  <IKContext
                    publicKey={publicKey}
                    urlEndpoint={urlEndpoint}
                    authenticationEndpoint={authenticationEndpoint}
                  >
                    <Space direction="vertical">
                      <Text>Destination Images</Text>
                      <Spin spinning={loading}>
                        <IKUpload
                          fileName={`Day Images ${array_index + 1}`}
                          onError={onImageUploadError}
                          onSuccess={(response) => onImageUploadSuccess(response, (array_index + 1))}
                          onChange={((e) => handleImageUploadChange(e))}
                        />
                      </Spin>
                    </Space>
                  </IKContext>
                </div>
                <Form.Item
                  label="Description"
                  name={`description ${(array_index + 1)}`}
                  labelCol={{ span: 24 }}
                >
                  <TextArea placeholder="Description" rows={6} />
                </Form.Item>
                <Space direction="vertical" style={{ paddingBottom: "16px" }}>
                  <Text>Add Run</Text>
                  <Button type="ghost" onClick={() => showModalAddRun((array_index + 1))}><PlusOutlined />Add</Button>
                </Space>
                {runNumber.length === 0 ? <></> :
                  uniqeRunNumber.map((run_number_data) => (run_number_data.day_number === (array_index + 1) ?
                    <RunsDataTable runNumberVal={runNumber} dayNumberVal={(array_index + 1)} getRunCount={handleRunCount} /> :
                    <></>))}
                <Collapse>
                  <Panel header="Food & Beverage">
                    <Form.Item
                      name={`checkboxValue ${(array_index + 1)}`}
                    >
                      <Checkbox.Group
                        options={mealOptions}
                        defaultValue={['Breakfast']}
                      />
                    </Form.Item>
                  </Panel>
                </Collapse>
              </Panel>
            ))
          }
        </Collapse>
      </div>
      <div className='footer-button-second'>
        <Button loading={loading} type="default" onClick={() => gotoStep(current - 1)}>Previous</Button>
        <Button loading={loading} type="primary" onClick={() => gotoStep(current + 1)}>Next</Button>
      </div>
    </>,

    <>
      <Text style={{ padding: 2 }} strong>Trip Expenses</Text>
      <div style={{ paddingTop: 16 }}>
        <Card title="Transport">
          <div className="trip-expense-transport">
            <div className="trip-expense-transport-first">
              <Form.Item
                label="Vehicle Type"
                name="vehicle_type"
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Bus" />
              </Form.Item>
            </div>
            <div>
              <Form.Item
                label="Rate Per Person (LKR)"
                name="transport_rate_per_person"
                rules={[{
                  pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                  message: "Please enter a number"
                }]}
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Rate" />
              </Form.Item>
            </div>
          </div>
        </Card>
        <Card title="Food and Beverage" style={{ marginTop: 16 }}>
          <Form.Item
            label="Meal Rate Per Person (LKR)"
            name="meal_rate_per_person"
            rules={[{
              pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
              message: "Please enter a number"
            }]}
            labelCol={{ span: 24 }}
          >
            <Input style={{ width: "38%" }} placeholder="Rate" />
          </Form.Item>
        </Card>
        <Card title="Accommodation" style={{ marginTop: 16 }}>
          <Form.Item
            label="Hotel Name"
            name="hotel_name"
            labelCol={{ span: 24 }}
          >
            <Input style={{ width: "38%" }} placeholder="Hotel Name" />
          </Form.Item>
          <Title level={5}>Single Rooms</Title>
          <div className="room-title">
            <Text strong>Deluxe Rooms</Text>
            <Text strong>Standard Rooms</Text>
          </div>
          <div className="rooms-container">
            <div className="rooms-item">
              <Form.Item
                label="Count"
                name="single_deluxe_room_count"
                rules={[{
                  pattern: /^[0-9]+$/,
                  message: "Please enter a number"
                }]}
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Count" />
              </Form.Item>
              <Form.Item
                label="Rate Per Person (LKR)"
                name="single_deluxe_room_rate_per_person"
                rules={[{
                  pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                  message: "Please enter a number"
                }]}
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Rate" />
              </Form.Item>
            </div>
            <div className="rooms-item">
              <Form.Item
                label="Count"
                name="single_standard_room_count"
                rules={[{
                  pattern: /^[0-9]+$/,
                  message: "Please enter a number"
                }]}
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Count" />
              </Form.Item>
              <Form.Item
                label="Rate Per Person (LKR)"
                name="single_standard_room_rate_per_person"
                rules={[{
                  pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                  message: "Please enter a number"
                }]}
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Rate" />
              </Form.Item>
            </div>
          </div>

          <Title level={5}>Double Rooms</Title>
          <div className="room-title">
            <Text strong>Deluxe Rooms</Text>
            <Text strong>Standard Rooms</Text>
          </div>
          <div className="rooms-container">
            <div className="rooms-item">
              <Form.Item
                label="Count"
                name="double_deluxe_room_count"
                rules={[{
                  pattern: /^[0-9]+$/,
                  message: "Please enter a number"
                }]}
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Count" />
              </Form.Item>
              <Form.Item
                label="Rate Per Person (LKR)"
                name="double_deluxe_room_rate_per_person"
                rules={[{
                  pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                  message: "Please enter a number"
                }]}
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Rate" />
              </Form.Item>
            </div>
            <div className="rooms-item">
              <Form.Item
                label="Count"
                name="double_standard_room_count"
                rules={[{
                  pattern: /^[0-9]+$/,
                  message: "Please enter a number"
                }]}
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Count" />
              </Form.Item>
              <Form.Item
                label="Rate Per Person (LKR)"
                name="double_standard_room_rate_per_person"
                rules={[{
                  pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                  message: "Please enter a number"
                }]}
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Rate" />
              </Form.Item>
            </div>
          </div>
          <Title level={5}>Triple Rooms</Title>
          <div className="room-title">
            <Text strong>Deluxe Rooms</Text>
            <Text strong>Standard Rooms</Text>
          </div>
          <div className="rooms-container">
            <div className="rooms-item">
              <Form.Item
                label="Count"
                name="triple_deluxe_room_count"
                rules={[{
                  pattern: /^[0-9]+$/,
                  message: "Please enter a number"
                }]}
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Count" />
              </Form.Item>
              <Form.Item
                label="Rate Per Person (LKR)"
                name="triple_deluxe_room_rate_per_person"
                rules={[{
                  pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                  message: "Please enter a number"
                }]}
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Rate" />
              </Form.Item>
            </div>
            <div className="rooms-item">
              <Form.Item
                label="Count"
                name="triple_standard_room_count"
                rules={[{
                  pattern: /^[0-9]+$/,
                  message: "Please enter a number"
                }]}
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Count" />
              </Form.Item>
              <Form.Item
                label="Rate Per Person (LKR)"
                name="triple_standard_room_rate_per_person"
                rules={[{
                  pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                  message: "Please enter a number"
                }]}
                labelCol={{ span: 24 }}
              >
                <Input placeholder="Rate" />
              </Form.Item>
            </div>
          </div>
        </Card>
      </div>
      <div className='footer-button-third'>
        <Button type="default" onClick={() => gotoStep(current - 1)}>Previous</Button>
        <Button
          type="primary"
          loading={formLoading}
          onClick={() => {
            submit().then(result => {
              if (result === 'ok') {
                gotoStep(current + 1);
              }
            });
          }}
        >
          Add Trip
        </Button>
      </div>
    </>,
  ];

  return (
    <div className="dashboard">
      <div className="section_row">
        {current === 1 ? <div className="top_row_search">
          <Text style={{ padding: 2 }} strong>Add Trip Details</Text>
          <Button
            className="primary__btn"
            onClick={() => handleIncreaseDay()}
          >
            Add New Date
          </Button>
        </div> : <></>}
        <>
          <Form {...formProps}>
            {formList[current]}
          </Form>
          {current === 3 && (
            <>
              {tripUpdate ? <>
                {navigate(`/trips`)}
              </> : <>  <Result
                status="info"
                title="Add Trip Anomaly"
                subTitle="Please check and modify the information before resubmitting."
                extra={[
                  <Button type="primary" key="console" onClick={(() => navigate(`/trips`))}>
                    Go Back
                  </Button>,
                  <Button key="buy" onClick={(() => gotoStep(current - 1))}>Previous</Button>,
                ]}
              >
              </Result>
              </>}
            </>
          )}
        </>
        <AddRunModal
          visible={visibleAddRunModal}
          onDone={handleCancelAddRunModal}
          onCancel={handleCancelAddRunModal}
          onSubmit={handleSubmitAddRunModal}
          runCountVal={runCount}
          memberStatusListVal={memberStatusList}
        />
      </div>
    </div>
  );
};

export default AddTripDetails