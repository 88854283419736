import { Button, message } from "antd";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { PostDataAuth } from "../apiService/PostData";
import { apis } from "../properties";
import { useRefreshTable } from "../store";
import Progress from "react-progress-2";
import useBreadCrumb from "../hooks/useBreadCrumb";
import AddRolesModal from "../components/Role/AddRolesModal";
import RolesDataTable from "../components/Role/RolesDataTable";

function Roles() {
  const location = useLocation();
  useBreadCrumb("MMC Roles", location.pathname, "MMC Roles");
  const [visibleAddRoleModal, setVisibleAddRoleModal] = useState(false);
  const [roleCount, setRoleCount] = useState(0);
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);

  const showModalAddRole = () => {
    setVisibleAddRoleModal(true);
  };

  const handleCancelAddRoleModal = () => {
    setVisibleAddRoleModal(false);
  };

  const handleGetRoleCount = (roleCount) => {
    setRoleCount(roleCount)
  }

  const handleSubmitAddRoleModal = (
    values
  ) => {

    const body = {
      "role_name": values.role_name,
      "status": values.status
    }

    PostDataAuth(apis.ROLES_LIST, body).then((result) => {
      let responseJson = result;

      if (responseJson === "success") {
        message.success("MMC role added successfully")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
          message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });

    setVisibleAddRoleModal(false)
  };

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="top_row">
          <Button
            className="primary__btn"
            onClick={() => showModalAddRole()}
          >
            Add MMC Role
          </Button>
        </div>
        <RolesDataTable getRoleCount={handleGetRoleCount} />
        <AddRolesModal
          visible={visibleAddRoleModal}
          onDone={handleCancelAddRoleModal}
          onCancel={handleCancelAddRoleModal}
          onSubmit={handleSubmitAddRoleModal}
          roleCountVal={roleCount}
        />
      </div>
    </div>
  );
}

export default Roles
