import {
  ExclamationCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import {
  Button,
  message,
  Modal,
  Space,
  Table,
  Tooltip,
  Input,
  Select
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import { apis } from "../../properties";
import { useRefreshTable, useStore } from "../../store";
import { useNavigate } from "react-router-dom";
import { DeleteDataAuth, PutDataAuth } from "../../apiService/PutData";
import { SearchOutlined } from "@ant-design/icons";
import EditRunsModal from "./EditRunsModal";
import useRunsList from "../../hooks/useRunsList";
import Highlighter from "react-highlight-words";
import Progress from "react-progress-2";
import useBeerReturnStock from "../../hooks/useBeerReturnStock";
import useBeerDispatchStock from "../../hooks/useBeerDispatchStock";

const { confirm } = Modal;

function RunsDataTable(props) {
  const { runNumberVal, dayNumberVal, getRunCount } = props
  const navigate = useNavigate();
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [data, setData] = useState([]);
  const [visibleEditRunModal, setVisibleEditRunModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [runId, setRunId] = useState("");
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [beerReturnedRunNums, setBeerReturnedRunNums] = useState([]);
  const searchInput = useRef(null);
  const userData = useStore(state => state.profileData);

  const runsList = useRunsList("");
  const beerReturnStock = useBeerReturnStock("");
  const beerDispatchStock = useBeerDispatchStock("");

  useEffect(() => {
    const tempRunListData = []
    runsList.map((run_list_data) => {
      if (run_list_data.run_status === "Ongoing") {

        if (runNumberVal?.length > 0) {

          const uniqueArray = Array.from(new Set(runNumberVal.map(JSON.stringify))).map(JSON.parse);
          uniqueArray.map((run_number_data) => {

            if (run_list_data.run_number === run_number_data.run_number) {

              if (run_list_data.trip_run_day === dayNumberVal) {
                tempRunListData.push({
                  "key": run_list_data.run_number,
                  "run_number": run_list_data.run_number,
                  "_id": run_list_data._id,
                  "run_name": run_list_data.run_name,
                  "run_date": run_list_data.run_date,
                  "starting_time": run_list_data.starting_time,
                  "run_site": run_list_data.run_site,
                  "run_site_name": run_list_data.run_site_name,
                  "note": run_list_data.note,
                  "registration_fee": run_list_data.registration_fee,
                  "registration_closing_date": run_list_data.registration_closing_date,
                  "cover_image_url": run_list_data.cover_image_url,
                  "run_status": run_list_data.run_status,
                  "complete_status": run_list_data.complete_status,
                  "trip_run_day": run_list_data.trip_run_day,
                  "participants": run_list_data.participants,
                  "participant_count": run_list_data?.participants?.length,
                  "total_collected_amount": (0 / 100).toFixed(2),
                  "created_at": run_list_data.created_at
                })
              }
            }
            return null
          })
        } else {
          tempRunListData.push({
            "key": run_list_data.run_number,
            "run_number": run_list_data.run_number,
            "_id": run_list_data._id,
            "run_name": run_list_data.run_name,
            "run_date": run_list_data.run_date,
            "starting_time": run_list_data.starting_time,
            "run_site": run_list_data.run_site,
            "run_site_name": run_list_data.run_site_name,
            "note": run_list_data.note,
            "registration_fee": run_list_data.registration_fee,
            "registration_closing_date": run_list_data.registration_closing_date,
            "cover_image_url": run_list_data.cover_image_url,
            "run_status": run_list_data.run_status,
            "complete_status": run_list_data.complete_status,
            "participants": run_list_data.participants,
            "participant_count": run_list_data?.participants?.length,
            "total_collected_amount": (0 / 100).toFixed(2),
            "income": run_list_data.income,
            "created_at": run_list_data.created_at
          })
        }
      }

      return null
    })

    // Iterate over the runList array
    for (let run of runsList) {
      let totalAmount = 0;

      // Calculate the total amount from participants' amounts
      for (let participant of run.participants) {
        if (participant.amount) {
          totalAmount += participant.amount;
        }
      }

      // Find the corresponding object in tempRunListData and update the total_collected_amount
      let correspondingObject = tempRunListData.find(obj => obj._id === run._id);
      if (correspondingObject) {
        correspondingObject.total_collected_amount = totalAmount.toFixed(2);
      }
    }

    setData(tempRunListData)
    getRunCount(runsList.at(-1)?.run_number || 0)
  }, [dayNumberVal, getRunCount, runNumberVal, runsList])

  useEffect(() => {
    const selectedDispatchStock = [];

    beerReturnStock.forEach((returnStock) => {
      const matchingDispatchStock = beerDispatchStock.find(
        (dispatchStock) =>
          dispatchStock.dispatch_number === returnStock.dispatch_number &&
          runsList.some((run) => run.run_number === dispatchStock.run_number)
      );

      if (matchingDispatchStock) {
        selectedDispatchStock.push(matchingDispatchStock);
      }
    });

    // Remove duplicate run_number values
    const uniqueRunNumbers = [...new Set(selectedDispatchStock.map((stock) => stock.run_number))];

    setBeerReturnedRunNums(uniqueRunNumbers);

  }, [beerReturnStock, beerDispatchStock, runsList])

  const showModalEditRun = (value) => {
    const filteredRunList = runsList?.filter((run_list_data) => {
      return run_list_data._id === value._id
    })

    const correctedRunDate = filteredRunList[0]?.run_date.replace(/-/g, "/");

    const selectedValueObj = {
      "run_number": filteredRunList[0]?.run_number,
      "run_name": filteredRunList[0]?.run_name,
      "run_date": correctedRunDate,
      "starting_time": filteredRunList[0]?.starting_time,
      "run_site": filteredRunList[0]?.run_site,
      "run_site_name": filteredRunList[0]?.run_site_name,
      "address": filteredRunList[0]?.address,
      "note": filteredRunList[0]?.note,
      "cover_image_url": filteredRunList[0]?.cover_image_url
    }

    setSelectedValue(selectedValueObj);
    setVisibleEditRunModal(true);
    setRunId(value._id)
  };

  const handleCancelEditRunModal = () => {
    setVisibleEditRunModal(false);
  };

  const handleSubmitEditRunModal = (
    values,
    runDate,
    startingTime,
    uploadedImageKitUrl
  ) => {

    const body = {
      "run_name": values.run_name,
      "run_date": runDate,
      "starting_time": startingTime,
      "run_site": values.run_site,
      "run_site_name": values.run_site_name,
      "address": values.address,
      "note": values.note,
      "cover_image_url": uploadedImageKitUrl
    }

    PutDataAuth(`${apis.RUNS_LIST}/${runId}`, body).then((result) => {
      let responseJson = result;

      if (responseJson === "success") {
        message.success("Run updated successfully")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
        message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });

    setVisibleEditRunModal(false)
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const handleCompleteStatusChange = (record, event) => {
    const runListBody = {
      "complete_status": event
    }

    PutDataAuth(`${apis.RUNS_LIST}/${record._id}`, runListBody).then((result) => {
      let responseJson = result;
      if (responseJson === "success") {
        message.success("Run complete status updated successfully")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
        message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });
  }

  const columns = [
    {
      title: "Run Number",
      dataIndex: "run_number",
      sorter: (a, b) => a.run_number - b.run_number,
      sortOrder: "descend",
      ...getColumnSearchProps("run_number"),
    },
    {
      title: "Run Site Name",
      dataIndex: "run_site_name",
      ...getColumnSearchProps("run_name"),
    },
    {
      title: "Starting Time",
      dataIndex: "starting_time",
      ...getColumnSearchProps("starting_time"),
    },
    {
      title: "Run Date",
      dataIndex: "run_date",
      ...getColumnSearchProps("run_date"),
    },
    {
      title: "Participant Count",
      dataIndex: "participant_count",
    },
    {
      title: "Total Collected Amount (LKR)",
      dataIndex: "total_collected_amount",
      fixed: "right",
    },
    {
      title: "Complete Status",
      dataIndex: "complete_status",
      fixed: "right",
      render: (complete_status, record) => {
        const incomeForRun = runsList.find((run) => run.run_number === record.run_number)?.income;
        const isDisabled = complete_status === "Complete" ||
          (!incomeForRun || incomeForRun.length === 0) ||
          !beerReturnedRunNums.includes(record.run_number);

        const tooltip = "You need to update return stock before complete also need to upload the invoice";

        return (
          <Tooltip title={complete_status === "Incomplete" ? tooltip : undefined}>
            <Select
              defaultValue={{ value: complete_status || "Incomplete" }}
              onChange={event => handleCompleteStatusChange(record, event)}
              options={[
                { value: "Incomplete", label: "Incomplete" },
                { value: "Complete", label: "Complete" },
              ]}
              disabled={isDisabled}
            />
          </Tooltip>
        );
      }
    },
    {
      title: "Action",
      render: (text, record) => (
        <Space>
          {record.complete_status === "Complete" ? <Tooltip placement="bottom" title="Edit Run">
            <Button
              className="delete_button_disabled"
              shape="circle"
              icon={<EditOutlined />}
              disabled
            />
          </Tooltip> : <Tooltip placement="bottom" title="Edit Run">
            <Button
              className="edit_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => showModalEditRun(record)}
            />
          </Tooltip>}
          {runNumberVal?.length > 0 ? <></> : <Tooltip placement="bottom" title="View Details">
            <Button
              className="view_button"
              shape="circle"
              icon={<EyeOutlined />}
              onClick={() =>
                navigate(
                  `/runs/view/${record._id}`
                )
              }
            />
          </Tooltip>}
          {
            record.complete_status === "Complete" || userData.role_status === "Admin"  ? <Tooltip placement="bottom" title="Cancel Run">
              <Button
                className="delete_button_disabled"
                shape="circle"
                icon={<DeleteOutlined />}
                disabled
              />
            </Tooltip> : <Tooltip placement="bottom" title="Cancel Run">
              <Button
                className="delete_button"
                shape="circle"
                icon={<DeleteOutlined />}
                onClick={() => deleteRunsContent(record.run_number)}
              />
            </Tooltip>
          }
        </Space>
      ),
      fixed: "right",
      align: "left",
    },
  ];

  const deleteRunsContent = (run_id) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to cancel this run?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        const body = {
          "run_status": "Cancel"
        }

        Progress.show();
        DeleteDataAuth(`${apis.RUNS_LIST}/${run_id}`, body).then((result) => {
          let responseJson = result;

          if (responseJson === "success") {
            message.success("Run canceled")
            setRefreshTable(!refreshTable)
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson?.error?.details[0]?.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };
  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination
        scroll={{ x: 1500 }}
      />
      <EditRunsModal
        visible={visibleEditRunModal}
        onDone={handleCancelEditRunModal}
        onCancel={handleCancelEditRunModal}
        onSubmit={handleSubmitEditRunModal}
        selectedValueData={selectedValue}
      />
    </>
  );
}

export default RunsDataTable;
