import {
    Modal,
    Form,
    Button,
    Input
} from 'antd';
import { useEffect } from 'react';

const EditMemberStatusModal = (props) => {
    const [formEditMemberStatus] = Form.useForm();
    const {
        visible,
        onCancel,
        onSubmit,
        selectedValueData,
    } = props;

    useEffect(() => {
        formEditMemberStatus.setFieldsValue({ "member_status": selectedValueData.member_status })
        formEditMemberStatus.setFieldsValue({ "amount": selectedValueData.amount })
    })

    const handleSubmit = () => {
        if (!formEditMemberStatus) return;
        formEditMemberStatus.submit();
    };

    const handleFinish = (values) => {
        if (onSubmit) {
            onSubmit(values);
            formEditMemberStatus.resetFields();
        }
    };

    const modalFooter = { onCancel };

    const getModalContent = () => {
        return (
            <Form
                form={formEditMemberStatus}
                name="formEditMemberStatus"
                onFinish={handleFinish}
            >
                <Form.Item
                    label="Member Status"
                    name="member_status"
                    rules={[{ required: true, message: 'Please enter a member status' }]}
                    labelCol={{ span: 24 }}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    label="Amount (LKR)"
                    name="amount"
                    rules={[{ required: true, message: 'Please enter a amount' }, {
                        pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                        message: "Please enter a number"
                    }]}
                    labelCol={{ span: 24 }}
                >
                    <Input />
                </Form.Item>
            </Form>
        );
    };

    return (
        <Modal
            title='Change Member Status'
            destroyOnClose
            width={500}
            open={visible}
            {...modalFooter}
            footer={[
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Change
                </Button>
            ]}
        >
            {getModalContent()}
        </Modal>
    );
};

export default EditMemberStatusModal;
