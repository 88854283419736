import {
  FileTextOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined
} from "@ant-design/icons";
import {
  Button,
  Space,
  Table,
  Tooltip,
  message,
  Input,
  Modal,
  Badge,
  Tag,
  Select
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom"
import { PutDataAuth } from "../../apiService/PutData";
import { apis } from "../../properties";
import { useRefreshTable } from "../../store";
import { SearchOutlined } from '@ant-design/icons';
import { DeleteDataAuth } from "../../apiService/DeleteData";
import { PostDataAuth } from "../../apiService/PostData";
import Progress from "react-progress-2";
import useMemberProfilesList from "../../hooks/useMemberProfilesList";
import AddTripParticipantsModal from "./AddTripParticipantsModal";
import useTripsList from "../../hooks/useTripsList";
import Highlighter from 'react-highlight-words';
import useMemberStatusList from "../../hooks/useMemberStatusList";
import jsPDF from "jspdf";
import "jspdf-autotable";
import useRunsList from "../../hooks/useRunsList";

const { confirm } = Modal;

function TripParticipantsDataTable() {
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [data, setData] = useState([]);
  const [visibleAddParticipantModal, setVisibleAddParticipantModal] = useState(false);
  const [inTripMemberIdArr, setInTripMemberIdArr] = useState([]);
  const [outTripMemberIdArr, setOutTripMemberIdArr] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const params = useParams();

  const memberProfilesList = useMemberProfilesList("");
  const tripList = useTripsList(`?_id=${params.trip_id}`);
  const allTripList = useTripsList("");
  const allRunList = useRunsList("");
  const runsList = useRunsList("");
  const memberStatusList = useMemberStatusList("");

  useEffect(() => {
    const tempMemberProfilesListData = []

    memberProfilesList?.map((member_profiles_list_data) => {
      tripList?.map((trip_list_data) => {

        trip_list_data?.participants?.map((participant_data) => {

          if (participant_data.member_id === member_profiles_list_data.member_id) {
            if (participant_data.payment_number) {
              tempMemberProfilesListData.push({
                "key": member_profiles_list_data._id,
                "_id": member_profiles_list_data._id,
                "member_id": member_profiles_list_data.member_id,
                "member_name": `${member_profiles_list_data.first_name} ${member_profiles_list_data.last_name}`,
                "hash_name": member_profiles_list_data.hash_name,
                "status": member_profiles_list_data.status,
                "room_type": participant_data.room_type,
                "payment_type": member_profiles_list_data.payment_type,
                "payment": "Paid",
                "former_runs": member_profiles_list_data.former_runs,
                "former_trips": member_profiles_list_data.former_trips,
                "former_hares": member_profiles_list_data.former_hares
              })
            } else {
              tempMemberProfilesListData.push({
                "key": member_profiles_list_data._id,
                "_id": member_profiles_list_data._id,
                "member_id": member_profiles_list_data.member_id,
                "member_name": `${member_profiles_list_data.first_name} ${member_profiles_list_data.last_name}`,
                "hash_name": member_profiles_list_data.hash_name,
                "status": member_profiles_list_data.status,
                "room_type": participant_data.room_type,
                "payment_type": member_profiles_list_data.payment_type,
                "payment": "Not Paid",
                "former_runs": member_profiles_list_data.former_runs,
                "former_trips": member_profiles_list_data.former_trips,
                "former_hares": member_profiles_list_data.former_hares
              })
            }
          }

          return null
        })

        setInTripMemberIdArr(trip_list_data?.participants)

        return null
      })

      return null
    })

    // Initialize counters for each member
    const memberCounts = {};
    for (const member of tempMemberProfilesListData) {
      memberCounts[member.member_id] = { runs: 0, hares: 0, trips: 0 };
    }
    for (const run of allRunList) {
      // Update runs counter for each participant with matching member ID and run_status "Attended"
      for (const participant of run.participants) {
        if (memberCounts[participant.member_id] && participant.run_status === "Attended") {
          memberCounts[participant.member_id].runs++;
        }
      }

      // Update hares counter for each member ID in the run object if participant.run_status is "Attended"
      for (const member_id of run.member_id) {
        if (memberCounts[member_id] && run.participants.some(participant => participant.member_id === member_id && participant.run_status === "Attended")) {
          memberCounts[member_id].hares++;
        }
      }
    }

    for (const trip of allTripList) {
      // Update trips counter for each participant with matching member ID and trip_status "Attended"
      for (const participant of trip.participants) {
        if (memberCounts[participant.member_id] && participant.trip_status === "Attended") {
          memberCounts[participant.member_id].trips++;
        }
      }
    }

    // Add the counts to the original members array
    for (const member of tempMemberProfilesListData) {
      member.runs = (memberCounts[member.member_id].runs + (member.former_runs || 0));
      member.hares = (memberCounts[member.member_id].hares + (member.former_hares || 0));
      member.trips = (memberCounts[member.member_id].trips + (member.former_trips || 0));

      // Reduce 1 from newly added hares values if both conditions are met
      runsList.forEach((run_list) => {
        if (run_list.complete_status !== "Complete") {
          for (const participant of run_list.participants) {
            if (participant.member_id === member.member_id && participant.run_status === "Attended") {
              // Check if the participant is a hare in the current run_list
              const isHare = run_list.member_id.includes(member.member_id);
              member.runs = Math.max(0, member.runs - 1);
              if (isHare) {
                member.hares = Math.max(0, member.hares - 1);
              }
              break; // No need to check other participants in this run_list
            }
          }
        }
      });

      tripList.forEach((trip) => {
        if (trip.complete_status !== "Complete") {
          for (const participant of trip.participants) {
            if (participant.member_id === member.member_id && participant.trip_status === "Attended") {
              // Check if the participant is a hare in the current trip
              member.trips = Math.max(0, member.trips - 1);
              break; // No need to check other participants in this trip
            }
          }
        }
      });
    }

    setData(tempMemberProfilesListData);

    const tripListParticipantsArr = tripList[0]?.participants
    // filtering member ids already in the trip
    const comparedArray = memberProfilesList.filter(ar => !tripListParticipantsArr?.find(rm => (rm.member_id === ar.member_id && ar.member_id === rm.member_id)));

    const tempSetMemberData = []
    comparedArray.map((compared_data) => {
      tempSetMemberData.push({
        "value": compared_data.member_id,
        "label": `${compared_data.member_id} - ${compared_data.first_name} ${compared_data.last_name} / ${compared_data.hash_name}`
      })

      return null
    })

    setOutTripMemberIdArr(tempSetMemberData)
  }, [allRunList, allTripList, inTripMemberIdArr, memberProfilesList, runsList, tripList])

  const showModalAddParticipant = () => {
    const participantCount = tripList?.[0]?.participant_count
    const currentParticipantCount = tripList?.[0]?.participants.length

    if (participantCount === currentParticipantCount) {
      message.warn("Can't add participants to the trip. All filled");
    } else {
      setVisibleAddParticipantModal(true);
    }
  };

  const handleCancelAddParticipantModal = () => {
    setVisibleAddParticipantModal(false);
  };

  const handleSubmitAddParticipantModal = (
    values,
    roomCheckValue
  ) => {
    if (roomCheckValue !== "") {
      const inputString = roomCheckValue;

      // Remove "_count" from the string
      const removedCount = inputString.replace("_count", "");

      // Split the string by "_"
      const splitString = removedCount.split("_");

      // Capitalize the first letter of each word
      const capitalizedWords = splitString.map(word => word.charAt(0).toUpperCase() + word.slice(1));

      // Join the words back into a single string
      const finalString = capitalizedWords.join(" ");


      let tempRoomCost = 0;
      const roomType = roomCheckValue.replace("_count", "");

      // Loop through the trip_expenses array
      for (const expense of tripList[0]?.trip_expenses) {
        // Access the value of roomCheckValue from the expense object
        const roomCount = expense[roomCheckValue];

        // If roomCount is greater than 0, add the corresponding rate to tempTotalCost
        if (roomCount > 0) {
          const ratePerPerson = expense[`${roomType}_rate_per_person`];
          tempRoomCost += 1 * ratePerPerson;
        }
      }

      const submitInTripMemberIdArr = inTripMemberIdArr

      submitInTripMemberIdArr.push({
        "member_id": values.member_id,
        "room_type": finalString,
        "room_cost": tempRoomCost,
        "updated_date": new Date(),
        "trip_status": "Not Attended",
        "meal_cost": values.meals ? tripList[0]?.trip_expenses[0]?.meal_rate_per_person || 0 : 0,
        "transport_cost": values.transport ? tripList[0]?.trip_expenses[0]?.transport_rate_per_person || 0 : 0
      });

      const body = {
        "participants": submitInTripMemberIdArr
      }

      Progress.show();
      PutDataAuth(`${apis.TRIPS_LIST}/${params.trip_id}`, body).then((result) => {
        let responseJson = result;

        if (responseJson === "success") {
          message.success("Participant added to the trip successfully")
          setRefreshTable(!refreshTable)
          Progress.hide();
        } else if (responseJson.status === "error") {
          message.error(responseJson?.error?.details[0]?.message);
          Progress.hide();
        }
      });

      setVisibleAddParticipantModal(false)
    } else {
      message.info("You must choose a room")
    }
  };

  const handleRemoveHare = (value) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to delete this content?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        Progress.show();
        const submitParticipantsArr = inTripMemberIdArr.filter((item) => {
          return item.member_id !== value.member_id
        })

        const body = {
          "participants": submitParticipantsArr
        }

        PutDataAuth(`${apis.TRIPS_LIST}/${params.trip_id}`, body).then((result) => {
          let responseJson = result;

          if (responseJson === "success") {
            message.success("Participant removed from the trip successfully")
            setRefreshTable(!refreshTable)
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson?.error?.details[0]?.message);
            Progress.hide();
          }
        });

      },
      onCancel() {
        console.log("Cancel");
      },
    });
  }

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const exportParticipantListPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);

    const title = "Colombo Hash";
    const subtitle = `${tripList[0]?.trip_number}-${tripList[0]?.trip_name}, Trip Participant List, Participant Number-${data.length}`;
    const headers = [[
      "Member ID",
      "Member Name",
      "Hash Name",
      "Hares",
      "Runs",
      "Attendance"
    ]];

    const tableData = data.map(elt => [
      elt.member_id,
      elt.member_name,
      elt.hash_name,
      elt.hares,
      elt.runs,
      elt.attendance
    ]);

    let content = {
      startY: 65,
      head: headers,
      body: tableData,
    };

    // Add title and new subtitle
    doc.text(title, marginLeft, 30);
    doc.setFontSize(12); // Reduce font size for the subtitle
    doc.text(subtitle, marginLeft, 50); // Add the new subtitle below the title

    // Get the current date and time
    const now = new Date();
    const generatedDateTime = `${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;

    // Add generated date and time in the bottom right corner
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.setFontSize(10); // Adjust font size for the generated date and time
    doc.text(generatedDateTime, pageWidth - marginLeft, pageHeight - 30, {
      align: "right",
    });

    doc.autoTable(content);
    doc.save(`trip-number${tripList[0]?.trip_number}-participant-list.pdf`);
  }

  const exportParticipantDataPDF = (record) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);

    const title = "Colombo Hash";
    const subtitle = `${tripList[0]?.trip_number}-${tripList[0]?.trip_name}, Trip Participant Data`;
    const headers = [[
      "Member ID",
      "Member Name",
      "Hash Name",
      "Hares",
      "Runs",
      "Trip Number",
      "Room Type"
    ]];

    const tableData = [record].map(elt => [
      elt.member_id,
      elt.member_name,
      elt.hash_name,
      elt.hares,
      elt.runs,
      tripList[0]?.trip_number,
      elt.room_type
    ]);

    let content = {
      startY: 65,
      head: headers,
      body: tableData,
    };

    // Add title and new subtitle
    doc.text(title, marginLeft, 30);
    doc.setFontSize(12); // Reduce font size for the subtitle
    doc.text(subtitle, marginLeft, 50); // Add the new subtitle below the title

    // Get the current date and time
    const now = new Date();
    const generatedDateTime = `${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;

    // Add generated date and time in the bottom right corner
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.setFontSize(10); // Adjust font size for the generated date and time
    doc.text(generatedDateTime, pageWidth - marginLeft, pageHeight - 30, {
      align: "right",
    });

    doc.autoTable(content);
    doc.save(`trip-number${tripList[0]?.trip_number}-member-id${record.member_id}-participant.pdf`);
  }

  const handlePaymentChange = (record, event) => {
    const paymentType = "Cash";
    const paymentDate = new Date().toJSON().slice(0, 10)
    const submitInRunMemberIdArr = tripList[0].participants

    if (event === "Not Paid") {
      for (let i in submitInRunMemberIdArr) {
        if (submitInRunMemberIdArr[i].member_id === record.member_id) {
          delete submitInRunMemberIdArr[i].payment_number;
          delete submitInRunMemberIdArr[i].payment_type;
          delete submitInRunMemberIdArr[i].payment_description;
          submitInRunMemberIdArr[i].trip_status = "Not Attended";
          break; //Stop this loop, we found it!
        }
      }

      DeleteDataAuth(`${apis.PAYMENTS_LIST}?payment_description=trip-${tripList[0]?.trip_number}&member_id=${record.member_id}`)
        .then((result) => {
          let responseJson = result;
          if (responseJson === "success") {
            const tripListBody = {
              "participants": submitInRunMemberIdArr
            }

            PutDataAuth(`${apis.TRIPS_LIST}/${params.trip_id}`, tripListBody).then((result) => {
              let responseJson = result;
              if (responseJson === "success") {
                message.success("Payment removed from the member")
                setRefreshTable(!refreshTable)
                Progress.hide();
              } else if (responseJson.status === "error") {
                message.error(responseJson?.error?.details[0]?.message);
                Progress.hide();
              }
            });

          } else if (responseJson.status === "error") {
            message.error(responseJson?.error?.details[0]?.message);
            Progress.hide();
          }
        });

    } else {
      let amount = 0;
      tripList[0]?.participants.map((participant_data) => {
        if (participant_data.member_id === record.member_id) {
          if (participant_data.room_cost || participant_data.meal_cost || participant_data.transport_cost) {
            amount += participant_data.room_cost
            amount += participant_data.meal_cost
            amount += participant_data.transport_cost
          }
        }

        return null
      })

      const body = {
        "amount": amount,
        "payment_date": [paymentDate, ""],
        "payment_type": paymentType,
        "payment_description": `trip-${tripList[0].trip_number}`,
        "member_id": Number(record.member_id)
      }

      PostDataAuth(`${apis.PAYMENTS_LIST}`, body).then((result) => {
        let responseJson = result;

        if (responseJson.payment_number) {
          for (let i in submitInRunMemberIdArr) {
            if (submitInRunMemberIdArr[i].member_id === record.member_id) {
              submitInRunMemberIdArr[i].payment_number = responseJson.payment_number;
              submitInRunMemberIdArr[i].payment_type = paymentType;
              submitInRunMemberIdArr[i].payment_description = `trip-${tripList[0].trip_number}`;
              submitInRunMemberIdArr[i].trip_status = "Attended";
              break; //Stop this loop, we found it!
            }
          }

          const tripListBody = {
            "participants": submitInRunMemberIdArr
          }

          PutDataAuth(`${apis.TRIPS_LIST}/${params.trip_id}`, tripListBody).then((result) => {
            let responseJson = result;
            if (responseJson === "success") {
              message.success("Payment added to the member successfully")
              setRefreshTable(!refreshTable)
              Progress.hide();
            } else if (responseJson.status === "error") {
              message.error(responseJson?.error?.details[0]?.message);
              Progress.hide();
            }
          });

        } else if (responseJson.status === "error") {
          message.error(responseJson?.error?.details[0]?.message);
          Progress.hide();
        }
      });
    }
  }

  const columns = [
    {
      title: "Member ID",
      dataIndex: "member_id",
      sorter: (a, b) => a.member_id - b.member_id,
      sortOrder: 'descend',
      ...getColumnSearchProps('member_id'),
    },
    {
      title: "Member Name",
      dataIndex: "member_name",
      ...getColumnSearchProps('member_name'),
      render: (member_name, record) => {
        // Check if the payment type is 'Standing Order'
        if (record.payment_type === "Standing Order") {
          // Render a Badge before the member name
          return (
            <div>
              <Badge color="#ffec3d" />
              <span>{member_name}</span>
            </div>
          );
        }
        // If not, render the member name as usual
        return member_name;
      },
    },
    {
      title: "Hash Name",
      dataIndex: "hash_name",
      ...getColumnSearchProps('hash_name'),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (status) => {
        const matchingStatus = memberStatusList.find(
          (member_status_data) => member_status_data._id === status
        );

        if (matchingStatus) {
          return <Tag>{matchingStatus.member_status}</Tag>;
        }

        return null
      }
    },
    {
      title: "Room Type",
      dataIndex: "room_type",
      ...getColumnSearchProps('room_type'),
    },
    {
      title: "Hares",
      dataIndex: "hares",
      render: (hares) => {
        const isMultipleOfFiveMinusOne = (hares % 5 === 4);
        return (
          <>
            {isMultipleOfFiveMinusOne ? <Badge status="success" text={hares} /> : <>{hares}</>}
          </>
        );
      },
    },
    {
      title: "Runs",
      dataIndex: "runs",
      render: (runs) => {
        const isMultipleOfFiveMinusOne = (runs % 5 === 4);
        return (
          <>
            {isMultipleOfFiveMinusOne ? <Badge status="success" text={runs} /> : <>{runs}</>}
          </>
        );
      },
    },
    {
      title: "Trips",
      dataIndex: "trips",
      render: (trips) => {
        const isMultipleOfFiveMinusOne = (trips % 5 === 4);
        return (
          <>
            {isMultipleOfFiveMinusOne ? <Badge status="success" text={trips} /> : <>{trips}</>}
          </>
        );
      },
    },
    {
      title: "Attendance",
      dataIndex: "payment_type",
      render: (payment_type, record) => {
        return tripList[0]?.participants.map((participant_data, index) => {
          if (record.member_id === participant_data.member_id) {
            return (
              <Select
                key={index}
                value={{ value: participant_data.trip_status }}
                disabled
              />
            );
          }
          return null; // Add this line if there are other cases where the map doesn't return a value
        });
      }
    },
    {
      title: "Payment",
      dataIndex: "payment",
      fixed: "right",
      render: (payment, record) => {
        const isComplete = tripList[0]?.complete_status === "Complete";

        if (isComplete) {
          return (
            <Select
              defaultValue={{ value: payment }}
              disabled
            />
          );
        } else {
          return (
            <Select
              defaultValue={{ value: payment || "Not Paid" }}
              onChange={(event) => handlePaymentChange(record, event)}
              options={[
                { value: 'Paid', label: 'Paid' },
                { value: 'Not Paid', label: 'Not Paid' }
              ]}
            />
          );
        }
      }
    },
    {
      title: "Action",
      render: (text, record) => (
        <Space>
          <Tooltip placement="bottom" title="Invoice Download">
            <Button
              className="delete_button"
              shape="circle"
              icon={<FileTextOutlined />}
              onClick={() => exportParticipantDataPDF(record)}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Delete Member">
            <Button
              className={tripList[0]?.complete_status === "Complete" ? "delete_button_disabled" : "delete_button"}
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => handleRemoveHare(record)}
              disabled={tripList[0]?.complete_status === "Complete"}
            />
          </Tooltip>
        </Space>
      ),
      fixed: "right",
      align: 'left',
    },
  ];

  return (
    <>
      <div className="top_row_both_side">
        <div>
          <p><Badge status="success" />Anniversary Stats</p>
          <p><Badge color="#ffec3d" />Standing Order Member</p>
        </div>
        <Space>
          <Button
            className="primary__btn"
            onClick={() => showModalAddParticipant()}
            disabled={tripList[0]?.complete_status === "Complete"}
          >
            Add Participants
          </Button>
          <Tooltip placement="bottom" title="Participant List Download">
            <Button
              className="edit_button"
              shape="circle"
              icon={<FileTextOutlined />}
              onClick={() => exportParticipantListPDF()}
            />
          </Tooltip>
        </Space>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination
        scroll={{ x: 1500 }}
      />
      <AddTripParticipantsModal
        visible={visibleAddParticipantModal}
        onDone={handleCancelAddParticipantModal}
        onCancel={handleCancelAddParticipantModal}
        onSubmit={handleSubmitAddParticipantModal}
        membersData={outTripMemberIdArr}
        tripListData={tripList}
      />
    </>
  );
}

export default TripParticipantsDataTable;
