import {
  Form,
  Row,
  Col,
  Input,
  Button,
  message,
  Select,
  Typography,
  Spin,
  Space
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import { apis } from "../../properties";
import { IKContext, IKUpload } from 'imagekitio-react';
import { PutDataAuth } from "../../apiService/PutData";
import { useRefreshTable } from "../../store";
import useTripsList from "../../hooks/useTripsList";
import useMemberProfilesList from "../../hooks/useMemberProfilesList";
import Progress from "react-progress-2";
import useRolesList from "../../hooks/useRolesList";

const { Text } = Typography;

function TripIncome() {
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [formAddIncome] = Form.useForm();
  const [edit, setEdit] = useState(false);
  const [uploadedImageKitUrl, setUploadedImageKitUrl] = useState("");
  const [memberData, setMemberData] = useState([]);
  const [loading, setLoading] = useState(false);
  const params = useParams();

  const publicKey = process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY
  const urlEndpoint = process.env.REACT_APP_IMAGE_PATH
  const authenticationEndpoint = apis.IMAGEKIT_AUTH;

  const memberProfileList = useMemberProfilesList("");
  const roleStatusList = useRolesList("");
  const tripList = useTripsList(`?_id=${params.trip_id}`);

  useEffect(() => {
    formAddIncome.setFieldsValue({ "beer_cost": (0 / 100).toFixed(2) })
    formAddIncome.setFieldsValue({ "entertainment_cost": (0 / 100).toFixed(2) })
    formAddIncome.setFieldsValue({ "food_and_soft_drink_cost": (0 / 100).toFixed(2) })
    formAddIncome.setFieldsValue({ "other_cost": (0 / 100).toFixed(2) })

    const tempMemberList = []

    memberProfileList?.map((member_list_data) => {
      roleStatusList?.map((role_status_data) => {
        if (member_list_data?.role_number === role_status_data.role_number) {
          if (role_status_data.status === "Super Admin") {
            tempMemberList.push({
              "value": member_list_data.member_id,
              "label": `${member_list_data.first_name} ${member_list_data.last_name}`
            })
          }
        }

        return null
      })

      return null
    })

    tripList[0]?.income?.map((income_data) => {
      tempMemberList.map((member_list_data) => {
        if (income_data.member_id === member_list_data.value) {
          formAddIncome.setFieldsValue({ "member_id": member_list_data.label })
          formAddIncome.setFieldsValue({ "beer_cost": income_data.beer_cost.toFixed(2) || (0 / 100).toFixed(2) })
          formAddIncome.setFieldsValue({ "entertainment_cost": income_data.entertainment_cost.toFixed(2) || (0 / 100).toFixed(2) })
          formAddIncome.setFieldsValue({ "food_and_soft_drink_cost": income_data.food_and_soft_drink_cost.toFixed(2) || (0 / 100).toFixed(2) })
          formAddIncome.setFieldsValue({ "other_cost": income_data.other_cost.toFixed(2) || (0 / 100).toFixed(2) })
          formAddIncome.setFieldsValue({ "balance": income_data.balance.toFixed(2) })
        }
        return null
      })

      return null
    })

    let collection = 0;
    tripList[0]?.participants.map((participant_data) => {
      if (participant_data.payment_number) {
        if (participant_data.room_cost || participant_data.meal_cost || participant_data.transport_cost) {
          collection += participant_data.room_cost
          collection += participant_data.meal_cost
          collection += participant_data.transport_cost
        }
      }

      return null
    })

    const collectionWithCents = collection?.toFixed(2);
    formAddIncome.setFieldsValue({ "collection": collectionWithCents })

    setMemberData(tempMemberList)
  }, [formAddIncome, memberProfileList, tripList, roleStatusList])

  const handleEdit = () => {
    setEdit(true);
  }

  const handleFinish = (values) => {
    const body = {
      "income": [{
        "member_id": values.member_id,
        "invoice": uploadedImageKitUrl,
        "collection": Number(values.collection),
        "beer_cost": Number(values.beer_cost),
        "entertainment_cost": Number(values.entertainment_cost),
        "food_and_soft_drink_cost": Number(values.food_and_soft_drink_cost),
        "other_cost": Number(values.other_cost),
        "balance": Number(values.balance)
      }]
    }

    Progress.show();
    PutDataAuth(`${apis.TRIPS_LIST}/${params.trip_id}`, body).then((result) => {
      let responseJson = result;

      if (responseJson === "success") {
        message.success("Income added to the trip successfully")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
        message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });

    setEdit(false)
  }

  const onImageUploadError = err => {
    message.error(err)
    setLoading(false)
  };

  const onImageUploadSuccess = res => {
    setUploadedImageKitUrl(res.url)
    setLoading(false)
  };

  const handleImageUploadChange = (e) => {
    if (e.type === "change") {
      setLoading(true)
    }
  }

  const handleCalculateBalance = () => {
    const fieldValues = formAddIncome.getFieldsValue();
    const balance = Number(fieldValues.collection) -
      (Number(fieldValues.beer_cost) + Number(fieldValues.entertainment_cost) + Number(fieldValues.food_and_soft_drink_cost) + Number(fieldValues.other_cost));

    formAddIncome.setFieldsValue({ "balance": balance.toFixed(2) })
  }

  const handleDownloadInvoice = () => {
    // Get the invoice URL from the record object
    const invoiceUrl = tripList[0]?.income[0]?.invoice
    window.open(invoiceUrl, '_blank');
  };

  return (
    <>
      {edit ? <>
        <Form
          form={formAddIncome}
          onFinish={handleFinish}
        >
          <Row gutter={16}>
            <Col xl={{ span: 12 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
              <Form.Item
                label="Collection (LKR)"
                name="collection"
                rules={[
                  { required: true, message: 'Please enter collection' },
                  {
                    pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                    message: "Please enter a number"
                  }]}
                labelCol={{ span: 24 }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xl={{ span: 12 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
              <Form.Item
                label="Beer Cost (LKR)"
                name="beer_cost"
                rules={[
                  { required: true, message: 'Please enter beer cost' },
                  {
                    pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                    message: "Please enter a number"
                  }]}
                labelCol={{ span: 24 }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Entertainment Cost (LKR)"
                name="entertainment_cost"
                rules={[
                  {
                    pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                    message: "Please enter a number"
                  }]}
                labelCol={{ span: 24 }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Food and Soft Drink Cost (LKR)"
                name="food_and_soft_drink_cost"
                rules={[
                  {
                    pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                    message: "Please enter a number"
                  }]}
                labelCol={{ span: 24 }}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Other Cost (LKR)"
                name="other_cost"
                rules={[
                  {
                    pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                    message: "Please enter a number"
                  }]}
                labelCol={{ span: 24 }}
              >
                <Input />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xl={{ span: 12 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
              <Form.Item
                label="Depositor"
                name="member_id"
                rules={[{ required: true, message: 'Please select a depositor' }]}
                labelCol={{ span: 24 }}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label?.toLowerCase() ?? '').localeCompare((optionB?.label?.toLowerCase() ?? ''))
                  }
                  options={memberData}
                />
              </Form.Item>
            </Col>
            <Col xl={{ span: 12 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
              <Form.Item
                label="Balance"
                name="balance"
                rules={[
                  { required: true, message: 'Please enter balance' }]}
                labelCol={{ span: 24 }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ paddingBottom: 16 }}>
            <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
              <IKContext
                publicKey={publicKey}
                urlEndpoint={urlEndpoint}
                authenticationEndpoint={authenticationEndpoint}
              >
                <Space direction="vertical">
                  <Text>
                    <span style={{ color: 'red' }}>*</span> Upload Bank Slip
                  </Text>
                  <Spin spinning={loading}>
                    <IKUpload
                      fileName=""
                      onError={onImageUploadError}
                      onSuccess={onImageUploadSuccess}
                      onChange={((e) => handleImageUploadChange(e))}
                    />
                  </Spin>
                </Space>
              </IKContext>
            </Col>
          </Row>
          <div className="top_row">
            <Button
              type="primary"
              onClick={handleCalculateBalance}
              loading={loading}
            >
              Calculate Balance
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
            >
              Save Details
            </Button>
          </div>
        </Form>
      </> : <>
        <div className="top_row">
          {
            tripList[0]?.complete_status === "Complete" ? <Button
              className="primary__btn"
              disabled
            >
              Edit
            </Button> : <Button
              className="primary__btn"
              onClick={() => handleEdit()}
            >
              Edit
            </Button>
          }

        </div>
        <Form
          form={formAddIncome}
        >
          <Row gutter={16}>
            <Col xl={{ span: 12 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
              <Form.Item
                label="Collection (LKR)"
                name="collection"
                rules={[{ required: true, message: 'Please select a collection' }]}
                labelCol={{ span: 24 }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
            <Col xl={{ span: 12 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
              <Form.Item
                label="Beer Cost (LKR)"
                name="beer_cost"
                rules={[
                  { required: true, message: 'Please enter beer cost' },
                  {
                    pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                    message: "Please enter a number"
                  }]}
                labelCol={{ span: 24 }}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="Entertainment Cost (LKR)"
                name="entertainment_cost"
                rules={[
                  {
                    pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                    message: "Please enter a number"
                  }]}
                labelCol={{ span: 24 }}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="Food and Soft Drink Cost (LKR)"
                name="food_and_soft_drink_cost"
                rules={[
                  {
                    pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                    message: "Please enter a number"
                  }]}
                labelCol={{ span: 24 }}
              >
                <Input disabled />
              </Form.Item>
              <Form.Item
                label="Other Cost (LKR)"
                name="other_cost"
                rules={[
                  {
                    pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                    message: "Please enter a number"
                  }]}
                labelCol={{ span: 24 }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xl={{ span: 12 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
              <Form.Item
                label="Depositor"
                name="member_id"
                rules={[{ required: true, message: 'Please select a depositor' }]}
                labelCol={{ span: 24 }}
              >
                <Select
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    (optionA?.label?.toLowerCase() ?? '').localeCompare((optionB?.label?.toLowerCase() ?? ''))
                  }
                  options={memberData}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xl={{ span: 12 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
              <Form.Item
                label="Balance (LKR)"
                name="balance"
                rules={[
                  { required: true, message: 'Please enter balance' }]}
                labelCol={{ span: 24 }}
              >
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>
          <div className="top_row">
            <Button onClick={() => handleDownloadInvoice()} type="primary">Download Invoice</Button>
          </div>
        </Form>
      </>}
    </>
  );
}

export default TripIncome;
