import {
  FileTextOutlined,
  RollbackOutlined,
} from "@ant-design/icons";
import { Button, Space, Table, Tooltip, message, Input } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useRefreshTable } from "../../store";
import { PostDataAuth } from "../../apiService/PostData";
import { apis } from "../../properties";
import { SearchOutlined } from '@ant-design/icons';
import useBeerDispatchStock from "../../hooks/useBeerDispatchStock";
import useRunsList from "../../hooks/useRunsList";
import ReturnBeerStockModal from "./ReturnBeerStockModal";
import useBeerReturnStock from "../../hooks/useBeerReturnStock";
import Highlighter from 'react-highlight-words';
import jsPDF from "jspdf";
import "jspdf-autotable";

function BeerDispatchDataTable() {
  const [data, setData] = useState([]);
  const [visibleReturnStockModal, setVisibleReturnStockModal] = useState(false);
  const [dispatchNumber, setDispatchNumber] = useState();
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [filteredBeerDispatch, setFilteredBeerDispatch] = useState([]);
  const [stockNumber, setStockNumber] = useState();
  const [beerLoading, setBeerLoading] = useState(false);
  const searchInput = useRef(null);
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);

  const runList = useRunsList("");
  const beerDispatchStockList = useBeerDispatchStock("");
  const beerReturnStockList = useBeerReturnStock("");

  useEffect(() => {
    const tempDispatchStock = []
    beerDispatchStockList?.map((beer_dispatch_stock_data) => {
      runList?.map((run_list_data) => {

        if (beer_dispatch_stock_data.run_number === run_list_data.run_number) {
          const tempDispatchQuantity = []
          beer_dispatch_stock_data?.dispatch_quantity?.map((added_quantity_data) => {
            tempDispatchQuantity.push({
              "quantity_data": `${added_quantity_data.volume}ml - ${added_quantity_data.quantity}`
            })

            return null
          })
          tempDispatchStock.push({
            "key": beer_dispatch_stock_data.dispatch_number,
            "dispatch_number": beer_dispatch_stock_data.dispatch_number,
            "run_number": beer_dispatch_stock_data.run_number,
            "dispatch_date": beer_dispatch_stock_data.dispatch_date,
            "dispatch_quantity": tempDispatchQuantity
          })
        }

        return null
      })

      return null
    })

    const tempData = []
    tempDispatchStock.map((temp_dispatch_stock_data) => {
      if (beerReturnStockList.length > 0) {
        beerReturnStockList.map((beer_return_stock_data) => {

          if (beer_return_stock_data.dispatch_number === temp_dispatch_stock_data.dispatch_number) {
            const tempReturnQuantity = []
            beer_return_stock_data?.return_quantity?.map((return_quantity_data) => {
              tempReturnQuantity.push({
                "quantity_data": `${return_quantity_data.volume}ml - ${return_quantity_data.quantity}`
              })

              return null
            })
            tempData.push({
              "dispatch_number": temp_dispatch_stock_data.dispatch_number,
              "run_number": temp_dispatch_stock_data.run_number,
              "dispatch_date": temp_dispatch_stock_data.dispatch_date,
              "dispatch_quantity": temp_dispatch_stock_data.dispatch_quantity,
              "return_date": beer_return_stock_data.return_date,
              "return_quantity": tempReturnQuantity
            })
          } else {
            tempData.push({
              "key": temp_dispatch_stock_data.dispatch_number,
              "dispatch_number": temp_dispatch_stock_data.dispatch_number,
              "run_number": temp_dispatch_stock_data.run_number,
              "dispatch_date": temp_dispatch_stock_data.dispatch_date,
              "dispatch_quantity": temp_dispatch_stock_data.dispatch_quantity,
            })
          }

          return null
        })
      } else {
        tempData.push({
          "key": temp_dispatch_stock_data.dispatch_number,
          "dispatch_number": temp_dispatch_stock_data.dispatch_number,
          "run_number": temp_dispatch_stock_data.run_number,
          "dispatch_date": temp_dispatch_stock_data.dispatch_date,
          "dispatch_quantity": temp_dispatch_stock_data.dispatch_quantity,
        })
      }

      return null
    })

    const filteredData = tempData.reduce((acc, curr) => {
      const existingItemIndex = acc.findIndex(item => item.dispatch_number === curr.dispatch_number);

      if (existingItemIndex === -1) {
        // this dispatch_number has not been encountered before, so add it to the array
        acc.push(curr);
      } else {
        // this dispatch_number has been encountered before
        if (curr.return_quantity) {
          // this item has return_quantity, so replace the previous item with this one
          acc.splice(existingItemIndex, 1, curr);
        }
      }

      return acc;
    }, []);

    setData(filteredData)
  }, [beerDispatchStockList, beerReturnStockList, runList])

  const showModalReturnStock = (record) => {
    setDispatchNumber(record.dispatch_number);

    const filteredBeerDispatchStockList = beerDispatchStockList.filter((beer_dispatch_stock) => {
      return beer_dispatch_stock.dispatch_number === record.dispatch_number
    })

    setFilteredBeerDispatch(filteredBeerDispatchStockList);
    setStockNumber(filteredBeerDispatchStockList[0]?.stock_number);
    setVisibleReturnStockModal(true);
  };

  const handleCancelReturnStockModal = () => {
    setVisibleReturnStockModal(false);
  };

  const handleSubmitReturnStockModal = (
    values,
    returnDate
  ) => {
    setBeerLoading(true);

    const body = {
      "dispatch_number": dispatchNumber,
      "stock_number": stockNumber,
      "return_date": returnDate,
      "return_quantity": [
        {
          "volume": 300,
          "quantity": Number(values.ml300) || 0
        },
        {
          "volume": 500,
          "quantity": Number(values.ml500) || 0
        },
        {
          "volume": 700,
          "quantity": Number(values.ml700) || 0
        }
      ]
    }

    PostDataAuth(apis.BEER_RETURN_STOCK, body).then((result) => {
      let responseJson = result;
      if (responseJson === "success") {
        message.success("Beer return successfully")
        setVisibleReturnStockModal(false)
        setRefreshTable(!refreshTable)
        setBeerLoading(false);
      } else if (responseJson.status === "error") {
        message.error(responseJson.message);
        setBeerLoading(false);
      }
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const exportDispatchStockDataPDF = (record) => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "landscape"; // portrait or landscape
    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);

    const title = "Colombo Hash";
    const subtitle = "Dispatch Stock Data";
    const headers = [[
      "Dispatch Number", 
      "Run Number", 
      "Dispatch Date", 
      "Dispatch Quantity", 
      "Return Date", 
      "Return Quantity"
    ]];

    const tableData = [record].map(elt => [
      elt.dispatch_number, 
      elt.run_number, 
      elt.dispatch_date, 
      elt.dispatch_quantity?.map((dispatch_quantity) => dispatch_quantity.quantity_data).join(), 
      elt.return_date, 
      elt.return_quantity?.map((return_quantity) => return_quantity.quantity_data).join()
    ]);

    let content = {
      startY: 65,
      head: headers,
      body: tableData,
    };

    // Add title and new subtitle
    doc.text(title, marginLeft, 30);
    doc.setFontSize(12); // Reduce font size for the subtitle
    doc.text(subtitle, marginLeft, 50); // Add the new subtitle below the title

    // Get the current date and time
    const now = new Date();
    const generatedDateTime = `${now.toLocaleDateString()} ${now.toLocaleTimeString()}`;

    // Add generated date and time in the bottom right corner
    const pageWidth = doc.internal.pageSize.getWidth();
    const pageHeight = doc.internal.pageSize.getHeight();
    doc.setFontSize(10); // Adjust font size for the generated date and time
    doc.text(generatedDateTime, pageWidth - marginLeft, pageHeight - 30, {
      align: "right",
    });

    doc.autoTable(content);
    doc.save(`dispatch-number${record.stock_number}-dispatch-stock.pdf`)
  }

  const columns = [
    {
      title: "Dispatch Number",
      dataIndex: "dispatch_number",
      sorter: (a, b) => a.dispatch_number - b.dispatch_number,
      sortOrder: 'descend',
      ...getColumnSearchProps('dispatch_number'),
    },
    {
      title: "Run Number",
      dataIndex: "run_number",
      ...getColumnSearchProps('run_number'),
    },
    {
      title: "Dispatch Date",
      dataIndex: "dispatch_date",
      ...getColumnSearchProps('dispatch_date'),
    },
    {
      title: "Dispatch Quantity",
      dataIndex: "dispatch_quantity",
      render: (dispatch_quantity) => dispatch_quantity?.map((dispatch_quantity) => dispatch_quantity.quantity_data).join(),
    },
    {
      title: "Return Date",
      dataIndex: "return_date",
      ...getColumnSearchProps('return_date'),
    },
    {
      title: "Return Quantity",
      dataIndex: "return_quantity",
      render: (return_quantity) => return_quantity?.map((return_quantity) => return_quantity.quantity_data).join(),
    },
    {
      title: "Action",
      render: (text, record) => (
        <Space>
          {
            record.return_date ?
              <Tooltip placement="bottom" title="Return Beer">
                <Button
                  className="return_button"
                  shape="circle"
                  icon={<RollbackOutlined />}
                  disabled
                />
              </Tooltip>
              :
              <Tooltip placement="bottom" title="Return Beer">
                <Button
                  className="return_button"
                  shape="circle"
                  icon={<RollbackOutlined />}
                  onClick={() => showModalReturnStock(record)}
                />
              </Tooltip>
          }
          <Tooltip placement="bottom" title="Download Invoice">
            <Button
              className="edit_button"
              shape="circle"
              icon={<FileTextOutlined />}
              onClick={() => exportDispatchStockDataPDF(record)}
            />
          </Tooltip>
        </Space>
      ),
      fixed: "right",
      align: 'left',
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination
        scroll={{ x: 1500 }}
      />
      <ReturnBeerStockModal
        visible={visibleReturnStockModal}
        onDone={handleCancelReturnStockModal}
        onCancel={handleCancelReturnStockModal}
        onSubmit={handleSubmitReturnStockModal}
        filteredBeerDispatchData={filteredBeerDispatch}
        beerLoadingVal={beerLoading}
      />
    </>
  );
}

export default BeerDispatchDataTable;
