import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import DashBoardBox from "../components/DashBoardWidgets/DashBoardBox";
import useBreadCrumb from "../hooks/useBreadCrumb";
import useBeerCurrentStock from "../hooks/useBeerCurrentStock";
import useMemberProfilesList from "../hooks/useMemberProfilesList";
import useRunsList from "../hooks/useRunsList";
import useTripsList from "../hooks/useTripsList";
import usePaymentsList from "../hooks/usePaymentsList";
import moment from "moment";
import { Table } from "antd";

function Dashboard() {
  const location = useLocation();
  useBreadCrumb("Dashboard", location.pathname, "Dashboard");
  const [totalStandingOrderExpMembers, setTotalStandingOrderExpMembers] =
    useState(0);
  const [standingOrderExpMemberNames, setStandingOrderExpMemberNames] =
    useState("");
  const [totalStandingOrderMembers, setTotalStandingOrderMembers] = useState(0);
  const [standingOrderMemberNames, setStandingOrderMemberNames] = useState("");
  const [data, setData] = useState([]);

  const memberProfileList = useMemberProfilesList("");
  const currentBeerStock = useBeerCurrentStock("");
  const runsList = useRunsList("");
  const tripsList = useTripsList("");
  const standingOrderPaymentList = usePaymentsList(
    "?payment_type=Standing Order"
  );

  useEffect(() => {
    const tempStandingOrderExpMemberData = [];

    standingOrderPaymentList.map((standing_order_payment, indexSt) => {
      // Find the corresponding member_profile_data using the member_id
      const member_profile_data = memberProfileList.find(
        (profile) => profile.member_id === standing_order_payment.member_id
      );

      if (member_profile_data) {
        const expirationMonth =
          standing_order_payment.payment_date[
            standing_order_payment.payment_date.length - 1
          ];
        const monthBeforeExpiration = moment(expirationMonth)
          .subtract(1, "month")
          .format("YYYY-MM");

        if (
          standing_order_payment.payment_date.includes(monthBeforeExpiration)
        ) {
          tempStandingOrderExpMemberData.push(
            `${member_profile_data.first_name} ${member_profile_data.last_name}-${member_profile_data.hash_name} `
          );
        }
      }

      return null;
    });

    let memberNames = [];
    standingOrderPaymentList.forEach((payment) => {
      const memberData = memberProfileList.find(
        (profile) => profile.member_id === payment.member_id
      );
      if (memberData) {
        memberNames.push(
          `${memberData.first_name} ${memberData.last_name}-${memberData.hash_name} `
        );
      }
    });

    setTotalStandingOrderExpMembers(tempStandingOrderExpMemberData.length);
    setStandingOrderExpMemberNames(tempStandingOrderExpMemberData.join(","));
    setTotalStandingOrderMembers(standingOrderPaymentList.length);
    setStandingOrderMemberNames(memberNames.join(", "));
  }, [standingOrderPaymentList, memberProfileList]);

  useEffect(() => {
    const today = new Date();
    const inSevenDays = new Date();
    inSevenDays.setDate(today.getDate() + 7);
    
    const isBirthdayInRange = (dateOfBirth) => {
        const birthDate = new Date(dateOfBirth);
        birthDate.setFullYear(today.getFullYear()); // Set to current year for comparison
    
        return birthDate >= today && birthDate <= inSevenDays;
    };
    
    const upcomingBirthdays = memberProfileList.reduce((acc, member) => {
        if (isBirthdayInRange(member.date_of_birth)) {
            acc.push({
                ...member,
                full_name: `${member.first_name} ${member.last_name}`
            });
        }
        return acc;
    }, []);

    setData(upcomingBirthdays);
  }, [memberProfileList]);

  const columns = [
    {
      title: "Member Name",
      dataIndex: "full_name",
    },
    {
      title: "Hash Name",
      dataIndex: "hash_name",
    },
    {
      title: "Birthday",
      dataIndex: "date_of_birth",
    },
  ];

  return (
    <div className="dashboard">
      <div className="box_section">
        <DashBoardBox
          title="Members"
          count={memberProfileList.length}
          icon={"bi bi-people"}
        />
        {currentBeerStock[0]?.current_quantity.map(
          (current_quantity, index) => {
            if (current_quantity.quantity <= 50) {
              return (
                <DashBoardBox
                  key={index}
                  title={`Low on Beer: ${current_quantity.volume}ml`}
                  count={current_quantity.quantity}
                  icon={"bi bi-cup-straw"}
                />
              );
            }
            return null;
          }
        )}
        <DashBoardBox
          title="Runs"
          count={runsList.length}
          icon={"bi bi-person-lines-fill"}
        />
        <DashBoardBox
          title="Trips"
          count={tripsList.length}
          icon={"bi bi-emoji-sunglasses"}
        />
        <DashBoardBox
          title={`Standing Order Expire Soon`}
          count={totalStandingOrderExpMembers}
          icon={"bi bi-clock-history"}
          tooltipData={standingOrderExpMemberNames}
        />
        <DashBoardBox
          title="Standing Order Members"
          count={totalStandingOrderMembers}
          icon={"bi bi-people"}
          tooltipData={standingOrderMemberNames}
        />
      </div>
      {data.length > 0 ? (
        <>
          <h4>Upcoming Birthdays</h4>
          <Table columns={columns} dataSource={data} bordered pagination />
        </>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Dashboard;
