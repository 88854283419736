import {
  DeleteOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Button, Space, Table, Tooltip, message, Modal, Input } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { PutDataAuth } from "../../apiService/PutData";
import { apis } from "../../properties";
import { useRefreshTable, useStore } from "../../store";
import { SearchOutlined } from '@ant-design/icons';
import Progress from "react-progress-2";
import useNoticesList from "../../hooks/useNoticesList";
import EditNoticesModal from "./EditNoticesModal";
import Highlighter from 'react-highlight-words';

const { confirm } = Modal;

function NoticesDataTable(props) {
  const { getNoticeCount } = props;
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [data, setData] = useState([]);
  const [visibleEditNoticeModal, setVisibleEditNoticeModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [noticeId, setNoticeId] = useState("");
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  const userData = useStore(state => state.profileData);

  const noticeList = useNoticesList("");

  useEffect(() => {
    const tempNoticeListData = []

    noticeList.map((notice_list_data, indexNo) => {
      if (notice_list_data.notice_status === "Ongoing") {
        tempNoticeListData.push({
          "key": indexNo,
          "_id": notice_list_data._id,
          "notice_number": notice_list_data.notice_number,
          "notice_date": notice_list_data.created_date.substring(0, 10),
          "title": notice_list_data.title,
          "content": notice_list_data.content
        })
      }

      return null
    })

    setData(tempNoticeListData)
    getNoticeCount(noticeList?.length)
  }, [getNoticeCount, noticeList])

  const showModalEditNotice = (value) => {
    const filteredNoticeList = noticeList?.filter((notice_list_data) => {
      return notice_list_data._id === value._id
    })

    const selectedValueObj = {
      "notice_number": filteredNoticeList[0]?.notice_number,
      "title": filteredNoticeList[0]?.title,
      "content": filteredNoticeList[0]?.content
    }

    setSelectedValue(selectedValueObj);
    setVisibleEditNoticeModal(true);
    setNoticeId(value._id)
  };

  const handleCancelEditNoticeModal = () => {
    setVisibleEditNoticeModal(false);
  };

  const handleSubmitEditNoticeModal = (
    values,
    noticeNumber
  ) => {

    const body = {
      "notice_number": noticeNumber,
      "title": values.title,
      "content": values.content,
      "username": userData.username,
      "notice_status": "Ongoing"
    }

    PutDataAuth(`${apis.NOTICES_LIST}/${noticeId}`, body).then((result) => {
      let responseJson = result;

      if (responseJson === "success") {
        message.success("Notice updated successfully")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
        message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });

    setVisibleEditNoticeModal(false)
  };

  const deleteNoticesContent = (record) => {
    confirm({
      title: `Are you sure?`,
      icon: <ExclamationCircleOutlined />,
      content: `Do you want to cancel this notice?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      onOk() {
        const body = {
          "notice_number": record.notice_number,
          "title": record.title,
          "content": record.content,
          "username": userData.username,
          "notice_status": "Cancel"
        }

        Progress.show();
        PutDataAuth(`${apis.NOTICES_LIST}/${record._id}`, body).then((result) => {
          let responseJson = result;

          if (responseJson === "success") {
            message.success("Notice canceled")
            setRefreshTable(!refreshTable)
            Progress.hide();
          } else if (responseJson.status === "error") {
            message.error(responseJson?.error?.details[0]?.message);
            Progress.hide();
          }
        });
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "Notice Number",
      dataIndex: "notice_number",
      sorter: (a, b) => a.notice_number - b.notice_number,
      sortOrder: 'descend',
      ...getColumnSearchProps('notice_number'),
    },
    {
      title: "Notices Date",
      dataIndex: "notice_date",
      ...getColumnSearchProps('notice_date'),
    },
    {
      title: "Title",
      dataIndex: "title",
      ...getColumnSearchProps('title'),
    },
    {
      title: "Content",
      dataIndex: "content",
      ...getColumnSearchProps('content'),
    },
    {
      title: "Action",
      render: (text, record) => (
        <Space>
          <Tooltip placement="bottom" title="Edit Notice">
            <Button
              className="edit_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => showModalEditNotice(record)}
            />
          </Tooltip>
          <Tooltip placement="bottom" title="Cancel Notice">
            <Button
              className="delete_button"
              shape="circle"
              icon={<DeleteOutlined />}
              onClick={() => deleteNoticesContent(record)}
            />
          </Tooltip>
        </Space>
      ),
      fixed: "right",
      align: 'left',
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination
        scroll={{ x: 1500 }}
      />
      <EditNoticesModal
        visible={visibleEditNoticeModal}
        onDone={handleCancelEditNoticeModal}
        onCancel={handleCancelEditNoticeModal}
        onSubmit={handleSubmitEditNoticeModal}
        selectedValueData={selectedValue}
      />
    </>
  );
}

export default NoticesDataTable;
