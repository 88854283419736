import { Button, Tabs, Typography } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import useBreadCrumb from "../hooks/useBreadCrumb";
import RunDetails from "../components/Run/RunDetails";
import RunHaresDataTable from "../components/Hares/RunHaresDataTable";
import RunParticipantsDataTable from "../components/Participants/RunParticipantsDataTable";
import RunIncome from "../components/Income/RunIncome";
import { BackwardOutlined } from "@ant-design/icons";
import { useState } from "react";

const { Title } = Typography;

function ViewRuns() {
  const location = useLocation();
  const navigate = useNavigate();
  useBreadCrumb("Runs/View/Details", location.pathname, "Runs");
  const [tabName, setTabName] = useState("Run Details");

  const items = [
    {
      key: '1',
      label: `Run Details`,
      children: <RunDetails />,
    },
    {
      key: '2',
      label: `Hares`,
      children: <RunHaresDataTable />,
    },
    {
      key: '3',
      label: `Participants`,
      children: <RunParticipantsDataTable />
    },
    {
      key: '4',
      label: `Income`,
      children: <RunIncome />,
    },
  ];

  const onTabChange = (key) => {
    items.map((item_data) => {
      if(item_data.key === key) {
        setTabName(item_data.label)
      }
      return null
    })
  }

  return (
    <div className="dashboard">
      <div className="section_row">
        <div className="tab_top_row">
          <Button
            className="primary__btn"
            onClick={() => navigate(
              `/runs`
            )}
            icon={<BackwardOutlined />}
          />
          <Title
            style={{ paddingTop: 5, paddingLeft: 3 }}
            level={5}
          >
            {tabName}
          </Title>
        </div>
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onTabChange}
        />
      </div>
    </div>
  );
}

export default ViewRuns
