import { color } from "d3-color";
import { interpolateRgb } from "d3-interpolate";
import { Typography } from "antd";
import LiquidFillGauge from "react-liquid-gauge";

const { Title } = Typography;

const LiquidFillGaugeComp = (props) => {
    const value = props.quantity
    const volume = props.volume

    const startColor = "#6495ed"; // cornflowerblue
    const endColor = "#dc143c"; // crimson

    const renderText = (renderProps) => {
        const radius = Math.min(renderProps.height / 2, renderProps.width / 2);
        const textPixels = (renderProps.textSize * radius) / 2;
        const valueStyle = {
            fontSize: textPixels,
        };
        const percentStyle = {
            fontSize: textPixels * 0.6,
        };

        return (
            <tspan>
                <tspan className="value" style={valueStyle}>
                    {Math.round(props.quantity)}
                </tspan>
                <tspan style={percentStyle}>{renderProps.percent}</tspan>
            </tspan>
        );
    };

    const radius = 200;
    const interpolate = interpolateRgb(startColor, endColor);
    const fillColor = interpolate(((value / props.totalQuantity) * 100)/ 100);
    const gradientStops = [
        {
            key: "0%",
            stopColor: color(fillColor).darker(0.5).toString(),
            stopOpacity: 1,
            offset: "0%",
        },
        {
            key: "50%",
            stopColor: fillColor,
            stopOpacity: 0.75,
            offset: "50%",
        },
        {
            key: "100%",
            stopColor: color(fillColor).brighter(0.5).toString(),
            stopOpacity: 0.5,
            offset: "100%",
        },
    ];

    return (
        <div>
            <LiquidFillGauge
                style={{ margin: "0 auto" }}
                width={radius * 1}
                height={radius * 1}
                value={(value / props.totalQuantity) * 100}
                percent=""
                textSize={1}
                textOffsetX={0}
                textOffsetY={0}
                textRenderer={renderText}
                riseAnimation
                waveAnimation
                waveFrequency={2}
                waveAmplitude={1}
                gradient
                gradientStops={gradientStops}
                circleStyle={{
                    fill: fillColor,
                }}
                waveStyle={{
                    fill: fillColor,
                }}
                textStyle={{
                    fill: color("#444").toString(),
                    fontFamily: "Arial",
                }}
                waveTextStyle={{
                    fill: color("#fff").toString(),
                    fontFamily: "Arial",
                }}
            />
            <div
                style={{
                    margin: "20px auto",
                    width: 100,
                }}
            >
                <Title level={3}>{volume} ml</Title>
            </div>
        </div>
    );
};

export default LiquidFillGaugeComp;
