export const BASE_LOCAL_API_URL = process.env.REACT_APP_BASE_LOCAL_API_URL;
export const LIVE_MAIN_API_URL = process.env.REACT_APP_LIVE_MAIN_API_URL;
export const IMAGE_PATH = process.env.REACT_APP_IMAGE_PATH;

export const BASE_API_URL = `${BASE_LOCAL_API_URL}`;

export const apis = {
  ADMIN_LOGIN: `${BASE_API_URL}/auth/login`,

  RUNS_LIST: `${BASE_API_URL}/runs`,

  TRIPS_LIST: `${BASE_API_URL}/trips`,

  MEMBERS_LIST: `${BASE_API_URL}/members`,

  NOTICES_LIST: `${BASE_API_URL}/notices`,

  MEMBER_PROFILES_LIST: `${BASE_API_URL}/member/profiles`,

  MEMBER_STATUS_LIST: `${BASE_API_URL}/member/status`,

  ROLES_LIST: `${BASE_API_URL}/roles`,

  BEER_ADDED_STOCK: `${BASE_API_URL}/beer/added/stocks`,

  BEER_AVAILABLE_STOCK: `${BASE_API_URL}/beer/available/stocks`,

  BEER_DISPATCH_STOCK: `${BASE_API_URL}/beer/dispatch/stocks`,

  BEER_RETURN_STOCK: `${BASE_API_URL}/beer/returns`,

  BEER_CURRENT_STOCK: `${BASE_API_URL}/beer/current/stocks`,

  IMAGEKIT_AUTH: `${BASE_API_URL}/auth/imagekit`,

  PAYMENTS_LIST: `${BASE_API_URL}/payments`,

  AUTH_REGISTER: `${BASE_API_URL}/auth/register`,
};

export const ckeditorConfig = {
  toolbar: [
    "heading",
    "|",
    "fontColor",
    "fontBackgroundColor",
    "|",
    "bold",
    "italic",
    "link",
    "bulletedList",
    "numberedList",
    "blockQuote",
    "|",
    "insertTable",
    "mediaEmbed",
    "|",
    "undo",
    "redo",
    "|",
    "imageUpload",
    "imageStyle:full",
    "imageStyle:side",
    "|",
    "code",
    "CodeBlock",
    "|",
  ],
};
