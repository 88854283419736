import { ReloadOutlined } from '@ant-design/icons';
import {
    Modal,
    Input,
    Row,
    Col,
    Form,
    DatePicker,
    Button,
    Space,
    Tooltip,
} from 'antd';
import { useEffect, useState } from 'react';

const ReturnBeerStockModal = (props) => {
    const [formReturnBeerStock] = Form.useForm();
    const {
        visible,
        onCancel,
        onSubmit,
        filteredBeerDispatchData,
        beerLoadingVal
    } = props;
    const [returnDate, setReturnDate] = useState("");

    const handleSubmit = () => {
        if (!formReturnBeerStock) return;
        formReturnBeerStock.submit();
    };

    const handleFinish = (values) => {
        if (onSubmit) {
            onSubmit(
                values,
                returnDate
            );
        }
    };

    const onReturnDatePickerChange = (date, dateString) => {
        setReturnDate(dateString)
    };

    useEffect(() => {
        formReturnBeerStock.setFieldsValue({ run_number: filteredBeerDispatchData[0]?.run_number });
        formReturnBeerStock.setFieldsValue({ stock_number: filteredBeerDispatchData[0]?.stock_number });
    }, [filteredBeerDispatchData, formReturnBeerStock])

    const modalFooter = { okText: 'Save Details', onOk: handleSubmit, onCancel };

    const getModalContent = () => {
        return (
            <Form
                form={formReturnBeerStock}
                name="formReturnBeerStock"
                onFinish={handleFinish}
                style={{ paddingLeft: 20, paddingRight: 20 }}
            >
                <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Run"
                            name="run_number"
                            labelCol={{ span: 24 }}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Stock Number"
                            name="stock_number"
                            labelCol={{ span: 24 }}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Return Date"
                            name="return_date"
                            rules={[{ required: true, message: 'Please select a return date' }]}
                            labelCol={{ span: 24 }}
                        >
                            <DatePicker style={{ width: "100%" }} onChange={onReturnDatePickerChange} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="300ml Quantity"
                            name="ml300"
                            rules={[{
                                pattern: /^[0-9]+$/,
                                message: "Please enter a number"
                            }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="How  many bottles or cans ?" />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="500ml Quantity"
                            name="ml500"
                            rules={[{
                                pattern: /^[0-9]+$/,
                                message: "Please enter a number"
                            }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="How  many bottles or cans ?" />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="700ml Quantity"
                            name="ml700"
                            rules={[{
                                pattern: /^[0-9]+$/,
                                message: "Please enter a number"
                            }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="How  many bottles or cans ?" />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        );
    };

    return (
        <Modal
            title='Return Stock'
            destroyOnClose
            width={1000}
            open={visible}
            {...modalFooter}
            footer={[
                <Space>
                    <Tooltip title="Clear form">
                        <Button
                            className="edit_button"
                            shape="circle"
                            icon={<ReloadOutlined />}
                            onClick={() => formReturnBeerStock.resetFields([
                                "return_date",
                                "ml300",
                                "ml500",
                                "ml700"
                            ])}
                        />
                    </Tooltip>
                    <Button key="submit" type="primary" onClick={handleSubmit} loading={beerLoadingVal}>
                        Return
                    </Button>
                </Space>
            ]}
        >
            {getModalContent()}
        </Modal>
    );
};

export default ReturnBeerStockModal;
