import {
    Modal,
    Input,
    Row,
    Col,
    Form,
    DatePicker,
    message,
    Select,
    Typography,
    Space,
    Button,
    Spin
} from 'antd';
import { useEffect, useState } from 'react';
import { apis } from '../../properties';
import { IKContext, IKUpload } from 'imagekitio-react';

const { Text } = Typography;

const AddBeerStockModal = (props) => {
    const [formAddStock] = Form.useForm();
    const {
        visible,
        onCancel,
        onSubmit,
        purchasePersonDataVal,
        beerAddedStockCountVal
    } = props;
    const [purchaseDate, setPurchaseDate] = useState("");
    const [uploadedImageKitUrl, setUploadedImageKitUrl] = useState("");
    const [loading, setLoading] = useState(false);

    const publicKey = process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY
    const urlEndpoint = process.env.REACT_APP_IMAGE_PATH
    const authenticationEndpoint = apis.IMAGEKIT_AUTH;

    useEffect(() => {
        formAddStock.setFieldsValue({ "stock_number": (beerAddedStockCountVal + 1) });
        formAddStock.setFieldsValue({ "lkr300": (0 / 100).toFixed(2) });
        formAddStock.setFieldsValue({ "lkr500": (0 / 100).toFixed(2) });
        formAddStock.setFieldsValue({ "lkr700": (0 / 100).toFixed(2) });
    }, [beerAddedStockCountVal, formAddStock])

    const handleSubmit = () => {
        if (!formAddStock) return;
        formAddStock.submit();
    };

    const handleFinish = (values) => {
        if (onSubmit) {
            onSubmit(
                values,
                purchaseDate,
                uploadedImageKitUrl
            );
            formAddStock.resetFields();
        }
    };

    const onPurchaseDatePickerChange = (date, dateString) => {
        setPurchaseDate(dateString)
    };

    const onImageUploadError = err => {
        message.error(err)
        setLoading(false)
    };

    const onImageUploadSuccess = res => {
        setUploadedImageKitUrl(res.url)
        setLoading(false)
    };

    const handleImageUploadChange = (e) => {
        if (e.type === "change") {
            setLoading(true)
        }
    }

    const modalFooter = { onCancel };

    const getModalContent = () => {
        return (
            <Form
                form={formAddStock}
                name="formAddStock"
                onFinish={handleFinish}
                style={{ paddingLeft: 20, paddingRight: 20 }}
            >
                <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Stock Number"
                            name="stock_number"
                            labelCol={{ span: 24 }}
                        >
                            <Input disabled />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Purchase Date"
                            name="purchase_date"
                            rules={[{ required: true, message: 'Please select a purchase date' }]}
                            labelCol={{ span: 24 }}
                        >
                            <DatePicker style={{ width: "100%" }} onChange={onPurchaseDatePickerChange} />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="Purchased Person "
                            name="purchased_person"
                            rules={[{ required: true, message: 'Please select a purchased person' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) =>
                                    (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())
                                }
                                filterSort={(optionA, optionB) =>
                                    (optionA?.label?.toLowerCase() ?? '').localeCompare((optionB?.label?.toLowerCase() ?? ''))
                                }
                                placeholder="Select member"
                                options={purchasePersonDataVal}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="300ml Quantity"
                            name="ml300"
                            rules={[{
                                pattern: /^[0-9]+$/,
                                message: "Please enter a number"
                            }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="How  many bottles or cans ?" />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="500ml Quantity"
                            name="ml500"
                            rules={[{
                                pattern: /^[0-9]+$/,
                                message: "Please enter a number"
                            }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="How  many bottles or cans ?" />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="700ml Quantity"
                            name="ml700"
                            rules={[{
                                pattern: /^[0-9]+$/,
                                message: "Please enter a number"
                            }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="How  many bottles or cans ?" />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="One 300ml Price (LKR)"
                            name="lkr300"
                            rules={[{
                                pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                                message: "Please enter a number"
                            }, { required: true, message: 'Please enter a price' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="Price for one bottle or can" />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="One 500ml Price (LKR)"
                            name="lkr500"
                            rules={[{
                                pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                                message: "Please enter a number"
                            }, { required: true, message: 'Please enter a price' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="Price for one bottle or can" />
                        </Form.Item>
                    </Col>
                    <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
                        <Form.Item
                            label="One 700ml Price (LKR)"
                            name="lkr700"
                            rules={[{
                                pattern: /^[0-9]+(?:\.[0-9]{1,2})?$/,
                                message: "Please enter a number"
                            }, { required: true, message: 'Please enter a price' }]}
                            labelCol={{ span: 24 }}
                        >
                            <Input placeholder="Price for one bottle or can" />
                        </Form.Item>
                    </Col>
                </Row>
                <IKContext
                    publicKey={publicKey}
                    urlEndpoint={urlEndpoint}
                    authenticationEndpoint={authenticationEndpoint}
                >
                    <Space direction="vertical">
                        <Text>
                            <span style={{ color: 'red' }}>*</span> Invoice
                        </Text>
                        <Spin spinning={loading}>
                            <IKUpload
                                fileName=""
                                onError={onImageUploadError}
                                onSuccess={onImageUploadSuccess}
                                onChange={((e) => handleImageUploadChange(e))}
                            />
                        </Spin>
                    </Space>
                </IKContext>
            </Form>
        );
    };

    return (
        <Modal
            title='Add Stock'
            destroyOnClose
            width={1000}
            open={visible}
            {...modalFooter}
            footer={[
                <Button
                    loading={loading}
                    key="submit"
                    type="primary"
                    onClick={handleSubmit}
                >
                    Add
                </Button>
            ]}
        >
            {getModalContent()}
        </Modal>
    );
};

export default AddBeerStockModal;
