import {
  Form,
  Row,
  Col,
  Input,
  Image,
  Collapse,
  Checkbox,
  Typography,
  Card
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom"
import useTripsList from "../../hooks/useTripsList";
import RunsDataTable from "../Run/RunsDataTable";

const { TextArea } = Input;
const { Panel } = Collapse;
const { Title, Text } = Typography

function TripDetails() {
  const [formViewTrip] = Form.useForm();
  const [filterData, setFilterData] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [dayNumber, setDayNumber] = useState(1);
  const [runNumber, setRunNumber] = useState([]);
  const [uniqueRunNumber, setUniqueRunNumber] = useState([]);
  const [destinationImages, setDestinationImages] = useState([]);
  const params = useParams();

  useState(() => {
    setFilterData(`?_id=${params.trip_id}`)
  }, [])

  const tripsList = useTripsList(filterData);

  useEffect(() => {
    formViewTrip.setFieldsValue(tripsList[0])
    setImageURL(tripsList[0]?.cover_image_url)
  }, [formViewTrip, tripsList])

  const mealOptions = ['Breakfast', 'Lunch', 'Dinner'];

  const handleRunCount = () => {

  }

  useEffect(() => {
    formViewTrip.setFieldsValue({ "vehicle_type": tripsList[0]?.trip_expenses[0]?.vehicle_type })
    formViewTrip.setFieldsValue({ "transport_rate_per_person": tripsList[0]?.trip_expenses[0]?.transport_rate_per_person.toFixed(2) || (0 / 100).toFixed(2) })
    formViewTrip.setFieldsValue({ "meal_rate_per_person": tripsList[0]?.trip_expenses[0]?.meal_rate_per_person.toFixed(2) })
    formViewTrip.setFieldsValue({ "hotel_name": tripsList[0]?.trip_expenses[0]?.hotel_name })
    formViewTrip.setFieldsValue({ "double_deluxe_room_count": tripsList[0]?.trip_expenses[0]?.double_deluxe_room_count })
    formViewTrip.setFieldsValue({ "double_deluxe_room_rate_per_person": tripsList[0]?.trip_expenses[0]?.double_deluxe_room_rate_per_person.toFixed(2) })
    formViewTrip.setFieldsValue({ "double_standard_room_count": tripsList[0]?.trip_expenses[0]?.double_standard_room_count })
    formViewTrip.setFieldsValue({ "double_standard_room_rate_per_person": tripsList[0]?.trip_expenses[0]?.double_standard_room_rate_per_person.toFixed(2) })
    formViewTrip.setFieldsValue({ "single_deluxe_room_count": tripsList[0]?.trip_expenses[0]?.single_deluxe_room_count })
    formViewTrip.setFieldsValue({ "single_deluxe_room_rate_per_person": tripsList[0]?.trip_expenses[0]?.single_deluxe_room_rate_per_person.toFixed(2) })
    formViewTrip.setFieldsValue({ "single_standard_room_count": tripsList[0]?.trip_expenses[0]?.single_standard_room_count })
    formViewTrip.setFieldsValue({ "single_standard_room_rate_per_person": tripsList[0]?.trip_expenses[0]?.single_standard_room_rate_per_person.toFixed(2) })
    formViewTrip.setFieldsValue({ "triple_deluxe_room_count": tripsList[0]?.trip_expenses[0]?.triple_deluxe_room_count })
    formViewTrip.setFieldsValue({ "triple_deluxe_room_rate_per_person": tripsList[0]?.trip_expenses[0]?.triple_deluxe_room_rate_per_person.toFixed(2) })
    formViewTrip.setFieldsValue({ "triple_standard_room_count": tripsList[0]?.trip_expenses[0]?.triple_standard_room_count })
    formViewTrip.setFieldsValue({ "triple_standard_room_rate_per_person": tripsList[0]?.trip_expenses[0]?.triple_standard_room_rate_per_person.toFixed(2) })

    const tempDestinationImages = []
    tripsList[0]?.trip_details?.map((trip_detail_data, index_no) => {
      formViewTrip.setFieldsValue({ [`description ${(index_no + 1)}`]: trip_detail_data.description })
      formViewTrip.setFieldsValue({ [`checkboxValue ${(index_no + 1)}`]: trip_detail_data.food })

      if (typeof trip_detail_data.day !== "undefined") {
        tempDestinationImages.push({
          "day": trip_detail_data?.day,
          "destination_images": trip_detail_data?.destination_images
        })
      }

      const tempRunNumber = trip_detail_data?.run_number
      setRunNumber(tempRunNumber)

      const newUniqeRunNum = tempRunNumber?.filter((v, i, a) => a.findIndex(v2 => (v2.day_number === v.day_number)) === i)
      setDayNumber(newUniqeRunNum?.length)
      setUniqueRunNumber(newUniqeRunNum)

      return null
    })

    setDestinationImages(tempDestinationImages)
  }, [formViewTrip, tripsList])

  return (
    <>
      <Form
        form={formViewTrip}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <Row gutter={16}>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Trip Number"
              name="trip_number"
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Trip Name"
              name="trip_name"
              rules={[{ required: true, message: 'Please enter a trip name' }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Starting Date"
              name="starting_date"
              rules={[{ required: true, message: 'Please select a starting date' }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Ending Date"
              name="ending_date"
              rules={[{ required: true, message: 'Please select a ending date' }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Destination"
              name="destination"
              rules={[{ required: true, message: 'Please enter destination' }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Participant Count"
              name="participant_count"
              rules={[{ required: true, message: 'Please enter participant count' }, {
                pattern: /^[0-9]+$/,
                message: "Please enter a number"
              }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
            <Form.Item
              label="Cover Image"
              name="cover_image"
              rules={[{ required: false, message: 'Please enter a image' }]}
              labelCol={{ span: 24 }}
            >
              <Image
                width={200}
                src={imageURL}
              />
            </Form.Item>
          </Col>
        </Row>
        {/* <Row>
          <Col xl={{ span: 24 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
            <Form.Item
              label="Description"
              name="description"
              rules={[{ required: false, message: 'Please enter a description' }]}
              labelCol={{ span: 24 }}
            >
              <TextArea disabled rows={4} />
            </Form.Item>
          </Col>
        </Row> */}
        <Row>
          <Col xl={{ span: 8 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
            <Form.Item
              label="Registration Closing Date"
              name="registration_closing_date"
              rules={[{ required: true, message: 'Please select a closing date' }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>

        <div>
          <Collapse
            ghost
            defaultActiveKey={0}
            style={{ width: '100%' }}
          >{
              [...Array(dayNumber).keys()].map((array_index) => (
                <Panel
                  header={`Day ${array_index + 1}`}
                  key={array_index}>
                  <div style={{ paddingBottom: "16px" }}>
                    {destinationImages?.map((destination_images_data) => (
                      <>
                        {
                          destination_images_data.day === (array_index + 1) ?
                            <Image
                              width={200}
                              src={destination_images_data.destination_images}
                            /> : <></>
                        }
                      </>
                    ))}
                  </div>
                  <Form.Item
                    label="Description"
                    name={`description ${(array_index + 1)}`}
                    labelCol={{ span: 24 }}
                  >
                    <TextArea placeholder="Description" />
                  </Form.Item>
                  {runNumber?.length === 0 ? <></> :
                    uniqueRunNumber?.map((run_number_data) => (run_number_data.day_number === (array_index + 1) ?
                      <RunsDataTable runNumberVal={runNumber} dayNumberVal={(array_index + 1)} getRunCount={handleRunCount} /> :
                      <></>))}
                  <Collapse>
                    <Panel header="Food & Beverage">
                      <Form.Item
                        name={`checkboxValue ${(array_index + 1)}`}
                      >
                        <Checkbox.Group
                          options={mealOptions}
                          defaultValue={['Breakfast']}
                        />
                      </Form.Item>
                    </Panel>
                  </Collapse>
                </Panel>
              ))
            }
          </Collapse>
        </div>
        <div style={{ paddingTop: 16 }}>
          <Text style={{ padding: 6 }} strong>Trip Expenses</Text>
          <Card title="Transport">
            <div className="trip-expense-transport">
              <div className="trip-expense-transport-first">
                <Form.Item
                  label="Vehicle Type"
                  name="vehicle_type"
                  labelCol={{ span: 24 }}
                >
                  <Input disabled placeholder="Bus" />
                </Form.Item>
              </div>
              <div>
                <Form.Item
                  label="Rate Per Person (LKR)"
                  name="transport_rate_per_person"
                  rules={[{
                    pattern: /^[0-9]+$/,
                    message: "Please enter a number"
                  }]}
                  labelCol={{ span: 24 }}
                >
                  <Input disabled placeholder="Rate" />
                </Form.Item>
              </div>
            </div>
          </Card>
          <Card title="Food and Beverage" style={{ marginTop: 16 }}>
            <Form.Item
              label="Meal Rate Per Person (LKR)"
              name="meal_rate_per_person"
              rules={[{
                pattern: /^[0-9]+$/,
                message: "Please enter a number"
              }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled style={{ width: "38%" }} placeholder="Rate" />
            </Form.Item>
          </Card>
          <Card title="Accommodation" style={{ marginTop: 16 }}>
            <Form.Item
              label="Hotel Name"
              name="hotel_name"
              labelCol={{ span: 24 }}
            >
              <Input disabled style={{ width: "38%" }} placeholder="Hotel Name" />
            </Form.Item>
            <Title level={5}>Single Rooms</Title>
            <div className="room-title">
              <Text strong>Deluxe Rooms</Text>
              <Text strong>Standard Rooms</Text>
            </div>
            <div className="rooms-container">
              <div className="rooms-item">
                <Form.Item
                  label="Count"
                  name="single_deluxe_room_count"
                  rules={[{
                    pattern: /^[0-9]+$/,
                    message: "Please enter a number"
                  }]}
                  labelCol={{ span: 24 }}
                >
                  <Input disabled placeholder="Count" />
                </Form.Item>
                <Form.Item
                  label="Rate Per Person (LKR)"
                  name="single_deluxe_room_rate_per_person"
                  rules={[{
                    pattern: /^[0-9]+$/,
                    message: "Please enter a number"
                  }]}
                  labelCol={{ span: 24 }}
                >
                  <Input disabled placeholder="Rate" />
                </Form.Item>
              </div>
              <div className="rooms-item">
                <Form.Item
                  label="Count"
                  name="single_standard_room_count"
                  rules={[{
                    pattern: /^[0-9]+$/,
                    message: "Please enter a number"
                  }]}
                  labelCol={{ span: 24 }}
                >
                  <Input disabled placeholder="Count" />
                </Form.Item>
                <Form.Item
                  label="Rate Per Person (LKR)"
                  name="single_standard_room_rate_per_person"
                  rules={[{
                    pattern: /^[0-9]+$/,
                    message: "Please enter a number"
                  }]}
                  labelCol={{ span: 24 }}
                >
                  <Input disabled placeholder="Rate" />
                </Form.Item>
              </div>
            </div>

            <Title level={5}>Double Rooms</Title>
            <div className="room-title">
              <Text strong>Deluxe Rooms</Text>
              <Text strong>Standard Rooms</Text>
            </div>
            <div className="rooms-container">
              <div className="rooms-item">
                <Form.Item
                  label="Count"
                  name="double_deluxe_room_count"
                  rules={[{
                    pattern: /^[0-9]+$/,
                    message: "Please enter a number"
                  }]}
                  labelCol={{ span: 24 }}
                >
                  <Input disabled placeholder="Count" />
                </Form.Item>
                <Form.Item
                  label="Rate Per Person (LKR)"
                  name="double_deluxe_room_rate_per_person"
                  rules={[{
                    pattern: /^[0-9]+$/,
                    message: "Please enter a number"
                  }]}
                  labelCol={{ span: 24 }}
                >
                  <Input disabled placeholder="Rate" />
                </Form.Item>
              </div>
              <div className="rooms-item">
                <Form.Item
                  label="Count"
                  name="double_standard_room_count"
                  rules={[{
                    pattern: /^[0-9]+$/,
                    message: "Please enter a number"
                  }]}
                  labelCol={{ span: 24 }}
                >
                  <Input disabled placeholder="Count" />
                </Form.Item>
                <Form.Item
                  label="Rate Per Person (LKR)"
                  name="double_standard_room_rate_per_person"
                  rules={[{
                    pattern: /^[0-9]+$/,
                    message: "Please enter a number"
                  }]}
                  labelCol={{ span: 24 }}
                >
                  <Input disabled placeholder="Rate" />
                </Form.Item>
              </div>
            </div>
            <Title level={5}>Triple Rooms</Title>
            <div className="room-title">
              <Text strong>Deluxe Rooms</Text>
              <Text strong>Standard Rooms</Text>
            </div>
            <div className="rooms-container">
              <div className="rooms-item">
                <Form.Item
                  label="Count"
                  name="triple_deluxe_room_count"
                  rules={[{
                    pattern: /^[0-9]+$/,
                    message: "Please enter a number"
                  }]}
                  labelCol={{ span: 24 }}
                >
                  <Input disabled placeholder="Count" />
                </Form.Item>
                <Form.Item
                  label="Rate Per Person (LKR)"
                  name="triple_deluxe_room_rate_per_person"
                  rules={[{
                    pattern: /^[0-9]+$/,
                    message: "Please enter a number"
                  }]}
                  labelCol={{ span: 24 }}
                >
                  <Input disabled placeholder="Rate" />
                </Form.Item>
              </div>
              <div className="rooms-item">
                <Form.Item
                  label="Count"
                  name="triple_standard_room_count"
                  rules={[{
                    pattern: /^[0-9]+$/,
                    message: "Please enter a number"
                  }]}
                  labelCol={{ span: 24 }}
                >
                  <Input disabled placeholder="Count" />
                </Form.Item>
                <Form.Item
                  label="Rate Per Person (LKR)"
                  name="triple_standard_room_rate_per_person"
                  rules={[{
                    pattern: /^[0-9]+$/,
                    message: "Please enter a number"
                  }]}
                  labelCol={{ span: 24 }}
                >
                  <Input disabled placeholder="Rate" />
                </Form.Item>
              </div>
            </div>
          </Card>
        </div>
      </Form>
    </>
  );
}

export default TripDetails;
