import {
    Modal,
    Input,
    Form,
    Button,
} from 'antd';
import { useEffect } from 'react';

const { TextArea } = Input;

const EditNoticesModal = (props) => {
    const [formEditNotice] = Form.useForm();
    const {
        visible,
        onCancel,
        onSubmit,
        selectedValueData,
    } = props;

    useEffect(() => {
        formEditNotice.setFieldsValue({ "notice_number": selectedValueData.notice_number })
        formEditNotice.setFieldsValue({ "title": selectedValueData.title })
        formEditNotice.setFieldsValue({ "content": selectedValueData.content })
    })

    const handleSubmit = () => {
        if (!formEditNotice) return;
        formEditNotice.submit();
    };

    const handleFinish = (values) => {
        if (onSubmit) {
            onSubmit(values, selectedValueData.notice_number);
            formEditNotice.resetFields();
        }
    };

    const modalFooter = { onCancel };

    const getModalContent = () => {
        return (
            <Form
                form={formEditNotice}
                name="formEditNotice"
                onFinish={handleFinish}
            >
                <Form.Item
                    label="Title"
                    name="title"
                    rules={[{ required: true, message: 'Please enter a title' }]}
                    labelCol={{ span: 24 }}
                >
                    <Input placeholder="Title" />
                </Form.Item>
                <Form.Item
                    label="Content"
                    name="content"
                    rules={[{ required: true, message: 'Please enter a content' }]}
                    labelCol={{ span: 24 }}
                >
                    <TextArea placeholder="Content" rows={4} />
                </Form.Item>
            </Form>
        );
    };

    return (
        <Modal
            title='Edit Notice'
            destroyOnClose
            width={500}
            open={visible}
            {...modalFooter}
            footer={[
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Save
                </Button>
            ]}
        >
            {getModalContent()}
        </Modal>
    );
};

export default EditNoticesModal;
