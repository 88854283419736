import {
    Modal,
    Input,
    Form,
    Button,
    Select
} from 'antd';
import { useEffect } from 'react';

const AddRolesModal = (props) => {
    const [formAddRole] = Form.useForm();
    const { visible, onCancel, onSubmit, roleCountVal } = props;

    useEffect(() => {
        formAddRole.setFieldsValue({ "role_number": (roleCountVal + 1) })
    })

    const handleSubmit = () => {
        if (!formAddRole) return;
        formAddRole.submit();
    };

    const handleFinish = (values) => {
        if (onSubmit) {
            onSubmit(values);
            formAddRole.resetFields();
        }
    };

    const modalFooter = { onCancel };

    const getModalContent = () => {
        return (
            <Form
                form={formAddRole}
                name="formAddRole"
                onFinish={handleFinish}
            >
                <Form.Item
                    label="Role Name"
                    name="role_name"
                    rules={[{ required: true, message: 'Please enter a role name' }]}
                    labelCol={{ span: 24 }}
                >
                    <Input placeholder="Enter role" />
                </Form.Item>
                <Form.Item
                    label="Status"
                    name="status"
                    labelCol={{ span: 24 }}
                >
                    <Select
                        placeholder="Select a status"
                        options={[
                            { value: 'Admin', label: 'Admin' },
                            { value: 'Super Admin', label: 'Super Admin' },
                        ]}
                    />
                </Form.Item>
            </Form>
        );
    };

    return (
        <Modal
            title='Add Role'
            destroyOnClose
            width={500}
            open={visible}
            {...modalFooter}
            footer={[
                <Button key="submit" type="primary" onClick={handleSubmit}>
                    Add
                </Button>
            ]}
        >
            {getModalContent()}
        </Modal>
    );
};

export default AddRolesModal;
