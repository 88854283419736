import {
  Form,
  Row,
  Col,
  Input,
  Select
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { country_and_states } from "../../util/CountryAndState";
import useMemberProfilesList from "../../hooks/useMemberProfilesList";
import useMemberStatusList from "../../hooks/useMemberStatusList";
import useRolesList from "../../hooks/useRolesList";

const { Option } = Select;

function MemberDetails() {
  const [formViewMember] = Form.useForm();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [memberStatusData, setMemberStatusData] = useState([]);
  const [roleData, setRoleData] = useState([]);
  const selectedState = "";
  const params = useParams();

  const memberProfilesList = useMemberProfilesList(`?_id=${params.member_id}`);
  const memberStatusList = useMemberStatusList("");
  const rolesList = useRolesList("");

  useEffect(() => {
    formViewMember.setFieldsValue(memberProfilesList[0]);
    formViewMember.setFieldsValue({ "street_number_name": memberProfilesList[0]?.address?.street_number_name });
    formViewMember.setFieldsValue({ "apartment_and_number": memberProfilesList[0]?.address?.apartment_and_number });
    formViewMember.setFieldsValue({ "city": memberProfilesList[0]?.address?.city });
    formViewMember.setFieldsValue({ "country": memberProfilesList[0]?.address?.country });
    formViewMember.setFieldsValue({ "state": memberProfilesList[0]?.address?.state });
    formViewMember.setFieldsValue({ "zip_code": memberProfilesList[0]?.address?.zip_code });

    setSelectedCountry(memberProfilesList[0]?.address?.country);

    const tempMemberStatus = []
    memberStatusList.map((member_status_data) => {
      tempMemberStatus.push({
        value: member_status_data._id,
        label: member_status_data.member_status
      })

      return null
    })

    setMemberStatusData(tempMemberStatus);

    const tempRoleList = []
    rolesList.map((role_list_data) => {
      tempRoleList.push({
        value: role_list_data.role_number,
        label: role_list_data.role_name
      })

      return null
    })

    setRoleData(tempRoleList);
  }, [
    memberProfilesList,
    rolesList,
    memberStatusList,
    formViewMember
  ])

  return (
    <>
      <Form
        form={formViewMember}
        style={{ paddingLeft: 20, paddingRight: 20 }}
      >
        <Row gutter={16}>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Member ID"
              name="member_id"
              rules={[{ required: false }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="First Name"
              name="first_name"
              rules={[{ required: true, message: 'Please enter a first name' }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Last Name"
              name="last_name"
              rules={[{ required: true, message: 'Please enter a last name' }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Hash Name"
              name="hash_name"
              rules={[{ required: false }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Contact Number"
              name="contact_number"
              rules={[{ required: true, message: 'Please enter a contact number' },
              {
                pattern: /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im,
                message: "Please enter a valid phone number"
              }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[{ required: false }, {
                pattern: /\S+@\S+\.\S+/,
                message: "Please enter a valid email address"
              }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Date of Birth"
              name="date_of_birth"
              rules={[{ required: true, message: 'Please select a date' }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Joined Date"
              name="joined_date"
              rules={[{ required: true, message: 'Please select a date' }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xl={{ span: 8 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
            <Form.Item
              label="Street Number and Name"
              name="street_number_name"
              rules={[{ required: false, message: 'Please enter a street number and name' }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
            <Form.Item
              label="Apartment and Number"
              name="apartment_and_number"
              rules={[{ required: false, message: 'Please enter a apartment and number' }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
            <Form.Item
              label="City"
              name="city"
              rules={[{ required: false, message: 'Please enter a city' }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xl={{ span: 8 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
            <Form.Item
              label="Country"
              name="country"
              rules={[{ required: false, message: 'Please enter a country' }]}
              labelCol={{ span: 24 }}
            >
              <Select
                value={selectedCountry}
                defaultValue={selectedCountry}
                disabled
              >
                {Object.keys(country_and_states.country).map((countryCode) => (
                  <Option key={countryCode} value={countryCode}>
                    {country_and_states.country[countryCode]}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
            <Form.Item
              label="State"
              name="state"
              rules={[{ required: false, message: 'Please enter a state' }]}
              labelCol={{ span: 24 }}
            >
              <Select
                value={selectedState}
                disabled
              >
                {selectedCountry && country_and_states.states[selectedCountry] &&
                  country_and_states.states[selectedCountry].map((state) => (
                    <Option key={state.code} value={state.code}>
                      {state.name}
                    </Option>
                  ))}
              </Select>
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} lg={{ span: 24 }} md={{ span: 24 }} sm={24}>
            <Form.Item
              label="Zip Code"
              name="zip_code"
              rules={[{ required: false, message: 'Please enter a zip code' }]}
              labelCol={{ span: 24 }}
            >
              <Input disabled />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="Status"
              name="status"
              labelCol={{ span: 24 }}
            >
              <Select
                options={memberStatusData}
                disabled
              />
            </Form.Item>
          </Col>
          <Col xl={{ span: 8 }} lg={{ span: 8 }} md={{ span: 12 }} sm={24}>
            <Form.Item
              label="MMC Role"
              name="role_number"
              labelCol={{ span: 24 }}
            >
              <Select
                options={roleData}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default MemberDetails;
