import {
  EditOutlined,
} from "@ant-design/icons";
import { Button, Space, Table, Tooltip, message, Input } from "antd";
import React, { useEffect, useState, useRef } from "react";
import { PutDataAuth } from "../../apiService/PutData";
import { apis } from "../../properties";
import { useRefreshTable } from "../../store";
import { SearchOutlined } from '@ant-design/icons';
import Progress from "react-progress-2";
import useRolesList from "../../hooks/useRolesList";
import EditRolesModal from "./EditRolesModal";
import Highlighter from 'react-highlight-words';

function RolesDataTable(props) {
  const { getRoleCount } = props;
  const setRefreshTable = useRefreshTable((state) => state.setRefreshTable);
  const refreshTable = useRefreshTable((state) => state.refreshTable);
  const [data, setData] = useState([]);
  const [visibleEditRoleModal, setVisibleEditRoleModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState({});
  const [roleId, setRoleId] = useState("");
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);

  const roleList = useRolesList("");

  useEffect(() => {
    const tempRoleListData = []

    roleList.map((role_list_data, indexNo) => {
      tempRoleListData.push({
        "key": indexNo,
        "_id": role_list_data._id,
        "role_number": role_list_data.role_number,
        "role_name": role_list_data.role_name,
        "status": role_list_data.status
      })

      return null
    })

    setData(tempRoleListData)
    getRoleCount(roleList?.length)
  }, [getRoleCount, roleList])

  const showModalEditRole = (value) => {
    const filteredRoleList = roleList?.filter((role_list_data) => {
      return role_list_data._id === value._id
    })

    const selectedValueObj = {
      "role_number": filteredRoleList[0]?.role_number,
      "role_name": filteredRoleList[0]?.role_name,
      "status": filteredRoleList[0]?.status
    }

    setSelectedValue(selectedValueObj);
    setVisibleEditRoleModal(true);
    setRoleId(value._id)
  };

  const handleCancelEditRoleModal = () => {
    setVisibleEditRoleModal(false);
  };

  const handleSubmitEditRoleModal = (
    values
  ) => {

    const body = {
      "role_name": values.role_name,
      "status": values.status
    }

    PutDataAuth(`${apis.ROLES_LIST}/${roleId}`, body).then((result) => {
      let responseJson = result;

      if (responseJson === "success") {
        message.success("Role updated successfully")
        setRefreshTable(!refreshTable)
        Progress.hide();
      } else if (responseJson.status === "error") {
          message.error(responseJson?.error?.details[0]?.message);
        Progress.hide();
      }
    });

    setVisibleEditRoleModal(false)
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: '#ffc069',
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const columns = [
    {
      title: "MMC Role Number",
      dataIndex: "role_number",
      sorter: (a, b) => a.role_number - b.role_number,
      sortOrder: 'descend',
      ...getColumnSearchProps('role_number'),
    },
    {
      title: "MMC Role Name",
      dataIndex: "role_name",
      ...getColumnSearchProps('role_name'),
    },
    {
      title: "Status",
      dataIndex: "status",
    },
    {
      title: "Action",
      render: (text, record) => (
        <Space>
          <Tooltip placement="bottom" title="Edit Role">
            <Button
              className="edit_button"
              shape="circle"
              icon={<EditOutlined />}
              onClick={() => showModalEditRole(record)}
            />
          </Tooltip>
        </Space>
      ),
      fixed: "right",
      align: 'left',
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={data}
        bordered
        pagination
        scroll={{ x: 1500 }}
      />
      <EditRolesModal
        visible={visibleEditRoleModal}
        onDone={handleCancelEditRoleModal}
        onCancel={handleCancelEditRoleModal}
        onSubmit={handleSubmitEditRoleModal}
        selectedValueData={selectedValue}
      />
    </>
  );
}

export default RolesDataTable;
